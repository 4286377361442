import React, { Component } from "react";
import './App.css';
import { Link } from 'react-router-dom';
import { Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import videoCoffee from './assets/videocoffee.mp4';
import posterCoffee from './assets/postercoffee.png';
import i18n from './i18n';
import Footer from './Footer.js';


class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, 
      videoCoffeSrc: ''
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    i18n.changeLanguage('en');
    this.setState({
      videoCoffeeSrc: videoCoffee
  })
  };


 
  orderUser(){
    this.orderSubscription("User");
  }




  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
       <div className="showcase">
       <video src={this.state.videoCoffeeSrc} poster={posterCoffee} muted loop playsInline autoPlay></video>
      {/* <div className="overlay"></div> */}
      <div className="text">
            <br/>
            <h2 >Pricing</h2> 
            <br/>
            <CardDeck>
            <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title><b>One manager</b></Card.Title>
                <Card.Text style={{color: "grey", fontWeight: "300"}}>
                For one manager  <br />
                600 SEK/month excl. VAT <br />
                Invoiced annually
                </Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
            <Card.Body>
            <Card.Title><b>Teams</b></Card.Title>
                <Card.Text  style={{color: "grey", fontWeight: "300"}}>
                For up to 5 managers <br />
                2.000 SEK/month excl. VAT <br />
                Invoiced annually
                </Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
            <Card.Body>
            <Card.Title><b>Company</b></Card.Title>
                <Card.Text  style={{color: "grey", fontWeight: "300"}}>
                For up to 20 managers <br />
                6.000 SEK/month excl. VAT <br />
                Invoiced annually
                </Card.Text>
            </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
            <Card.Body>
            <Card.Title><b>Enterprise</b></Card.Title>
                <Card.Text  style={{color: "grey", fontWeight: "300"}}>
                For up to 100 managers <br />
                24.000 SEK/month excl. VAT <br />
                Invoiced annually
                </Card.Text>
            </Card.Body>
            </Card>
            
            </CardDeck>
            
            

                
        <LoadingSpinner/>
        <Link className="float-right" to={`/signup`} style= {{backgroundColor: "transparent"}}><Button  variant="primary" className="btn-lg">Sign Up</Button></Link>
        <Table borderless>
            <tr>
            <td></td>
        </tr>
        </Table>
        <br /><br />
        </div>
      </div>
      <Footer/>

    </React.Fragment>
  );
};
};

export default Pricing;