import React, {useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { trackPromise } from 'react-promise-tracker';
import { VisibilityOutlined, DeleteOutlined, EditOutlined, FormatListBulletedOutlined } from '@mui/icons-material';
import { ContentCopyOutlined, ScreenShareOutlined, CloseOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StopSign from './StopSign';
import 'typeface-roboto';
import i18n from './i18n';

export default function BonusAgreements() {
  const [ bonusagreements, setBonusagreements] = useState([]);
  let history = useHistory();
   
  const columns = [
    {field: 'name', headerClassName: 'mymuiheader', headerFontWeight: 'bold', width: 250, headerName: i18n.t("bonus.name")},
    {field: 'employeeFullName', headerClassName: 'mymuiheader', width: 180, headerName: i18n.t("bonus.employee-name")},
    {field: 'period', headerClassName: 'mymuiheader', headerName: i18n.t("bonus.period")},
    {field: 'phase', headerClassName: 'mymuiheader', headerName: i18n.t("bonus.phase"), width: 220,
      renderCell: (cellValues) => {
        return(
        <div>
          {i18n.t("phase." + cellValues.row.phase)}
        </div>)
      }
    },
    {
      field: "View",
      headerName: i18n.t("navigation.view"),
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            color="secondary"
            onClick={(event) => {
              handleView(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <VisibilityOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    }, 
    {
      field: "Edit",
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Set Results") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleEdit(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <FormatListBulletedOutlined sx={{ color: "#007bff" }} />
          </Button>}
          </div>
        );
      }
    }, 
    {
      field: "Sign",
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Set Results") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleSign(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <EditOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    },
    {
      field: "Stop",
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Sign"  || cellValues.row.phase === "Sign Results") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleStopSign (event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <CloseOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    },  
    {
      field: "Share",
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            onClick={(event) => {
              handleShare(event, cellValues);
            }}
          >
            <ScreenShareOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    },
    {
      field: "Copy",
      headerClassName: 'mymuiheader', 
      width: 100,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            onClick={(event) => {
              handleCopy(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <ContentCopyOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    },
    {
      field: "Delete",
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Sign") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleDelete(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <DeleteOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    }


  ]
  
  function handleView(e, cellValues) {
    console.log("We should view a page");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results" || cellValues.row.phase === "Sign Results" || cellValues.row.phase === "Signed Results")
    { 
      history.push("/listonebonuswithresult/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft" || cellValues.row.phase === "Sign" || cellValues.row.phase === "Signed")
  {
    history.push("/listonebonus/" + cellValues.id)
  }
  }

  function handleEdit(e, cellValues) {
    console.log("We should edit a page");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results")
    { 
      history.push("/setresultonebonus/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft")
  {
    history.push("/editonebonus/" + cellValues.id)
  }
  }

  function handleSign(e, cellValues) {
    console.log("We should sign");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results")
    { 
      history.push("/signresults/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft")
  {
    history.push("/sign/" + cellValues.id)
  }
  }

  function handleStopSign (e, cellValues) {
    StopSign({bonusAgreementId: cellValues.id});
  } 

  function handleShare(e, cellValues) {
    console.log("We should share");
    console.log(cellValues);

    history.push("/sharebonusagreement/" + cellValues.id); 

  }

  function handleCopy (e, cellValues) {

    const model = {};
    model.bonusAgreementId = cellValues.id;
    model.userId = JSON.parse(localStorage.getItem('currentUser')).id;

    const BonusAgreementCopy = model;

    var connectionstring = process.env.REACT_APP_apiUrl + '/api/bonuskpiapproval/copybonusagreement';
    let token = JSON.parse(localStorage.getItem('currentUser')).token;

    trackPromise(  
    axios.post(connectionstring, BonusAgreementCopy,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(res => {
          /*this.props.history.push('/bonusagreements');*/
          window.location.reload(false);
        })
    ).catch(error => console.log(error));

} 

function handleDelete (e, cellValues) {
  console.log("We should sign");
  console.log(cellValues);
  
  if (cellValues.row.phase === "Draft" || cellValues.row.phase === "Sign")
  { 
    history.push("/deletebonusagreement/" + cellValues.id); 
  }

}


  useEffect(() => {

      let token = JSON.parse(localStorage.getItem('currentUser')).token;
      let managerUserName = JSON.parse(localStorage.getItem('currentUser')).userName;
      let connectionstring = process.env.REACT_APP_apiUrl + "/api/bonusagreements/" + token + "/" + managerUserName;
      console.log(connectionstring);
  
      trackPromise(
      axios
        .get(connectionstring,
          {
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${token}`,
            }
          })
        .then(response => {
          setBonusagreements(response.data);
          console.log(response.data);
       
        })
        // If we catch any errors connecting, let's update accordingly
        .catch(error => console.log(error)));

  }, []);



  return (<div>
    {bonusagreements.length === 0 ? 
    <div style={{height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Box >
        <CircularProgress />
      </Box>
    </div> 
    :
    <div>
      <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            rows={bonusagreements}
            columns={columns}
            getRowId={(row) => row.bonusAgreementId}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
    </div> 
    }
    

    </div>
  );
}