import React, { Component } from "react";
import { Layout } from './components/Layout';
import 'reactjs-popup/dist/index.css';
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import i18n from './i18n';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import englishflag from './assets/englishflag.jpg';
import swedishflag from './assets/swedishflag.jpg';
import { Redirect } from "react-router-dom";
import { Trans } from 'react-i18next';


class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isShow: false,
      redirect: null,
      en_variant: "", 
      se_variant: "",
      en_size: "",
      se_size: ""
    };
  }
 

  componentDidMount() {
   if (JSON.parse(localStorage.getItem('currentUser')).language === "se"){ 
    this.setState(state => { return { 
      en_variant: "light", 
      se_variant: "success",
      en_size: "",
      se_size: "lg" 
     };}) 
  } else {
    this.setState(state => { return { 
      en_variant: "success", 
      se_variant: "light",
      en_size: "lg",
      se_size: "" 
     };})
  }
}

ReturnToSettings = () => {
  this.setState({ redirect: '/settings' })

}

  ChangeLanguageCode = (e) => {

    if (e==='se') {
        
      this.setState(state => { return { 
        en_variant: "light", 
        se_variant: "success",
        en_size: "",
        se_size: "lg" 
       };});
    } else {
      this.setState(state => { return { 
        en_variant: "success", 
        se_variant: "light",
        en_size: "lg",
        se_size: "" 
       };});
    }

      /* Change the i18 language code */
      i18n.changeLanguage(e);

      /* Change the user cookie */
      var existing = localStorage.getItem('currentUser');
      existing = existing ? JSON.parse(existing) : {};
      existing['language'] = e;
      localStorage.setItem('currentUser', JSON.stringify(existing));

    /* Change the value at the server */
    var connectionstring = process.env.REACT_APP_apiUrl + '/identity/users/changeuserlanguage/';
    console.log(connectionstring);

    const model = {};
    model.UserId = JSON.parse(localStorage.getItem('currentUser')).id;
    model.Language = e;

    console.log(model);
    console.log(connectionstring);

    const UserLanguageChange = model;

    trackPromise(
    axios
      // This is where the data is hosted
      .post(connectionstring, UserLanguageChange
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('currentUser')).token}`,
        }
      })
      // Once we get a response and store data, display what we get
      .then(response => {
        console.log("Backend: " + response.data);
        window.location.reload(false);
        /*this.setState(state => { return { redirect: '/settings' };});*/
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }))
    );


  } 




 
  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Layout>
        <br /><br />
        <h2><Trans i18nKey="settings.change-language"/></h2><br />
      <div>
         <Button className="mr-1" variant={this.state.se_variant} size={this.state.se_size} onClick={() => {this.ChangeLanguageCode('se')}}><img className="thumbnail-language pull-left" src={swedishflag} alt="Language" /></Button>
         <Button className="mr-1" variant={this.state.en_variant} size={this.state.en_size} onClick={() => {this.ChangeLanguageCode('en')}}><img className="thumbnail-language pull-left" src={englishflag} alt="Language" /></Button>
         <br /><br />
         <br /><br /><br />
         <Button className="mr-1" variant="secondary" onClick={() => {this.ReturnToSettings()}}><Trans i18nKey="navigation.return"/></Button>
         </div>
    </Layout>
    );
  }
}



export default ChangeLanguage;

