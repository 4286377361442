import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import { PrivateRoute } from './_components';
import Home from './Home';
import SE from './SE';
import { HomeLoggedIn } from './HomeLoggedIn';
import BonusAgreements from './BonusAgreements';
import BonusAgreementsSM from './BonusAgreementsSM';
import BonusAgreementListM from './BonusAgreementListM';
import ListOneBonus from './ListOneBonus';
import ListOneBonusWithResult from './ListOneBonusWithResult';
import SetResultOneBonus from './SetResultOneBonus';
import EditOneBonus from './EditOneBonus';
import EditKpiTables from './EditKpiTables';
import EditKpiTableSteps from './EditKpiTableSteps';
import ShareBonusAgreement from './ShareBonusAgreement';
import Sign from './Sign';
import SignResults from './SignResults';
import SignFromEmail from './SignFromEmail';
import SignResultsFromEmail from './SignResultsFromEmail';
import IsSigned from './IsSigned';
import ViewSignedAgreement from './ViewSignedAgreement';
import ViewSignedResultsAgreement from './ViewSignedResultsAgreement';
import ViewBonusAgreementFromEmail from './ViewBonusAgreementFromEmail';
import { SignUp } from './SignUp';
import { SignUp_SE } from './SignUp_SE';
import { Login } from './Login';
import { Login_se } from './Login_se';
import Pricing from './Pricing';
import Pricing_se from './Pricing_se';
import Intro from './Intro';
import Partner from './Partner';
import Privacy from './Privacy';
import Settings  from './Settings';
import Help  from './Help';
import ChangeLogo  from './ChangeLogo';
import ChangeLanguage  from './ChangeLanguage';
import Order  from './Order';
import OrderConfirmation  from './OrderConfirmation';
import SignUpComplete  from './SignUpComplete';
import AdminHome  from './AdminHome';
import AdminDeleteUser from './AdminDeleteUser';
import AdminBonusAgreements from './AdminBonusAgreements';
import AdminDeleteBonusAgreement from './AdminDeleteBonusAgreement';
import AdminReset from './AdminReset';
import Testpage from './Testpage';
import DeleteBonusAgreement from './DeleteBonusAgreement';
import { NoMatch } from './NoMatch';
import NavigationBar from './components/NavigationBar';
import LogRocket from 'logrocket';

class App extends Component {
  
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('24exmp/managebonus');
    }
  }

  render () {
   return (
    <React.Fragment>
       <Router>
       <NavigationBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/SE" component={SE} />
          <Route exact path="/homeloggedin" component={HomeLoggedIn} />
          <PrivateRoute path="/bonusagreements" component={BonusAgreements} />
          <PrivateRoute path="/bonusagreementssm" component={BonusAgreementsSM} />
          <PrivateRoute path="/bonusagreementlistm" component={BonusAgreementListM} />
          <PrivateRoute path="/listonebonus/:bonusAgreementId" component={ListOneBonus} />
          <PrivateRoute path="/listonebonuswithresult/:bonusAgreementId" component={ListOneBonusWithResult} />
          <PrivateRoute path="/editonebonus/:bonusAgreementId" component={EditOneBonus} />
          <PrivateRoute path="/setresultonebonus/:bonusAgreementId" component={SetResultOneBonus} />
          <PrivateRoute path="/editkpitables" component={EditKpiTables} />
          <PrivateRoute path="/editkpitablesteps/:kpiId/:returnPage/:returnId" component={EditKpiTableSteps} />
          <PrivateRoute path="/sharebonusagreement/:bonusAgreementId" component={ShareBonusAgreement} />
          <PrivateRoute path="/sign/:bonusAgreementId" component={Sign} />
          <PrivateRoute path="/signresults/:bonusAgreementId" component={SignResults} />
          <PrivateRoute path="/deletebonusagreement/:bonusAgreementId" component={DeleteBonusAgreement} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/help" component={Help} />
          <PrivateRoute path="/changelogo" component={ChangeLogo} />
          <PrivateRoute path="/changelanguage" component={ChangeLanguage} />
          <PrivateRoute path="/order" component={Order} />
          <PrivateRoute path="/orderconfirmation" component={OrderConfirmation} />
          <PrivateRoute path="/signupcomplete" component={SignUpComplete} />
          <PrivateRoute path="/adminhome" component={AdminHome} />
          <PrivateRoute path="/admindeleteuser" component={AdminDeleteUser} />
          <PrivateRoute path="/adminbonusagreements" component={AdminBonusAgreements} />
          <PrivateRoute path="/admindeletebonusagreement/:bonusAgreementId" component={AdminDeleteBonusAgreement} />
          <PrivateRoute path="/adminreset" component={AdminReset} />
          <Route path="/signfromemail/:EmailCode/:Email" component={SignFromEmail} />
          <Route path="/signresultsfromemail/:EmailCode/:Email" component={SignResultsFromEmail} />
          <Route path="/issigned/:language" component={IsSigned} />
          <Route path="/viewsignedagreement/:EmailCode/:Email" component={ViewSignedAgreement} />
          <Route path="/viewsignedresultsagreement/:EmailCode/:Email" component={ViewSignedResultsAgreement} />
          <Route path="/viewbonusagreementfromemail/:EmailCode" component={ViewBonusAgreementFromEmail} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signup_se" component={SignUp_SE} />
          <Route path="/login" component={Login} />
          <Route path="/login_se" component={Login_se} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/pricing_se" component={Pricing_se} />
          <Route path="/intro" component={Intro} />
          <Route path="/partner" component={Partner} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/testpage" component={Testpage} />     
          <Route component={NoMatch} />
        </Switch>
        </Router>

    </React.Fragment>
  );
  }
}

export default App;
