import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Form, Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import BonusTableSteps from './components/bonustablesteps';
import Share from './components/Share';
import { Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';


class Sign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      bonusAgreementId: "",
      employeeEmail: "", 
      employeeFullName: "", 
      phase: "", 
      dateSent: "", 
      dateAnswered: "", 
      timeAnswered: "", 
      approvalStatus: "", 
      approvalLevel: "", 
      approvalComment: "", 
      email: "", 
      fullName: "", 
      ipAddress: "", 
      city: "", 
      country: "", 
      longitude: "", 
      latitude: "", 
      name: "", 
      onTargetReward: "", 
      resultAmount: "", 
      period: "",  
      employeeUserName: "", 
      managerUserName: "", 
      kpi: [
        { 
          kpiId: "",
          bonusAgreementId: "",
          name: "",
          description: "", 
          type: "", 
          goal: "", 
          result: "",  
          resultPercentage: "", 
          percentageOfTotalReward:"", 
          onTargetReward: "",
          resultAmount: "",
        },
      ],
    };
  }
 
  getIPInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
          token: this.state.token, 
          bonusAgreementId: this.state.bonusAgreementId,
          employeeEmail: this.state.employeeEmail, 
          employeeFullName: this.state.employeeFullName, 
          phase: this.state.phase, 
          dateSent: this.state.dateSent, 
          dateAnswered: this.state.dateAnswered, 
          timeAnswered: this.state.timeAnswered, 
          approvalStatus: this.state.approvalStatus, 
          approvalLevel: this.state.approvalLevel, 
          approvalComment: this.state.approvalComment, 
          email: this.state.email, 
          fullName: this.state.fullName, 
          ipAddress: data.ip, 
          city: data.city, 
          country: data.country_name, 
          longitude: String(data.longitude), 
          latitude: String(data.latitude), 
          name: this.state.name,
          onTargetReward: this.state.onTargetReward,
          resultAmount: this.state.resultAmount,
          period: this.state.period,
          employeeUserName: this.state.employeeUserName,
          managerUserName: this.state.managerUserName,
          kpi: this.state.kpi,
          isLoading: this.state.isLoading
        });
        

    }).catch((error) => {
        
    });
};

  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpi/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    console.log(connectionstring);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          bonusagreement: response.data,
          token: this.state.token, 
          bonusAgreementId: response.data.bonusAgreementId,
          employeeEmail: response.data.employeeUserName, 
          employeeFullName: response.data.employeeFullName, 
          phase: response.data.phase, 
          currency: response.data.currency, 
          dateSent: "", 
          dateAnswered: "", 
          timeAnswered: "", 
          approvalStatus: "", 
          approvalLevel: response.data.managerApprovalLevel, 
          approvalComment: "", 
          email: response.data.managerUserName, 
          fullName: response.data.managerFullName, 
          ipAddress: "", 
          city: "", 
          country: "", 
          longitude: "", 
          latitude: "", 
          name: response.data.name,
          language: response.data.language,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          translation: response.data.translationStringList,
          kpi: response.data.kpi,
          isLoading: false
        });
        this.getIPInfo();
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
   if (JSON.parse(localStorage.getItem('currentUser')).validSubscription !== "yes") {
      this.props.history.push('/order');
      this.setState(state => {
        return {
        redirect: '/order'
      };
      });
    } else {
      this.getBonusAgreement();
    }
  };


  handleChange = (e) => {
    const {name, value} = e.target;

    var New_bonusAgreementId = this.state.bonusAgreementId; 
    var New_employeeEmail = this.state.employeeEmail; 
    var New_employeeFullName = this.state.employeeFullName; 
    var New_phase = this.state.phase; 
    var New_dateSent = this.state.dateSent; 
    var New_dateAnswered = this.state.dateAnswered; 
    var New_timeAnswered = this.state.timeAnswered; 
    var New_approvalStatus = this.state.approvalStatus; 
    var New_approvalLevel = this.state.approvalLevel; 
    var New_approvalComment = this.state.approvalComment; 
    var New_email = this.state.email;
    var New_fullName = this.state.fullName; 
    var New_ipAddress = this.state.ipAddress; 
    var New_city = this.state.city; 
    var New_country = this.state.country; 
    var New_longitude = this.state.longitude; 
    var New_latitude = this.state.latitude; 
    var New_name = this.state.name; 
    var New_onTargetReward = this.state.onTargetReward;
    var New_resultAmount = this.state.resultAmount; 
    var New_period = this.state.period;  
    var New_employeeUserName = this.state.employeeUserName; 
    var New_managerUserName = this.state.managerUserName; 
    if (name === "bonusAgreementId") { New_bonusAgreementId = value;}
    if (name === "name") { New_name = value;}
    if (name === "onTargetReward") { New_onTargetReward = value;}
    if (name === "resultAmount") { New_resultAmount = value;}
    if (name === "period") { New_period = value;}
    if (name === "employeeUserName") { New_employeeUserName = value;}
    if (name === "managerUserName") { New_managerUserName = value;}
    if (name === "phase") { New_phase = value;}


    this.setState({
      token: this.state.token,        
      bonusAgreementId: New_bonusAgreementId, 
      employeeEmail : New_employeeEmail, 
      employeeFullName : New_employeeFullName, 
      phase : New_phase,
      dateSent : New_dateSent, 
      dateAnswered : New_dateAnswered, 
      timeAnswered : New_timeAnswered, 
      approvalStatus : New_approvalStatus, 
      approvalLevel : New_approvalLevel, 
      approvalComment : New_approvalComment, 
      email : New_email,
      fullName : New_fullName, 
      ipAddress : New_ipAddress, 
      city : New_city, 
      country : New_country, 
      longitude : New_longitude, 
      latitude : New_latitude, 
      name: New_name, 
      onTargetReward: New_onTargetReward, 
      resultAmount: New_resultAmount, 
      period: New_period,  
      employeeUserName: New_employeeUserName, 
      managerUserName: New_managerUserName, 
      kpi: this.state.kpi,
    }
    );
  }
 
  handleSubmit = event => {
    event.preventDefault();

    var today = new Date();

    const model = {};
    model.bonusAgreementId = this.state.bonusAgreementId; 
    model.employeeEmail = this.state.employeeEmail; 
    model.employeeFullName = this.state.employeeFullName; 
    model.phase = "Start Sign";
    model.dateSent = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(); 
    model.dateAnswered = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    model.timeAnswered = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(); 
    model.approvalStatus = this.state.approvalStatus; 
    model.approvalLevel = this.state.approvalLevel; 
    model.approvalComment = this.state.approvalComment; 
    model.email = this.state.email;
    model.fullName = this.state.fullName; 
    model.ipAddress = this.state.ipAddress; 
    model.city = this.state.city;
    model.country = this.state.country; 
    model.longitude = this.state.longitude; 
    model.latitude = this.state.latitude; 

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/ApproveSend';
    const connectionstring = connectionstring1.concat("" , connectionstring2);


    trackPromise(
    axios.post(connectionstring, BonusAgreement
      ,
    {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then(res => {
        this.props.history.push('/bonusagreements');
      }))
  };

  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
    <Layout>
      <br />
        <h2>{this.state.translation.string011}</h2>
        <br />
        <h4 className="card-title">{this.state.name} - {this.state.employeeFullName}</h4> 
        <h6 className="card-subtitle mb-2 text-muted">{this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}</h6>
         <br />          
        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        <p className="card-text">{kpi.description && kpi.description + ". "}{this.state.translation.string003} {kpi.percentageOfTotalReward}%. {this.state.translation.string001} {kpi.onTargetReward} {this.state.currency} </p>
                        
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} language={this.state.language}></BonusTableSteps>
                        <br />
                        </div>
                        
                        )
                    })}
      <LoadingSpinner/>
      <Form onSubmit={this.handleSubmit}>
      <Table borderless>
      <tbody>
        <tr>
           <td><Link className="float-left" to={`/editonebonus/` + this.state.bonusAgreementId}><Button  variant="secondary"><Trans i18nKey="navigation.return"/></Button></Link></td>
           <td><Button variant="primary" type="submit" className="float-right mr-1">{this.state.approvalLevel === 1 ? <Trans i18nKey="approval.sign"/> : this.state.translation.string012}</Button> 
           <Share bonusAgreementId={this.state.bonusAgreementId} ></Share></td>
      </tr></tbody>
      </Table>
      </Form>
      <br /><br />

    </Layout>
    </React.Fragment>
  );
};
};

export default Sign;