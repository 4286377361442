import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../_helpers/handle-response';
import LogRocket from 'logrocket';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    loginaftersignup,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function loginaftersignup(user){
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
    return user;
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/identity/users/authenticate';
    const connectionstring = connectionstring1.concat("" , connectionstring2);

    return fetch(connectionstring, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            console.log("logged in");
            console.log("process.env.NODE_ENV" + process.env.NODE_ENV);

            if (process.env.NODE_ENV === 'production') {
                console.log("LogRocket added user");
                LogRocket.identify(JSON.parse(localStorage.getItem('currentUser')).userName, {
                    name:  JSON.parse(localStorage.getItem('currentUser')).fullName,
                    email: JSON.parse(localStorage.getItem('currentUser')).userName,
                  });
              }
            return user;
        }).catch(function(error) {
            return Promise.reject();
            /*window.location.reload(true);*/
        })
        ;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
