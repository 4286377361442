import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import BonusTableSteps from './components/bonustablesteps';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';

class ListOneBonusWithResult extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    token: JSON.parse(localStorage.getItem('currentUser')).token,
    bonusAgreementId: "",
    name: "",
    onTargetReward: null,
    resultAmount: null,
    period: "",
    employeeUserName: "",
    managerUserName: "",
    employeeFullName: "",
    managerFullName: "",
    phase: "",
    kpi: [],
    data_bonuslevels: [],
    isLoading: true,
    errors: null
  }
}
  // Now we're going to make a request for data using axios
  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpiapproval/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    axios
      // This is where the data is hosted
      .get(connectionstring,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          phase: response.data.phase,
          currency: response.data.currency,
          language: response.data.language,
          translation: response.data.translationStringList,
          kpi: response.data.kpi,
          approval: response.data.approval, 
          data_bonuslevels: [],
          isLoading: false
        });
        this.builddatatable();
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getBonusAgreement();
  };
 
  builddatatable(){
    var tableswithdata = {};
    tableswithdata = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      newkpi = [];
      for (var k=0; k<5; k++) {
        var newbonuslevel = {};
        newbonuslevel.tb_percentage = j*10;
        newbonuslevel.tb_level = parseInt(j)*100+parseInt(k);
        newbonuslevel.tb_levelaxis = this.state.kpi[j].unit;
        newbonuslevel.tb_payout = j*k*100;
        newbonuslevel.tb_payoutaxis = this.state.currency;
        newkpi.push(newbonuslevel);
      }
      tableswithdata.push(newkpi);
    }

    this.setState(state => {
      return {
        bonusAgreementId: this.state.bonusAgreementId,
        name: this.state.name,
        onTargetReward: this.state.onTargetReward,
        resultAmount: this.state.resultAmount,
        period: this.state.period,
        employeeUserName: this.state.employeeUserName,
        managerUserName: this.state.managerUserName,
        employeeFullName: this.state.employeeFullName,
        managerFullName: this.state.managerFullName,
        phase: this.state.phase,
        kpi: this.state.kpi,
        data_bonuslevels: tableswithdata,
      }
    })
    this.forceUpdate();
  };



  // Putting that data to use
  render() {

    
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="ViewSignedResultsAgreement"><LoadingSpinner/></div>;
    }

    return (
      <React.Fragment>
        <Layout>
        <br />
        <h2>{this.state.translation.string024}</h2>
        <br />
        <h4 className="card-title">{this.state.name}</h4>
        {this.state.translation.string018} {this.state.employeeFullName}<br />
        {this.state.translation.string022}: <b>{this.state.resultAmount} {this.state.currency}</b><br />
        {this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}

          <br />   <br />       

        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        {kpi.description} <br />
                        {this.state.translation.string003} {kpi.percentageOfTotalReward}% <br />
                        {this.state.translation.string001} {kpi.onTargetReward} {this.state.Currency} <br />
                        {this.state.translation.string019} {kpi.result} {kpi.unit} {this.state.translation.string020} <b>{kpi.resultAmount} {this.state.currency} </b> <br /><br />
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} resultAmount={kpi.resultAmount} language={this.state.language}></BonusTableSteps>
                        <br />
                        </div>
                        
                        )
                    })}
        <br />

        {this.state.phase === "Signed Results"       
        ? <div>
            <h2>{this.state.translation.string026}</h2><br />
          </div>
        : <h2>{this.state.translation.string025}</h2> 
        }   
        <h4>{this.state.translation.string027}</h4><br />
                
          {this.state.approval.map((approval,j) => {
                        return (
                          <div key={j} >
                          {approval.approvalStatus === 'Signed' 
                              ? <div>
                                  <h5>{approval.fullName}</h5>
                                  {approval.phase === 'Signed' ? this.state.translation.string028 : this.state.translation.string029}
                                  <br /><br />
                                  <Table >
                                    <tbody>
                                  <tr>
                                      <td>{this.state.translation.string030}: {approval.email}</td>
                                      <td>{this.state.translation.string031}: {approval.dateAnswered}</td>
                                      <td>{this.state.translation.string032}: {approval.timeAnswered} </td>
                                      <td>{this.state.translation.string037}: {approval.ipAddress}</td>
                                  </tr>
                                  <tr>
                                      <td>{this.state.translation.string033}: {approval.city}</td>
                                      <td>{this.state.translation.string034}: {approval.country}</td>      
                                      <td>{this.state.translation.string035}: {approval.longitude}</td>
                                      <td>{this.state.translation.string036}: {approval.latitude}</td>                            
                                  </tr>
                                  </tbody>
                                  </Table>
                                  </div>
                              : <div>
                                {approval.phase === "Set Results"
                                ? <div></div>
                                : <div>
                                  <h5>{approval.fullName}</h5>
                                  {this.state.translation.string038}. {approval.dateSent ? this.state.translation.string039 + " " + approval.dateSent : this.state.translation.string040}
                                  <br /><br />
                                  </div>
                               }
                              </div>}
                          </div>
                          )
                      })
                      }

        <Link className="float-left" to={`/bonusagreements`}>Return</Link>
       </Layout>
      </React.Fragment>
    );
  }
}

export default ListOneBonusWithResult;