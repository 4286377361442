import React, { Component } from "react";
import './App.css';
import { Accordion, Card } from "react-bootstrap";
import LoadingSpinner from "./components/LoadingSpinner";
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown   } from '@fortawesome/free-solid-svg-icons';
import i18n from './i18n';
import Footer from './Footer.js';



class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    i18n.changeLanguage('en');
  };


 


  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
      <div className="p-3 bg-white text-dark">
        <Container>
          <h2  className="text-primary">Privacy Policy</h2>
          <Accordion>
          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 1. Introduction
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                <h5>Privacy</h5>
                                At Manage Bonus (a brand name of the company Paseedu AB) (“us”, “we”, “our” or the “Company”) we value your privacy and the importance of safeguarding your data. This Privacy Policy ("Privacy Policy") describes our privacy practices for the activities set out below. As per your rights, we inform you how we collect, store, access, and otherwise process information relating to individuals. In this Policy, "Personal Data" refers to any information that on its own, or in combination with other available information, can distinguish an individual.<br /><br />
<h5>Regulations</h5>
We are committed to protecting your privacy in accordance with the highest level of privacy regulation. As such, we follow the obligations under
<ul>
<li>the EU's General Protection Data Regulation (GDPR)</li>
<li>California's Consumer Protection Act (CCPA) and California Online Privacy Protection Act (CalOPPA)</li>
<li>Canada's Personal Information Protection and Electronic Documents Act (PIPEDA) and the applicable provincial legislations</li>
<li>Brazil’s Data Protection Legislation (LGPD)</li>
</ul>
<h5>Scope</h5>
This policy applies to the Manage Bonus websites and domains.<br /><br />

This Policy does not apply to third-party applications, websites, products, services or platforms that may be accessed through  links that we may provide to you. These sites are owned and operated independently from us, and they have their own separate privacy and data collection practices. Any personal data that you provide to these websites will be governed by the third-party’s own privacy policy. We cannot accept liability for the actions or policies of these independent sites, and we are not responsible for the content or privacy practices of such sites.
<br /><br />
<h5>Processing Activities</h5>
This Privacy Policy applies when you interact with us by doing any of the following:
<ul>
<li>Make use of our application and services as an authorized user</li>
<li>Visit any of our websites that link to this Privacy Statement</li>
<li>Receive any communication from us including newsletters, emails, calls, or texts</li>
</ul>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/>  2. Personal Data We Collect
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  

                                <h5>Data You Provide</h5>
When you sign up, accept a subscription, or sign a bonus agreement we collect personal data to document the process.

This data includes:
<ul>
    <li>Account information such as your name, email address, and password</li>
    <li>Company information such as company name, address and logo</li>
    <li>Subscription details</li>
    <li>Location Data, ip-address, time stamp</li>
    <li>Authentication records</li>
    <li>Data from third parties e.g. google analytics, linked in</li>
    <li>Interaction usage of the software</li>
    <li>Device type</li>
</ul>
<h5>Other individuals</h5>
If you provide us, or our service providers, with any Personal Data relating to other individuals, you represent that you have the authority to do so and acknowledge that it will be used in accordance with this Privacy Statement. If you believe that your Personal Data has been provided to us improperly, or to otherwise exercise your rights relating to your Personal Data, please contact us by using the information set out in the Contact Us section below.
<br /><br />
Our websites and services may contain links to other websites, applications and services maintained by third parties. The information practices of such other services, or of social media networks that host our branded social media pages, are governed by third parties’ privacy statements, which you should review to better understand those third parties’ privacy practices.
<br /><br />
<h5>Purpose</h5>
Purpose and Legal Basis for the Processing of Personal Data
We collect and use personal data about you with your consent to provide, maintain, and develop our products and services and understand how to improve them.

These purposes include:
<ul>
<li>To deliver your product or service</li>
<li>To fulfill orders</li>
<li>Building a Safe and Secure Environment</li>
<li>To Verify or authenticate your identity</li>
<li>Investigate and prevent security incidents such as breaches, attacks and hacks.</li>
<li>Providing, Developing, and Improving our Products and Services</li>
<li>Deliver, maintain, debug and improve our products and services.</li>
<li>Enable you to access Manage Bonus services and set up accounts.</li>
<li>Provide you with technical and customer support.</li>
</ul>
Where we process your personal data to provide a product or service, we do so because it is necessary to perform contractual obligations. All of the above processing is necessary in our legitimate interests to provide products and services and to maintain our relationship with you and to protect our business for example against fraud. Consent will be required to initiate services with you. New consent will be required If any changes are made to the type of data collected. Within our contract, if you fail to provide consent, some services may not be available to you.
<br /><br />
<h5>Sharing and Disclosure</h5>
We will share your personal data with third parties only in the ways set out in this Policy or set out at the point when the personal data is collected.

We also use Google Tag Manager, Google Analytics, Linkedin Insights and Logrocket to help us understand how our customers use the site. 

We may use or disclose your personal data in order to comply with a legal obligation, in connection with a request from a public or government authority, or in connection with court or tribunal proceedings, to prevent loss of life or injury, or to protect our rights or property. Where possible and practical to do so, we will tell you in advance of such disclosure.
<br /><br />
<h5>Service Providers and Other Third Parties</h5>
We may use a third party service provider, independent contractors, agencies, or consultants to deliver and help us improve our products and services. Service providers may be within or located outside the EEA. We may share your personal data with marketing agencies, database service providers, backup and disaster recovery service providers, email service providers and others but only to maintain and improve our products and services. For further information on the recipients of your Personal Data, please contact us by using the information in the Contact Us section below.


                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/>  3. Cookie policy
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                When you visit a Manage Bonus website, we automatically collect and store information about your visit using browser cookies (files which are sent by us to your computer), or similar technology. 
                                Only necessary cookies are used to offer you the service. Should you turn those cookies off, then the service will no longer function properly. 
                                 </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 4. Retention & Deletion
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                We will only retain your personal data for as long as necessary for the purpose for which that data was collected and to the extent required by applicable law. When we no longer need personal data, we will remove it from our systems and / or take steps to anonymize it.                             
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 5. Your rights to Your Personal Data
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="4">
                                <Card.Body>

                                Depending on your geographical location and citizenship, your rights are subject to local data privacy regulations. These rights may include:
<ul>
<li>Right to Access(PIPEDA, GDPR Article 15, CCPA, LGPD)
You have the right to request a copy of the personal data we are processing about you.
</li>
<li>
Right to Rectification (PIPEDA, GDPR Article 16, LGPD)
You have the right to have incomplete or inaccurate personal data that we process about you rectified.
</li>
<li>
Right to be Forgotten (right to erasure) (GDPR Article 17, CCPA, LGPD)
You have the right to request that we delete personal data that we process about you, except we are not obliged to do so if we need to retain such data in order to comply with a legal obligation or to establish, exercise or defend legal claims.
</li>
<li>
Right to Restriction of Processing (GDPR Article 18, LGPD)
You have the right to restrict our processing of your personal data where you believe such data to be inaccurate; our processing is unlawful; or if we no longer need to process such data for a particular purpose unless we are not able to delete the data due to a legal or other obligation or because you do not wish us to delete it.
</li>
<li>
Right to Portability (PIPEDA, GDPR Article 20, LGPD)
You have the right to obtain personal data we hold about you, in a structured, electronic format, and to transmit such data to another data controller, where this is (a) personal data which you have provided to us, and (b) if we are processing that data on the basis of your consent or to perform a contract with you or the third party that subscribes to Enzuzo Inc. services.
</li>
<li>
Right to Objection (GDPR Article 21, LGPD)
Where the legal justification for our processing of your personal data is our legitimate interest, you have the right to object to such processing on grounds relating to your particular situation. We will abide by your request unless we have compelling legitimate grounds for processing which override your interests and rights, or if we need to continue to process the data for the establishment, exercise or defense of a legal claim.
</li>
<li>
Right Not to Be Discriminated (CCPA)
You have the right not to be denied service or have an altered experience based on having executed any of your CCPA rights.
</li>
</ul>

<h5>Withdrawing Consent</h5>
If you have consented to our processing of your personal data, you have the right to withdraw your consent at any time, free of charge, such as where you wish to unsubscribe from marketing messages that you receive from us. If you wish to withdraw your consent, please contact us using the information found at the bottom of this page.
<br /><br />
<h5>How to Exercise Your Rights</h5>
You can make a request to exercise any of these rights in relation to your personal data by sending the request to our privacy team by using the form below.

For your own privacy and security, at our discretion, we may require you to prove your identity before providing the requested information.


                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 6. Changes
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                We may modify this Policy at any time. If we make changes to this Policy then we will post an updated version of this Policy on this website. When using our services, you will be asked to review and accept our Privacy Policy. In this manner, we may record your acceptance and notify you of any future changes to this Policy.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 7. Complaints  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                If you have a complaint about this Policy or any element of your personal information that we hold then please contact us below. 
                                <br />
                                If you are not satisfied, then you have the right to lodge a complaint with the local data protection authority.
                                 </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> 8. Contact Us
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                To request for your data to be deleted, or ask a question about your data privacy, please contact us at contact@managebonus.com
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

          </Accordion>
        </Container>
        </div>


        <Container><br />
            </Container> 

            <Footer/>

    </React.Fragment>
  );
};
};

export default Privacy;