import React, { Component } from "react";
import { Layout } from './components/Layout';
import { Trans } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import videoIntro from './assets/videohelpintro.mp4';
import videoFirstBonusAgreement from './assets/videohelpheader.mp4';
import imageVideoIntro from './assets/imagevideohelpintro.png';
import imageVideoFirstBonusAgreement from './assets/imagevideofirstbonusagreement.png';


class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new: true,
      isLoading: false,
      redirecttype: 'save',
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      userId: JSON.parse(localStorage.getItem('currentUser')).id, 
      videoIntroSrc: '',
      videoFirstBonusAgreementSrc: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      videoIntroSrc: videoIntro, 
      videoFirstBonusAgreementSrc: videoFirstBonusAgreement
  })

  };





  handleSubmit = event => {
    event.preventDefault();

  };

render() {
 
  if (this.state.redirect) {
    return <Redirect to={this.state.redirect} />
  }


  const { isLoading } = this.state;

  if (isLoading) {
    return <div className="Settings"><LoadingSpinner/></div>;
  }

  return (
    <React.Fragment>
    <Layout>
        <br />
        <h2><Trans i18nKey="help.getting-started"/></h2>
        <Trans i18nKey="help.getting-started-description"/>
        <br /><br/ >
        <Form onSubmit={this.handleSubmit}>

            <Form.Group ><Row><Col><h5><Trans i18nKey="help.how-works-manage-bonus"/></h5>
            <Trans i18nKey="help.how-works-manage-bonus-description"/>
            </Col><Col><video ref="vidRef" src={this.state.videoIntroSrc} poster={imageVideoIntro} controls style={{width: '100%'}}></video>  </Col></Row></Form.Group>
            
            <Form.Group ><Row><Col><h5><Trans i18nKey="help.make-first-bonus-agreement"/></h5>
            <Trans i18nKey="help.make-first-bonus-agreement-description"/>
            </Col><Col><video ref="vidRef" src={this.state.videoFirstBonusAgreementSrc}  poster={imageVideoFirstBonusAgreement} controls style={{width: '100%'}}></video>  </Col></Row></Form.Group>

            </Form>

    </Layout>
    </React.Fragment>
  );
}}

export default Help;