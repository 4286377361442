import React, { Component } from "react";
import 'reactjs-popup/dist/index.css';
import { Button, Modal} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import "bootstrap/dist/css/bootstrap.css";
import videoHelpHeader from '../assets/videohelpheader.mp4';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion  } from '@fortawesome/free-solid-svg-icons';



class HelpVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false, 
      videoSource: videoHelpHeader, 
      videoHeader: 'Make a Bonus Agreement'
    };
  }

  componentDidMount() {

    var newVideoSource;
    var newVideoHeader;
    newVideoSource = videoHelpHeader;
    newVideoHeader = <Trans i18nKey="help.make-bonus-agreement"/>

    if (this.props.videoName === 'getting-started') { 
      newVideoSource = videoHelpHeader;
      newVideoHeader = <Trans i18nKey="help.make-bonus-agreement"/>
    }
    
    this.setState(state => { 
     return {
        videoSource: newVideoSource, 
        videoHeader: newVideoHeader
    }})
     


}



  handleClose = () => {
    this.setState(state => {
      return {
        show: false
     };
  })}

  handleShow = () => {
    this.setState(state => {
      return {
        show: true
     };
  })}


  render() {

    return (
        <div>
          <Button variant="success" className="float-right mr-1" onClick={this.handleShow}><FontAwesomeIcon icon={faQuestion} /> <Trans i18nKey="help.help"/></Button>
          <Modal show={this.state.show} onHide={this.handleClose} style={{width: '900px'}}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.videoHeader}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
             
                <video ref="vidRef" src={this.state.videoSource} controls style={{width: '100%'}}></video>  

              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}><Trans i18nKey="navigation.close"/></Button>
              </Modal.Footer>
            </Modal>
        </div>
    );
  }
}

export default HelpVideo;