import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import i18n from './i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus  } from '@fortawesome/free-solid-svg-icons';

class EditKpiTableSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      returnpage: null,
      formIsvalid: true,
      downloadcorrectiontomake: true,
      new: true,
      finishedloading: false,
      errorCurrency: "",
      kpi: null
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
 
  handleKeyPress (event)  {
    if(event.charCode === 13){
      event.preventDefault();
      this.correctHeaderAndTables();
    }
  }

  correctHeaderAndTables() {

    console.log("Check what happened");
    console.log("This direction: " + this.state.direction);
    if (this.state.direction  === "Up") {

      var model_up = {};
      model_up.KpiStep = [];
  
    for (var m=0; m<this.state.kpiStep.length; m++) {
      var newkpistep_up = {};
      newkpistep_up.kpiStepId = this.state.kpiStep[m].kpiStepId;
      newkpistep_up.kpiId = this.state.kpiStep[m].kpiId;
      newkpistep_up.managerUserName = this.state.kpiStep[m].managerUserName;
      newkpistep_up.kpiName = this.state.kpiStep[m].kpiName;
      newkpistep_up.stepNumber = this.state.kpiStep[m].stepNumber;
      newkpistep_up.achievePercentage = this.state.kpiStep[m].achievePercentage;
      newkpistep_up.payoutPercentage = this.state.kpiStep[m].payoutPercentage;
      newkpistep_up.achieveAmount = parseInt(this.state.kpiStep[m].achievePercentage / 100 * this.state.goal);
      newkpistep_up.payoutAmount = parseInt(this.state.kpiStep[m].payoutPercentage / 100 * this.state.onTargetReward);
      model_up.KpiStep.push(newkpistep_up);
    }
    console.log('Got here');

    this.setState(state => { 
      return {
        kpiStep: model_up.KpiStep
     }})



    } 
    
    if (this.state.direction  === "Down")
    {

      /* After download we need to make corrections of the headers */

      if (this.state.downloadcorrectiontomake) {
        
        var scaleUnderGoal; 
        var scaleOverGoal;
        if ((this.state.scaleUnderGoal === "scale-2-1") ||  (this.state.scaleUnderGoal === "scale-1-1") || (this.state.scaleUnderGoal === "scale-1-2"))
          { scaleUnderGoal = this.state.scaleUnderGoal} else
          { scaleUnderGoal = "scale-2-1"; }
      
        if ((this.state.scaleOverGoal === "scale-2-1") ||  (this.state.scaleOverGoal === "scale-1-1") || (this.state.scaleOverGoal === "scale-1-2"))
          { scaleOverGoal = this.state.scaleOverGoal} else
          { scaleOverGoal = "scale-1-2"; }

        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        
        var tmp;
        var numberofkpisteps = this.state.kpiStep.length;
        for (var i=0; i<this.state.kpiStep.length; i++) 
        {
            tmp = this.state.kpiStep[i].achievePercentage;
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
          }
        /* highest and lowest should only be set after downloading */

        if ((lowest < 1) || (lowest > 99)) { lowest = 60; }
        if ((highest > 500) || (highest < 101)) { highest = 150; }

        this.setState({
          downloadcorrectiontomake: false,
          scaleUnderGoal: scaleUnderGoal,
          scaleOverGoal: scaleOverGoal,
          lowCutOff: lowest, 
          highCutOff: highest,
          numberOfKpiSteps: numberofkpisteps
        });

    }



      var stepsbetterthangoal = 0;
      var stepsworsethangoal = 0;
      var mediumstep = parseInt(this.state.kpiStep.length / 2);
      var ratio = this.state.goal / this.state.kpiStep[mediumstep].achieveAmount;

       /* We need first to centralize the numbers */


      for (var k=0; k<this.state.kpiStep.length; k++) 
      {
        /*this.state.kpiStep[k].achieveAmount = this.state.kpiStep[k].achieveAmount * ratio;*/
        if (parseInt(this.state.goal) > parseInt(this.state.kpiStep[k].achieveAmount * ratio) ) {stepsbetterthangoal = stepsbetterthangoal + 1;}
        if (parseInt(this.state.goal) < parseInt(this.state.kpiStep[k].achieveAmount * ratio)) {stepsworsethangoal = stepsworsethangoal + 1;}
      }

      var achievepercentageperstepbetterthangoal = Math.round((this.state.highCutOff - 100 )/stepsbetterthangoal);
      var achievepercentageperstepworsethangoal = Math.round((100 - this.state.lowCutOff)/stepsworsethangoal);
      var payoutpercentageperstepbetterthangoal;
      var payoutpercentageperstepworsethangoal;
 
    payoutpercentageperstepbetterthangoal = achievepercentageperstepbetterthangoal; 
      if (this.state.scaleOverGoal === "scale-2-1") { payoutpercentageperstepbetterthangoal = Math.round(achievepercentageperstepbetterthangoal / 2); }
      if (this.state.scaleOverGoal === "scale-1-2") { payoutpercentageperstepbetterthangoal = Math.round(achievepercentageperstepbetterthangoal * 2); }
      
      payoutpercentageperstepworsethangoal = achievepercentageperstepworsethangoal; 
      if (this.state.scaleUnderGoal === "scale-2-1") { payoutpercentageperstepworsethangoal = Math.round(achievepercentageperstepworsethangoal * 2); }
      if (this.state.scaleUnderGoal === "scale-1-2") { payoutpercentageperstepworsethangoal = Math.round(achievepercentageperstepworsethangoal / 2); }
 
 

    var model = {};
    model.KpiStep = [];

  for (var j=0; j<this.state.kpiStep.length; j++) {
    var newkpistep = {};
    newkpistep.kpiStepId = this.state.kpiStep[j].kpiStepId;
    newkpistep.kpiId = this.state.kpiStep[j].kpiId;
    newkpistep.managerUserName = this.state.kpiStep[j].managerUserName;
    newkpistep.kpiName = this.state.kpiStep[j].kpiName;
    newkpistep.stepNumber = this.state.kpiStep[j].stepNumber;
    newkpistep.achievePercentage = this.state.kpiStep[j].achievePercentage;
    newkpistep.payoutPercentage = this.state.kpiStep[j].payoutPercentage;
    newkpistep.achieveAmount = this.state.kpiStep[j].achieveAmount * ratio;
    newkpistep.payoutAmount = this.state.kpiStep[j].payoutAmount;
    model.KpiStep.push(newkpistep);
  }

  var model_sorted = model.KpiStep.sort((a, b) => (a.achieveAmount > b.achieveAmount) ? 1 : -1)
  
 
  var actualstepsbetterthangoal = stepsbetterthangoal;
  var actualstepsworsethangoal = 1;

  var model_sortedupdated = {};
  model_sortedupdated.kpiStep = [];

  for (j=0; j<this.state.kpiStep.length; j++) {
    newkpistep = {};
    newkpistep.kpiStepId = -1;
    newkpistep.kpiId = this.state.kpiId;
    newkpistep.managerUserName = this.state.managerUserName;
    newkpistep.kpiName = this.state.name;
    newkpistep.stepNumber = this.state.kpiStep.length - j;
    newkpistep.achievePercentage = Math.round(newkpistep.stepNumber * 10 * 1.5);
    newkpistep.payoutPercentage = Math.round(newkpistep.stepNumber * 10 * 1.5);
    newkpistep.achieveAmount = Math.round(model_sorted[j].achieveAmount);
    if (actualstepsbetterthangoal > 0 ) {
      if (actualstepsbetterthangoal === stepsbetterthangoal) {newkpistep.achievePercentage = Math.round(this.state.highCutOff); } 
      else { newkpistep.achievePercentage = Math.round(100 + achievepercentageperstepbetterthangoal * actualstepsbetterthangoal);}
      newkpistep.payoutPercentage = Math.round(100 + payoutpercentageperstepbetterthangoal * actualstepsbetterthangoal);
      actualstepsbetterthangoal = actualstepsbetterthangoal - 1;
    } else {
      if (parseInt(newkpistep.achieveAmount) === parseInt(this.state.goal)) {
        newkpistep.achievePercentage = 100;
        newkpistep.payoutPercentage = 100;
        actualstepsbetterthangoal = actualstepsbetterthangoal - 1;
      } else {
        if (j === ( this.state.kpiStep.length - 1)) {newkpistep.achievePercentage = Math.round(this.state.lowCutOff);} 
        else {newkpistep.achievePercentage = Math.round(100 - achievepercentageperstepworsethangoal * actualstepsworsethangoal); }
        newkpistep.payoutPercentage = Math.round(100 - payoutpercentageperstepworsethangoal * actualstepsworsethangoal);
        actualstepsworsethangoal = actualstepsworsethangoal + 1;
      }
    }
    if (newkpistep.payoutPercentage < 1 ) {newkpistep.payoutPercentage = 0;  }
    newkpistep.payoutAmount = Math.round(newkpistep.payoutPercentage / 100 * this.state.onTargetReward);
    model_sortedupdated.kpiStep.push(newkpistep);
  }

  this.setState(state => { 
    return {
      kpiStep: model_sortedupdated.kpiStep
   }})


    }
    if (this.state.direction === "Down") {
      return model_sortedupdated.kpiStep;
    } else { return null};
    
  }


  getKpiWithKpiStep() {

    var connectionstring4 = process.env.REACT_APP_apiUrl + '/api/KpiStep/' + this.props.match.params.kpiId

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring4
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          returnpage: this.props.match.params.returnPage,
          new: false,
          finishedloading: true, 
          kpiId: response.data.kpiId,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name, 
          description: response.data.description, 
          type: response.data.type, 
          goal: response.data.goal,
          result: response.data.result,
          resultPercentage: response.data.resultPercentage,
          percentageOfTotalReward: response.data.percentageOfTotalReward,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          unit: response.data.unit,
          usedFor: response.data.usedFor,
          direction: response.data.direction,
          managerUserName: response.data.managerUserName,
          sortOrder: response.data.sortOrder,
          currency: response.data.currency,
          lowCutOff: response.data.lowCutOff,
          highCutOff: response.data.highCutOff,
          numberOfKpiSteps: response.data.numberOfKpiSteps,
          scaleUnderGoal: response.data.scaleUnderGoal,
          scaleOverGoal: response.data.scaleOverGoal,           
          kpi: response.data,
          kpiStep: response.data.kpiStep,
          isLoading: false
        });
        this.correctHeaderAndTables();
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };
  
  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getKpiWithKpiStep();
  };

  handleKpiChange = (e) => {
    const {name, value} = e.target;

    var newkpiId = this.state.kpiId;
    var newbonusAgreementId = this.state.bonusAgreementId;
    var newname = this.state.name;
    var newdescription = this.state.description; 
    var newtype = this.state.type;
    var newgoal = this.state.goal; 
    var newresult = this.state.result; 
    var newresultPercentage = this.state.resultPercentage; 
    var newpercentageOfTotalReward = this.state.percentageOfTotalReward; 
    var newonTargetReward = this.state.onTargetReward;  
    var newunit = this.state.unit; 
    var newusedFor = this.state.usedFor; 
    var newdirection = this.state.direction; 
    var newmanagerUserName = this.state.managerUserName; 
    var newsortOrder = this.state.sortOrder; 
    var newcurrency = this.state.currency; 
    var newlowCutOff = this.state.lowCutOff;
    var newhighCutOff = this.state.highCutOff;
    var newnumberOfKpiSteps = this.state.numberOfKpiSteps;
    var newscaleUnderGoal = this.state.scaleUnderGoal;
    var newscaleOverGoal = this.state.scaleOverGoal;


    if (name === "lowCutOff") {
      newlowCutOff=value;
      var newerrorLowCutOff = "";
      if (isNaN(value)) {
        newlowCutOff = 0;
        newerrorLowCutOff = i18n.t('error-kpi.range-0-99');}
      if (value < 0) {
        newlowCutOff = 0; 
        newerrorLowCutOff = i18n.t('error-kpi.range-not-under-0');
      }
      if (value < 1) {newlowCutOff = 0; }
      if (value > 99) { newerrorLowCutOff = i18n.t('error-kpi.range-less-than-100');}
    }

    if (name === "goal") {
      newgoal=value;
      var newerrorGoal= "";
      if (isNaN(value)) {
        newgoal = 0;
      }
      if (value < 0) {newgoal = 0}
    }

    if (name === "highCutOff") {
      newhighCutOff=value;
      var newerrorHighCutOff= "";
      if (isNaN(value)) {newhighCutOff = 100;}
      if (value < 1) {newhighCutOff = 1}
      if (value < 101) {newerrorHighCutOff= i18n.t('error-kpi.range-over-100');}
    }

    if (name === "scaleUnderGoal"){
      if (value === i18n.t('kpi.scale-2-1')) { newscaleUnderGoal = 'scale-2-1'; }
      if (value === i18n.t('kpi.scale-1-1')) { newscaleUnderGoal = 'scale-1-1'; }
      if (value === i18n.t('kpi.scale-1-2')) { newscaleUnderGoal = 'scale-1-2'; }
    }

    if (name === "scaleOverGoal"){
      if (value === i18n.t('kpi.scale-2-1')) { newscaleOverGoal = 'scale-2-1'; }
      if (value === i18n.t('kpi.scale-1-1')) { newscaleOverGoal = 'scale-1-1'; }
      if (value === i18n.t('kpi.scale-1-2')) { newscaleOverGoal = 'scale-1-2'; }

    }


    /* We need to recalculate the bonussteps also */

    this.setState({      
      kpiId: newkpiId,
      bonusAgreementId: newbonusAgreementId,
      name: newname,
      description: newdescription, 
      type: newtype,
      goal: newgoal, 
      result: newresult, 
      resultPercentage: newresultPercentage, 
      percentageOfTotalReward: newpercentageOfTotalReward, 
      onTargetReward: newonTargetReward,  
      unit: newunit, 
      usedFor: newusedFor, 
      direction: newdirection, 
      managerUserName: newmanagerUserName, 
      sortOrder: newsortOrder, 
      currency: newcurrency, 
      lowCutOff: newlowCutOff,
      highCutOff: newhighCutOff,
      numberOfKpiSteps: newnumberOfKpiSteps,
      scaleUnderGoal: newscaleUnderGoal,
      scaleOverGoal: newscaleOverGoal, 
      errorLowCutOff: newerrorLowCutOff,
      errorHighCutOff: newerrorHighCutOff, 
      errorGoal: newerrorGoal
    });

   /* this.correctHeaderAndTables();*/

  }

  handleKpiStepChange = (e, index) => {
   
     const {name, value} = e.target;
     const list = [...this.state.kpiStep];
     /*list[index][name]=value;*/

    /* This is only active for Up */
     if (name === "achievePercentage") {

      list[index]["errorAchievePercentage"]="";
      if (value > 0) {
        list[index]["achievePercentage"] = value;
        list[index]["achieveAmount"] = this.state.goal * value / 100;
      } else {
        list[index]["achievePercentage"] = value;
        list[index]["achieveAmount"] = 0 
      }
    }

    if (name === "payoutPercentage") {
      list[index]["errorPayoutPercentage"]="";
      if (value > 0) {
        list[index]["payoutPercentage"] = value;
        list[index]["payoutAmount"] = this.state.onTargetReward * value / 100;
      } 
      else {
        list[index]["payoutPercentage"] = value; 
        list[index]["payoutAmount"] = 0; 
      }
      if (this.state.direction === "Down") { list[index]["achievePercentage"] = list[index]["payoutPercentage"];  }
    }

    /* This is only active for Down */
    if (name === "achieveAmount") {
      if (!value || (value < 1)) 
        {list[index]["achieveAmount"] = 0; } 
      else
        { list[index]["achieveAmount"] = parseInt(value); 
       }
    }

     this.setState({      
       kpiStep: list
     });


   }

  handleAddKpiStep = () => {
      const list = [...this.state.kpiStep];

      const newKpiStep = {};
      newKpiStep.kpiStepId = -1;
      newKpiStep.managerUserName = this.state.managerUserName;
      newKpiStep.kpiName = this.state.name;
      newKpiStep.achievePercentage = "";
      newKpiStep.payoutPercentage = "";
      newKpiStep.stepNumber = -1;
      newKpiStep.achievePercentage = "";
      newKpiStep.payoutPercentage = "";
      newKpiStep.achieveAmount = "";
      newKpiStep.payoutAmount = "";


      list.unshift(newKpiStep);

      this.setState(state => {
        return {
          kpiStep: list
       };
     });

  }

  handleRemoveKpiStep = index => {
    const list = [...this.state.kpiStep];
    list.splice(index,1);

    this.setState(state => {
       return {
        kpiStep: list,
      };
    });

  }

  validatekpiandkpistep() {
  /*this.reseterrors();*/
    var formisvalid = true;
    const kpisteplist = [...this.state.kpiStep];

    if (this.state.direction  === "Up") {

    for (var k=0; k<this.state.kpiStep.length; k++) {

      if (isNaN(this.state.kpiStep[k].achievePercentage) || this.state.kpiStep[k].achievePercentage < 1) {
        kpisteplist[k].errorAchievePercentage = i18n.t('error-kpi.positive-number');
        formisvalid = false;
      } else {

      }

      if (isNaN(this.state.kpiStep[k].payoutPercentage) || this.state.kpiStep[k].payoutPercentage < 1) {
        kpisteplist[k].errorPayoutPercentage = i18n.t('error-kpi.positive-number');
        formisvalid = false;
      } else {
      }


     }

    }


     if (this.state.direction  === "Down") {
      for (var m=0; m<this.state.kpiStep.length; m++) {

        if (isNaN(this.state.kpiStep[m].achieveAmount)) {
          kpisteplist[m].errorAchieveAmount = "Achieve amount has to be a valid number"
          formisvalid = false;
        } else {

        }

      }

      if (isNaN(this.state.lowCutOff)) {
        kpisteplist[m].errorLowCutOff = i18n.t('kpi.no-low-cut-off');
        formisvalid = false;
      }

      if (isNaN(this.state.goal)) {
        kpisteplist[m].errorGoal = i18n.t('kpi.no-goal');
        formisvalid = false;
      }

      if (isNaN(this.state.highCutOff)) {
        kpisteplist[m].errorHighCutOff = "High cut off is missing";
        kpisteplist[m].errorHighCutOff = i18n.t('kpi.no-high-cut-off');
        formisvalid = false;
      }

     }

     if (formisvalid === true) {
         this.setState(state => { return { formIsvalid: true  }}); 
     } else {
         this.setState(state => { return { formIsvalid: false  }}); 
    }

    this.setState({      
      kpiStep: kpisteplist
    });

    return formisvalid;
  };

  testfunction ()
  {
    return true;
  }

  handleUpdate = event => {
    this.correctHeaderAndTables();
  }


  handleSubmit = event => {
    event.preventDefault();
    var sendformisvalid = false;
    sendformisvalid = this.validatekpiandkpistep();
    var model = {};

    model.KpiId = this.state.kpiId;
    model.BonusAgreementId = this.state.bonusAgreementId;
    model.Name = this.state.name;
    model.Description = this.state.description; 
    model.Type = this.state.type;
    model.Goal = parseFloat(this.state.goal); 
    model.Result = this.state.result; 
    model.ResultPercentage = this.state.resultPercentage; 
    model.PercentageOfTotalReward = this.state.percentageOfTotalReward; 
    model.OnTargetReward = this.state.onTargetReward; 
    model.ResultAmount = this.state.resultAmount
    model.Unit = this.state.unit; 
    model.UsedFor = this.state.usedFor; 
    model.Direction = this.state.direction; 
    model.ManagerUserName = this.state.managerUserName; 
    model.SortOrder = this.state.sortOrder; 
    model.Currency = this.state.currency; 
    model.LowCutOff = parseFloat(this.state.lowCutOff);
    model.HighCutOff = parseFloat(this.state.highCutOff);
    model.NumberOfKpiSteps = parseInt(this.state.numberOfKpiSteps);
    model.ScaleUnderGoal = this.state.scaleUnderGoal;
    model.ScaleOverGoal = this.state.scaleOverGoal;
    
    model.KpiStep = [];

    if (this.state.direction === "Up") 
    {
      for (var j=0; j<this.state.kpiStep.length; j++) {
        var newkpistep = {};
        newkpistep.KpiStepId = this.state.kpiStep[j].kpiStepId;
        newkpistep.KpiId = this.state.kpiStep[j].kpiId;
        newkpistep.ManagerUserName = this.state.kpiStep[j].managerUserName;
        newkpistep.KpiName = this.state.kpiStep[j].kpiName;
        newkpistep.StepNumber = this.state.kpiStep[j].stepNumber;
        newkpistep.AchievePercentage = parseFloat(this.state.kpiStep[j].achievePercentage);
        newkpistep.PayoutPercentage = parseFloat(this.state.kpiStep[j].payoutPercentage);
        newkpistep.AchieveAmount = parseFloat(this.state.kpiStep[j].achieveAmount);
        newkpistep.PayoutAmount = parseFloat(this.state.kpiStep[j].payoutAmount);
        model.KpiStep.push(newkpistep);
      }
    }
    if (this.state.direction === "Down") 
    {
      model.KpiStep = this.correctHeaderAndTables();
    } 

    const Kpi = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var poststring = '/api/KpiStep';
    var connectionstringpost = connectionstring1.concat("" , poststring);
    /*connectionstringpost = process.env.REACT_APP_apiUrl + '/api/KpiStep'*/

    /*sendformisvalid = false;*/

    if (sendformisvalid) {

    trackPromise(
    axios.post(connectionstringpost, Kpi
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
         if (this.props.match.params.returnId !== "") 
          {
           const returnpage = '/' + this.props.match.params.returnPage + '/' + this.props.match.params.returnId;
           this.props.history.push(returnpage);
          } else {
            this.props.history.push('/' + this.props.match.params.returnPage);
          }
        
        }))
  };
  
}
  render () {

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <Layout>
      <LoadingSpinner/>
      <br />
      {this.state.returnpage === 'EditOneBonus' && <div><h3><Trans i18nKey="kpi-step.edit-step-header"/></h3><br/></div> }
      {this.state.returnpage === 'EditKpiTables' && <div><h3><Trans i18nKey="kpi-step.edit-default-step-header"/></h3><br/></div> }
      <Form onSubmit={this.handleSubmit}>
      <h4>{this.state.name}</h4>
      {(this.state.direction === "Up") 
      ? <div>
          <Trans i18nKey="kpi.bonus-table-type"/>: <Trans i18nKey="kpi.higher-result-more-bonus"/><br />
          <Trans i18nKey="kpi.goal"/>: {this.state.goal}
      </div>
      : <div>
        <Trans i18nKey="kpi.bonus-table-type"/>: <Trans i18nKey="kpi.lower-result-more-bonus"/><br /><br />
        <Table borderless>
          <thead>
          <tr>
            <th><Trans i18nKey="kpi.low-cut-off"/></th>
            <th><Trans i18nKey="kpi.goal"/></th>
            <th><Trans i18nKey="kpi.high-cut-off"/></th>
            <th><Trans i18nKey="kpi.scale-under-goal"/></th>
            <th><Trans i18nKey="kpi.scale-over-goal"/></th>
          </tr>
          </thead>
        <tbody>
          <tr>
            <td>
                <Form.Control type="text" name="lowCutOff" placeholder={i18n.t('kpi.low-cut-off')} className="mr10" value={this.state.lowCutOff} onChange={e => this.handleKpiChange(e)} onKeyPress={this.handleKeyPress}></Form.Control>
                {this.state.errorLowCutOff !== "" && <div className="validationerror"> {this.state.errorLowCutOff}</div>}
            </td>
            <td>
                <Form.Control type="text" name="goal" placeholder={i18n.t('kpi.goal')} className="mr10" value={this.state.goal} onChange={e => this.handleKpiChange(e)} onKeyPress={this.handleKeyPress}></Form.Control>
                {this.state.errorGoal !== "" && <div className="validationerror"> {this.state.errorGoal}</div>}
            </td>
            <td>
                <Form.Control type="text" name="highCutOff" placeholder={i18n.t('kpi.high-cut-off')} className="mr10" value={this.state.highCutOff} onChange={e => this.handleKpiChange(e)} onKeyPress={this.handleKeyPress}></Form.Control>
                {this.state.errorHighCutOff !== "" && <div className="validationerror"> {this.state.errorHighCutOff}</div>}
            </td>
            <td>
                <Form.Group ><Form.Control as="select" type="text" name="scaleUnderGoal" placeholder="direction" className="mr10" value={i18n.t("kpi." + this.state.scaleUnderGoal)} onChange={e => this.handleKpiChange(e)}>
                  <option>{i18n.t('kpi.scale-2-1')}</option>
                  <option>{i18n.t('kpi.scale-1-1')}</option>
                  <option>{i18n.t('kpi.scale-1-2')}</option>
                </Form.Control></Form.Group>
              {this.state.errorScaleUnderGoal !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorScaleUnderGoal}</div></Col></Row></Form.Group> } 
            </td>
            <td>
                <Form.Group ><Form.Control as="select" type="text" name="scaleOverGoal" placeholder="direction" className="mr10" value={i18n.t("kpi." + this.state.scaleOverGoal)} onChange={e => this.handleKpiChange(e)}>
                  <option>{i18n.t('kpi.scale-2-1')}</option>
                  <option>{i18n.t('kpi.scale-1-1')}</option>
                  <option>{i18n.t('kpi.scale-1-2')}</option>
                </Form.Control></Form.Group>
              {this.state.errorScaleOverGoal !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorScaleOverGoal}</div></Col></Row></Form.Group> } 
            </td>
            <td><input type="button" value={i18n.t('navigation.update')} className="mr10 float-left" onClick={this.handleUpdate} /></td>
          </tr>
        </tbody>
        </Table>
        
        </div>
        }
      <br />

<Table borderless>
           <thead>
              <tr>
                <th><Trans i18nKey="kpi-step.percentage-to-achieve"/></th>
                <th><Trans i18nKey="kpi-step.level-achieved"/></th>
                <th><Trans i18nKey="kpi-step.unit"/></th>
                <th><Trans i18nKey="kpi-step.percentage-payout"/></th>
                <th><Trans i18nKey="kpi-step.payout"/></th>
                <th><Trans i18nKey="kpi-step.currency"/></th>
                <th></th>
              </tr>
              </thead> 
              <tbody> 
          {this.state.kpiStep.map((item,i) => {
          return(  
              <tr key={i} className={this.state.class120highlight}>
                <td>
                  {this.state.direction === "Up" ?
                  <div><Form.Control type="text" name="achievePercentage" placeholder={i18n.t('kpi-step.percentage-to-achieve')} className="mr10" value={this.state.kpiStep[i].achievePercentage} onChange={e => this.handleKpiStepChange(e, i)}></Form.Control>
                  {this.state.kpiStep[i].errorAchievePercentage !== "" && <div className="validationerror"> {this.state.kpiStep[i].errorAchievePercentage}</div>}
                  </div>
                  : <div>{this.state.kpiStep[i].achievePercentage}</div>
                  }
                  </td>
                <td>
                {this.state.direction === "Up" ?
                <div> {this.state.kpiStep[i].achieveAmount}</div>
                : <div>
                 <Form.Control type="text" name="achieveAmount" placeholder={i18n.t('kpi-step.level-achieved')} className="mr10" value={this.state.kpiStep[i].achieveAmount} onChange={e => this.handleKpiStepChange(e, i)}></Form.Control>
                  {this.state.kpiStep[i].errorAchieveAmount !== "" && <div className="validationerror"> {this.state.kpiStep[i].errorAchieveAmount}</div>}
                 </div>
                }
                </td>
                <td>
                {this.state.unit}
                </td>
                <td>
                  {this.state.direction === "Up" ?
                  <div>
                    <Form.Control type="text" name="payoutPercentage" placeholder={i18n.t('kpi-step.percentage-payout')} className="mr10" value={this.state.kpiStep[i].payoutPercentage} onChange={e => this.handleKpiStepChange(e, i)}></Form.Control>
                    {this.state.kpiStep[i].errorPayoutPercentage !== "" && <div className="validationerror"> {this.state.kpiStep[i].errorPayoutPercentage}</div>}
                  </div> 
                  :
                  <div>
                      {this.state.kpiStep[i].payoutPercentage}
                  </div>
                  }
                </td>
                <td>
                  {this.state.kpiStep[i].payoutAmount}
                </td>
                <td>{this.state.currency}</td>
                <td>
                 <Button className="mr-1 my-1 float-right"  variant="success" onClick={this.handleAddKpiStep}><FontAwesomeIcon icon={faPlus} /></Button>

                  {this.state.kpiStep.length !== 1 && 
                  <Button className="mr-1 my-1 float-right"  variant="danger" onClick={() => this.handleRemoveKpiStep(i)}><FontAwesomeIcon icon={faMinus} /></Button>}
 
                  </td>
              </tr>
            )})}

          </tbody>
          </Table>
                  
      <LoadingSpinner/>
      <br />

      <Button className="mr-1 my-1 float-right"  variant="success" onClick={this.handleAddKpiStep}><FontAwesomeIcon icon={faPlus} /></Button>< br />< br />
      <Button variant="primary" type="submit" className="float-right" onSubmit={this.handleSubmit}><Trans i18nKey="navigation.save"/></Button>
      </Form>
      <br/><br/>
    </Layout>
  );
};
};

export default EditKpiTableSteps;