import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Layout } from './components/Layout';
import { Redirect } from "react-router-dom";
import { authenticationService } from './_services/authentication.service';

class AdminHome extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        users: null,
        role: JSON.parse(localStorage.getItem('currentUser')).role, 
        redirect: false
      }
    }

    componentDidMount() {
      if (this.state.role !== "Admin") { 
        console.log("We should log out");
        authenticationService.logout();
        this.setState({redirect: true })
        
      };
      console.log(this.state.role);
  }

  render () {

    if (this.state.redirect) {
      return <Redirect to={'/'} />
    }

  return (
  <div>
    <Layout>
        <div>
        <br/>
        <h1>Admin Home</h1> <br />
        <h3>User admin</h3>
        <Link to="/admindeleteuser"><Button  className="mr-1 my-1" variant="primary">Delete User</Button></Link> 
        <Link to="/adminreset"><Button  className="mr-1 my-1" variant="primary">Reset User Password</Button></Link><br />
        <br />
        <h3>Bonus Agreement Admin</h3>
        <Link to="/adminbonusagreements"><Button  className="mr-1 my-1" variant="primary">All bonus ageements</Button></Link><br /><br />
        
        <h3>Site Admin</h3>
        Site: {process.env.REACT_APP_apiUrl}<br />       
        Logged in user role: {this.state.role}<br />
        <i>Note</i> This page logs unauthorized users out. 

        </div>
    </Layout>
  </div>
  )

}
}

export default AdminHome;



