import React from "react";
import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";


const LoadingSpinner = props => {
    const { promiseInProgress } = usePromiseTracker();
  
    return promiseInProgress && 
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots color="#2BAD60" height="100" width="100" />
     </div>
  };

  export default LoadingSpinner;