import React from 'react';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Trans } from 'react-i18next';
import i18n from './i18n';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import StopSign from './StopSign';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faFileSignature, faPoll, faUndo, faTrashAlt, faFileExport, faCopy } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';


class BonusAgreements extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    token: JSON.parse(localStorage.getItem('currentUser')).token,
    managerUserName: JSON.parse(localStorage.getItem('currentUser')).userName,
    userId: JSON.parse(localStorage.getItem('currentUser')).id,
    bonusagreements: [],
    isLoading: true,
    errors: null
  };

copy = (e) => {

    const model = {};
    model.bonusAgreementId = parseInt(e);
    model.userId = this.state.userId;

    const BonusAgreementCopy = model;

    var connectionstring = process.env.REACT_APP_apiUrl + '/api/bonuskpiapproval/copybonusagreement';


    trackPromise(  
    axios.post(connectionstring, BonusAgreementCopy,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          /*this.props.history.push('/bonusagreements');*/
          window.location.reload(false);
        })
    ).catch(error => console.log(error));

} 


stopSign = (e) => {
    StopSign({bonusAgreementId: e});

} 
  // Now we're going to make a request for data using axios
  getBonusAgreements() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpi';
    connectionstring2 = '/api/bonusagreements'
    var connectionstring = connectionstring1.concat("" , connectionstring2);
    connectionstring = connectionstring + "/" + this.state.token + "/" + this.state.managerUserName;

    trackPromise(
    axios
      .get(connectionstring,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
      .then(response => {
        this.setState({
          bonusagreements: response.data,
          isLoading: false
        });
        console.log(response.data);
        
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getBonusAgreements();
  }
  // Putting that data to use
  render() {
    const { isLoading, bonusagreements } = this.state;
    return (
      <React.Fragment>
        <Layout>
        <br /> 
        <h2><Trans i18nKey="menu.bonus-list"/></h2>
        <LoadingSpinner/>

        {(this.state.bonusagreements.length === 0 && !isLoading )
        ? 
        <div><br /><CardDeck>
        <Card style={{ width: '18rem' }} bg='light'>
          <Card.Body>
            <Card.Title><Trans i18nKey="home.new-bonus-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="home.new-bonus-text"/>
            </Card.Text>  
            <Link to="/editonebonus/new"><Button variant="primary"><Trans i18nKey="home.new-bonus-button"/></Button></Link>
          </Card.Body>
        </Card>
       </CardDeck>
        <br /></div>
        : <div></div>}

        <br />
        <div key={uuidv4()}>
          {!isLoading ? (
            bonusagreements.map(bonusagreement => {
              return (<div  key={uuidv4()}>{bonusagreement.usedFor === "User" &&
                <div className="card" border="danger">
                  <div className="card-body">
                     <h4 className="card-title">{bonusagreement.employeeFullName} - {bonusagreement.name}</h4> 
                    <h6 className="card-subtitle mb-2 text-muted">{i18n.t("phase." + bonusagreement.phase)}</h6>

                      {(bonusagreement.phase === "Draft" || bonusagreement.phase === "Sign") && <Link to={`deletebonusagreement/${bonusagreement.bonusAgreementId}`}><Button variant="danger" className="mr-1 my-1"><FontAwesomeIcon icon={faTrashAlt} /> <Trans i18nKey="bonus.delete"/></Button></Link>}
                      {bonusagreement.phase === "Sign" && <span style={{'color': '#007bff', 'textDecoration': 'none', 'backgroundColor': 'transparent', 'cursor':'pointer'}} onClick={() => {this.stopSign(bonusagreement.bonusAgreementId)}}><Button variant="danger" className="mr-1 my-1" ><FontAwesomeIcon icon={faUndo} /><Trans i18nKey="approval.stop-sign"/></Button></span>} 

                      {(bonusagreement.phase === "Draft" || bonusagreement.phase === "Sign" || bonusagreement.phase === "Signed") && <Link to={`/listonebonus/${bonusagreement.bonusAgreementId}`}><Button variant="success" className="mr-1 my-1" ><FontAwesomeIcon icon={faEye} /> <Trans i18nKey="navigation.view"/></Button></Link>}
                      {(bonusagreement.phase === "Set Results" || bonusagreement.phase === "Sign Results" || bonusagreement.phase === "Signed Results") && <Link to={`/listonebonuswithresult/${bonusagreement.bonusAgreementId}`}><Button variant="success" className="mr-1  my-1" ><FontAwesomeIcon icon={faEye} /> <Trans i18nKey="navigation.view-results"/></Button></Link>}

                      {bonusagreement.phase === "Draft" && <Link to={`/editonebonus/${bonusagreement.bonusAgreementId}`}><Button  variant="success"  className="mr-1  my-1"><FontAwesomeIcon icon={faEdit} /> <Trans i18nKey="navigation.edit"/></Button></Link>}

                      {/*   */} 
                      <span style={{'color': '#007bff', 'textDecoration': 'none', 'backgroundColor': 'transparent', 'cursor':'pointer'}} onClick={() => {this.copy(bonusagreement.bonusAgreementId)}}><Button variant="success" className="mr-1 my-1" ><FontAwesomeIcon icon={faCopy} /> <Trans i18nKey="bonus.copy"/></Button></span>

                      <Link to={`/sharebonusagreement/${bonusagreement.bonusAgreementId}`}><Button  variant="success"  className="mr-1  my-1"><FontAwesomeIcon icon={faFileExport} /> <Trans i18nKey="navigation.share"/></Button></Link>

                      {bonusagreement.phase === "Draft" && <Link to={`sign/${bonusagreement.bonusAgreementId}`}><Button  className="mr-1  my-1"><FontAwesomeIcon icon={faFileSignature} /> <Trans i18nKey="approval.sign"/></Button></Link>} 
                      
                      {(bonusagreement.phase === "Signed" || bonusagreement.phase === "Set Results") && <Link to={`/setresultonebonus/${bonusagreement.bonusAgreementId}`}><Button className="mr-1  my-1"><FontAwesomeIcon icon={faPoll} /> <Trans i18nKey="bonus.set-results"/></Button></Link>}
                      {bonusagreement.phase === "Set Results" && <Link to={`/signresults/${bonusagreement.bonusAgreementId}`}><Button  className="mr-1  my-1"><FontAwesomeIcon icon={faFileSignature} /> <Trans i18nKey="approval.sign"/></Button></Link>}
                           {bonusagreement.phase === "Sign Results" && <span style={{'color': '#007bff', 'textDecoration': 'none', 'backgroundColor': 'transparent', 'cursor':'pointer'}} onClick={() => {this.stopSign(bonusagreement.bonusAgreementId)}}><Button className="mr-1 my-1" ><Trans i18nKey="approval.stop-sign-results"/></Button></span>} 

                       {/*    <Share bonusAgreementId={bonusagreement.bonusAgreementId} ></Share> */}

                      {/*<Col><input type="button" value="Delete" onClick={this.DeleteAgreement} /></Col>*/}

                    <br />
                  </div>  
                </div>}<br /></div>
              )
            })
          ) : (
            <p></p>
          )}
        </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default BonusAgreements;