import React, { Component } from "react";
import { Form, Button } from 'react-bootstrap';
import { Accordion, Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import videoFront from './assets/homevideoblue.mp4';
import videoAnimated from './assets/animatedvideo.mp4';
import posterVideoFront from './assets/posterhomevideo.png';
import posterAnimatedVideo from './assets/posteranimatedvideo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faCoffee, faCogs, faBookReader, faAngleDown  } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer_se.js';
import i18n from './i18n';
import LoadingSpinner from "./components/LoadingSpinner";

class SE extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false, 
        width: 0, 
        height: 0
      };

      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
  
    // Let's our app know we're ready to render the data
    componentDidMount() {
        i18n.changeLanguage('se');
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({
            videoFrontSrc: videoFront,
            videoAnimatedSrc: videoAnimated
        })
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }


navigateTo = event => 
  {  
    i18n.changeLanguage('se');
    this.props.history.push('/signup_se');
  }

  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
  <div>
            {this.state.width > 700 &&   
    <div class="showcase">
        {/* <video src={videoFront} muted loop autoPlay></video>*/}
    <video src={this.state.videoFrontSrc} poster={posterVideoFront}  muted loop playsInline autoPlay></video>

    <div class="overlay"></div>
    <div class="text">
      <h2>Digitala</h2> 
      <h3>bonusavtal</h3>
      <p>Gör bonusavtal för anställda snabbare, enklare och säkrare.  <br />
        Nå garanterat bonusmålen. </p>
      <a href="/signup_se">Testa gratis</a>
    </div>
  </div>}
  {this.state.width < 701 &&  
    <div  className="container">
        <br />
    <h1 className="text-center text-dark" style={{"fontSize": "40px", "fontWeight": "950", "textTransform": "upperCase"}}>Digitala </h1>
    <h2 className="text-center text-dark" style={{"fontSize": "40px", "fontWeight": "650", "textTransform": "upperCase"}}>Bonus<span style={{color: "#007BFF"}}>avtal</span></h2>
      <p className="text-center">Gör bonusavtal för anställda snabbare, enklare och säkrare. Nå garanterat bonusmålen.</p>
      <div  className="text-center"><a className="text-center" href="/signup">Testa GRATIS</a></div>
      <div style={{ height: '300px', width: '100%', position: 'relative'}}>
     <video src={this.state.videoFrontSrc} poster={posterVideoFront} style={{ height: '300px', width: '100%', top: '-2px', overflow: 'hidden', position: 'absolute', 'zIndex': '-2'}} muted loop playsInline autoPlay></video>
     </div>
  </div>}
  <div class="breaker"> </div>
  <Form>

        <div class="p-3 bg-dark text-white">
        <Container>
        <br />
          <h1><FontAwesomeIcon icon={faBookReader} /> Bakgrund</h1>
          Vi har sett bonusavtal i olika företag. 
          Oftast sätts bonusarna väldigt sent. Varför? För att säljcheferna inte har tillräcklig tid för att sätta bonusar.<br /><br />
          De anställda uppskattar det inte, för att de upplever att bonusarna tas från dem. 
          De anställda vet inte heller tydligt vilka mål det är som gäller. 
          Istället för att vara motiverade och inriktade på att nå målen, så sker det motsatta. 
          Företaget och chefen lider också, då de inte får de resultaten de vill ha. <br /><br />
          
          Lösningen är enkel. Använd <i>Manage Bonus</i> för att hantera dina bonusar. Det är en stor hjälp för dig som säljchef som sätter bonusarna.  
          Allt du behöver för att sätta bonusar för din personal kan du lösa med hjälp av <i>Manage Bonus</i>. 
        <br /><br />
        <div class="col text-center"><Button variant="primary btn-lg" onClick={() => {this.navigateTo()}}>Testa gratis</Button></div>
        <br /><br /><br /><br /><br />
        </Container>
        </div>


        <div class="container">
            <br /><br />
        <h1 class="text-center text-dark" style={{"font-size": "75px"}}>Vi <span style={{color: "#007BFF"}}>erbjuder</span></h1>
        <hr class="divider my-3" />
        <div class="row">
            <div class="col-lg-4 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-child text-primary mb-4"></i>
                    <h2 class="h2 mb-2"><FontAwesomeIcon icon={faCoffee} /> Skippa administrationen</h2>
                    <p class="text-muted mb-0">Ingen tid? ... Inga problem! Det går extremt snabbt att skapa ett bonusavtal</p><br />
                    <p class="text-muted mb-0">Du behöver inte lägga lång timmar i excel och word</p><br />
                    <p class="text-muted mb-0">Fokusera på bonusmålen istället</p><br />
          <br />
          <br />
                </div>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fas fa-music text-primary mb-4"></i>
                    <h2 class="h2 mb-2"><FontAwesomeIcon icon={faUserCheck} /> Rätt bonus till rätt person</h2>
                    <p class="text-muted mb-0">Bara den anställde kommer att se och godkänna sin bonus. Ingen annan.</p><br />
                    <p class="text-muted mb-0">Du behöver inte känna stressen över att sända e-post till fel person</p><br />
                    <p class="text-muted mb-0">Slå in e-post adressen en gång. Sedan fixar appen resten</p><br />
                </div>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-microphone text-primary mb-4"></i>
                    <h2 class="h2 mb-2"><FontAwesomeIcon icon={faCogs} /> Snabbt, enkelt och flexibelt</h2>
                    <p class="text-muted mb-0">Genom ett par klick är bonusavtalet klart</p><br />
                    <p class="text-muted mb-0">Vill du ändra? Det är jätteenkelt</p><br />
                    <p class="text-muted mb-0">Justera fritt nivåer, utbetalningar, mål, gränser ... Allt vad du vill kan justeras</p><br />
                </div>
            </div>
        </div>
        <br /><br />
    </div>

    <Container>
            <video id='videoIntro' src={videoAnimated} poster={posterAnimatedVideo} controls playsInline style={{width: '100%', top: '-2px;', overflow: 'hidden'}}></video>
        </Container>
        <br /><br />

        <div class="p-3 bg-white text-dark">
        <Container>
          <h2  className="text-primary">FAQ</h2>
          <Accordion>
          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Hur är ett bonusavtal uppbyggt?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    Varje bonusavtal består av några KPI. 
                                    I de flesta fallen har du 2-4 KPIer. Och, du kan ha precis så många du behöver. 
                                    Varje KPI är uppbyggt av resultatnivåer som behöver bli uppfyllda. 
                                    Ersättningen bestäms av vilket resultat som uppnås. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Supporterar ni vårt bonussystem? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    Det finns inget standard bonussystem, eftersom alla företag har deras egna system. 
                                    Appen är byggd med en hög grad av flexibilitet, så att du kan anpassa flera parametrar. 
                                    De flesta bonusavtalen är supporterade och är enkla att skapa. 
                                    Om du skulle ha problem att sätta upp ditt bonusavtal, fråga oss och så hittar vi ett sätt som funkar för dig. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> I vårt system ges inte bonus under en viss nivå. Stödjer ni det? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                Systemet stödjer en lägre och en högre cut-off nivå. Du kan sätta den nivån du behöver
                                   </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Hur kan den anställde underteckna bonusavtalet? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                Elektronisk signatur är inbyggd för bonusarna. Den anställde får en e-post när det är dags att underteckna. När alla berörda har undertecknat får alla en kopia av det slutgiltiga avtalet.  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Kan jag diskutera avtalet med den anställde innan det undertecknas? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                Absolut. Du kan dela bonusavtalet för översyn när som helst. Om du ändrar dig i efterhand kan du också dra tillbaka ett bonusavtal, dvs om det inte är undertecknat. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Vad händer när resultaten är klara? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                När resultaten är klara för du in dem i bonusavtalet. Då räknas bonusutbetalningarna ut. Du och den anställde behöver även underteckna detta. Efter signering får alla som undertecknat en kopia på det undertecknade bonusavtalet med resultaten. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Kommer mitt vaurmärke att synas på bonusavtalen? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                Ja - dvs om du vill. På bonusavtalen kan du sätta ditt företagsnamn, logo, adress osv. Det här kommer att finnas med på de undertecknade bonusavtalen som skickas ut. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Hur kan jag hantera flera anställda med liknande KPIer? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                Du kan göra förinställda KPIer, tex omsättning, marginal, antal kundbesök eller antal projekt. KPIerna kan sedan bli återanvända för de individuella bonusavtalen. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="8" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Vilka språk stöds? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="8">
                                <Card.Body>
                                Engelska och svenska stöds för närvarande. Bägge kan användas som användarspråk i appen samt på bonusavtalen. Om du behöver något annat språk, hör av dig. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="9" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Vad kostar det? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="9">
                                <Card.Body>
                                Alla nya användare har en 30 dagars gratis testa på period. Efter det, om du väljer att fortsätta kommer du att bli fakturerad i svenska kronor med svensk moms från Paseedu AB. 
                                Det finns olika prenumerationer som du kan välja från beroende på antal användare. <br /><br />
                                <a href="/pricing_se">Prenumerationer och priser</a> 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="10" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Vem är det här för?  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="10">
                                <Card.Body>
                                Den typiska användaren är en B2B chef eller säljchef som regelbundet sätter nya bonusar. Det kan vara årligen, kvartalsvis eller något liknande. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

          </Accordion>
        </Container>
        </div>
        <br />
        
  
        <Container>
        <div class="col text-center"><Button variant="primary  btn-lg" onClick={() => {this.navigateTo()}}>Testa gratis</Button></div>
       </Container>
        
        
        <br /><br />
        
        <Footer/>
       
        </Form>
  </div>
  );
};
};

export default SE;
