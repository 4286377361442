import { handleResponse } from '../_helpers/handle-response';
import { authenticationService } from './authentication.service';

export const signupService = {
    signup
};

function signup(firstname, lastname, email, phonenumber, companyname, title, country, password, language) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstname, lastname, email, phonenumber, companyname, title, country, password, language })
    };

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/identity/users/registerfulluser';
    const connectionstring = connectionstring1.concat("" , connectionstring2);

    console.log(connectionstring);
    console.log(requestOptions);

    return fetch(connectionstring, requestOptions)
        .then(handleResponse)
        .then(user => {
            authenticationService.loginaftersignup(user);
            return user;
        }).catch(function(error) {
            return Promise.reject();
        })
        ;
}

