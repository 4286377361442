import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Trans } from 'react-i18next';
import i18n from './i18n';
import { Redirect } from "react-router-dom";


class DeleteBonusAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      userId: JSON.parse(localStorage.getItem('currentUser')).id,
      userName: JSON.parse(localStorage.getItem('currentUser')).userName,
      redirect: false,
      bonusAgreementId: "", 
      isLoading: true 
     };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getBonusAgreement();

  };

  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpiapproval/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          token: this.state.token,
          new: false,
          finishedloading: true, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          employeeApprovalLevel: response.data.employeeApprovalLevel,
          managerApprovalLevel: response.data.managerApprovalLevel,
          phase: response.data.phase,
          approval: response.data.approval,
          kpi: response.data.kpi,
          isLoading: false
        });
        if (response.data.managerUserName !== this.state.userName)
        { this.setState({redirect: true }) }

      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };


  handleSubmit = event => {
    event.preventDefault();

    const BonusAgreementDelete = {};
    BonusAgreementDelete.BonusAgreementId = parseInt(this.props.match.params.bonusAgreementId);


    var connectionstringdelete = process.env.REACT_APP_apiUrl + '/api/bonuskpiapproval';

    trackPromise(  
    axios.delete(connectionstringdelete, 
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }, 
        data: {BonusAgreementId: this.state.bonusAgreementId, UserId: this.state.userId}}
      )
        .then(res => {
          this.props.history.push('/bonusagreements');
        })
    )


  };
  
  render () {

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="DeleteBonusAgreement"><LoadingSpinner/></div>;
    }

    if (this.state.redirect) {
      return <Redirect to={'/Homeloggedin'} />
    }

  return (
    <Layout>
      <br />
      <h3><Trans i18nKey="delete.header"/></h3><br/>
      <Form onSubmit={this.handleSubmit}>

      <Trans i18nKey="delete.sure?"/> {this.state.name} {!this.state.employeeFullName ? '' : " " + i18n.t('delete.with') + " " + this.state.employeeFullName}? <br /><br />
      <Button variant="primary" type="submit" className="float-left"><Trans i18nKey="delete.delete"/></Button>
      <br /><br />
      </Form>
      <LoadingSpinner/>
    </Layout>
  );
};
};

export default DeleteBonusAgreement;