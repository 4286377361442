import React,{ Component } from 'react';
import { Table } from 'react-bootstrap';
import './bonustable.css';


class BonusTableSteps extends Component {
  constructor(props) {
    super(props);
  this.state = {

  }
}


componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.language === 'se') { 
      this.setState(state => { 
        return {
          columnheader_percentageachieved: '% Uppnådd',
          columnheader_levelachieved: 'Uppnådd nivå', 
          columnheader_unit: 'Enhet',
          columnheader_payout: 'Ersättning',
          columnheader_currency: 'Valuta'
       }})
     }
    else { 
      this.setState(state => { 
        return {
          columnheader_percentageachieved: '% Achieved',
          columnheader_levelachieved: 'Level Achieved', 
          columnheader_unit: 'Unit',
          columnheader_payout: 'Payout',
          columnheader_currency: 'Currency'
       }})

     }

}





render () {
    return (
      <div className="UserInfo">
        <Table striped bordered hover>
           <thead>
              <tr>
                <th>{this.state.columnheader_percentageachieved}</th>
                <th>{this.state.columnheader_levelachieved}</th>
                <th>{this.state.columnheader_unit}</th>
                <th>{this.state.columnheader_payout}</th>
                <th>{this.state.columnheader_currency}</th>
              </tr>
            </thead>  
            <tbody>
            {this.props.kpistep.map((item,i) => {
              return(
                <tr key={i} className={this.props.resultAmount === this.props.kpistep[i].payoutAmount ? 'highlight' : undefined}>
                <td>{this.props.kpistep[i].achievePercentage}%</td>
                <td>{this.props.kpistep[i].achieveAmount}</td>
                <td>{this.props.unit}</td>
                <td>{this.props.kpistep[i].payoutAmount}</td>
                <td>{this.props.currency}</td>
                </tr>
              )
            }
      
      )}
            </tbody>
        </Table>
      </div>
    )
  }
};

export default BonusTableSteps;