import React, { Component } from "react";
import { Form, Button } from 'react-bootstrap';
import { Accordion, Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Footer from './Footer.js';
import videoFront from './assets/homevideoblue.mp4';
import videoAnimated from './assets/animatedvideo.mp4';
import posterVideoFront from './assets/posterhomevideo.png';
import posterAnimatedVideo from './assets/posteranimatedvideo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faCoffee, faCogs, faBookReader, faAngleDown   } from '@fortawesome/free-solid-svg-icons';
import i18n from './i18n';
import LoadingSpinner from "./components/LoadingSpinner";

class Home extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false, 
        width: 0, 
        height: 0, 
        videoFrontSrc: '',
        videoAnimatedSrc: ''
      };

      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
  
    // Let's our app know we're ready to render the data
    componentDidMount() {
        i18n.changeLanguage('en');
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({
            videoFrontSrc: videoFront,
            videoAnimatedSrc: videoAnimated
        })
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

navigateTo = event => 
  {  
    i18n.changeLanguage('en');
    this.props.history.push('/signup');
  }

  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
  <div>
     {this.state.width > 700 &&   
    <div className="showcase">
        {/* <video src={videoFront} muted loop playsInLine autoPlay></video>*/}
    <video src={this.state.videoFrontSrc} poster={posterVideoFront} muted loop playsInline autoPlay></video>
    <div className="overlay"></div>
    <div className="text">
      <h2>Digital</h2> 
      <h3>Bonus Agreements</h3>
      <p>Make Bonus Agreements with employees faster, easier and safer. Increase hit rate of reaching company goals</p>
      <a href="/signup">Try for free</a>
    </div>
  </div>}
    {this.state.width < 701 &&  
    <div  className="container">
        <br />
    <h1 className="text-center text-dark" style={{"fontSize": "40px", "fontWeight": "900", "textTransform": "upperCase"}}>Digital </h1>
    <h2 className="text-center text-dark" style={{"fontSize": "40px", "fontWeight": "650", "textTransform": "upperCase"}}>Bonus <span style={{color: "#007BFF"}}>Agreements</span></h2>
      <p className="text-center">Make Bonus Agreements faster, easier and safer. Increase hit rate of reaching company goals</p>
      <div  className="text-center"><a className="text-center" href="/signup" style={{"textTransform": "upperCase"}}>Try for free</a></div>
      <div style={{ height: '300px', width: '100%', position: 'relative'}}>
     <video src={this.state.videoFrontSrc} poster={posterVideoFront} style={{ height: '300px', width: '100%', top: '-2px', overflow: 'hidden', position: 'absolute', 'zIndex': '-2'}} muted loop playsInline autoPlay></video>
     </div>
  </div>}
  <Form>

        <div className="p-3 bg-dark text-white">
        <Container>
        <br />
          <h1><FontAwesomeIcon icon={faBookReader} /> Our Story</h1>
          We have seen bonus agreements in various companies. 
          Typically, the bonuses are set too late. Why? Because the sales manager does not have sufficient time for the bonus setting process. <br /><br />
          The employees don't like this, because they feel that the bonus is robbed from them. Also, the employees don't know so clearly what the goals are. Therefore, instead of being motivated and focusing on reaching their goals, the opposite is happening. 
          The company and the manager also suffer, as they do not get the results they strive for<br /><br />
          The solution is easy - just use Manage Bonus to handle your bonuses. Its a huge help for the sales manager who sets the bonuses. Everything you need to set the bonus for your staff is solved with Manage Bonus. 
        <br /><br />
        <div className="col text-center"><Button variant="primary  btn-lg" onClick={() => {this.navigateTo()}}>Try for FREE</Button></div>
        <br /><br /><br /><br /><br />
        </Container>
        </div>


        <div className="container">
            <br /><br />
        <h1 className="text-center text-dark" style={{"fontSize": "75px"}}>We <span style={{color: "#007BFF"}}>Offer</span></h1>
        <hr className="divider my-3" />
        <div className="row">
            <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                    <i className="fas fa-4x fa-child text-primary mb-4"></i>
                    <h2 className="h2 mb-2"><FontAwesomeIcon icon={faCoffee} /> Skip the administration</h2>
                    <p className="text-muted mb-0">No time? ... No problem! Its razor fast to set up a bonus agreement</p><br />
                    <p className="text-muted mb-0">No need to spend long hours in excel and work</p><br />
                    <p className="text-muted mb-0">Focus on what targets you want</p><br />
          <br />
          <br />
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                    <i className="fas fa-4x fas fa-music text-primary mb-4"></i>
                    <h2 className="h2 mb-2"><FontAwesomeIcon icon={faUserCheck} /> Right bonus to the right person</h2>
                    <p className="text-muted mb-0">Only the right employee will see and confirm his bonus</p><br />
                    <p className="text-muted mb-0">No stress over sending bonus in email to the wrong person</p><br />
                    <p className="text-muted mb-0">Enter the email once, and the app takes care of the rest</p><br />
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
                <div className="mt-5">
                    <i className="fas fa-4x fa-microphone text-primary mb-4"></i>
                    <h2 className="h2 mb-2"><FontAwesomeIcon icon={faCogs} /> Fast, easy and flexible</h2>
                    <p className="text-muted mb-0">With a few clicks, you have your bonus agreement ready</p><br />
                    <p className="text-muted mb-0">Want to tweak? It's super easy</p><br />
                    <p className="text-muted mb-0">Adjust levels, payouts, targets ... you name it</p><br />
                </div>
            </div>
        </div>
        <br /><br />
    </div>


    <Container>
            <video id='videoIntro' src={this.state.videoAnimatedSrc}  poster={posterAnimatedVideo} controls playsInline style={{width: '100%', top: '-2px', overflow: 'hidden'}}></video>
        </Container>
        <br /><br />


        <div className="p-3 bg-white text-dark">
        <Container>
          <h2  className="text-primary">FAQ</h2>
          <Accordion>
          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> How is a bonus agreement built up?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>Each bonus agreement consists of a number of KPIs. 
                                  In most cases you only have 2-4 KPIs. However, you can have as many as you need. 
                                  Each KPI is built up of result goals that has to be achieved.
                                  Depending on the result you achieve, you get the bonus payout amount.  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/>  Can you support our bonus agreement?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  There is no standard for bonus agreements as each company has their own system.
                                  The app is built up with a lot of flexibility where you can tweak several parameters. 
                                  Most bonus agreement systems are supported and are easy to setup.
                                  Should you have a difficulty to setup your bonus agreement, then you ask for support and we will find a way for you.  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/>  We have cutoff level, when the results are low. Do you support that?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                The system supports low and high cut off levels. You can set whatever level that you need. 
                                   </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> How can my employee sign the agreement?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                There is a built in signing system for the bonuses. The employee will get the bonus agreement on email to sign. After signing, then all persons who signed will get a copy of the final agreement. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Can I discuss the agreement before signing?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                You can share the bonus agreement for review at any time. Also, if you change your mind, you can pull back a bonus agreement, which is not signed. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> What happens when the results are in? 
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                When the results are in, you enter them to the bonus agreement. The results are calculated as well as the payout amounts. You and the employee will need to sign the results as well. After signing, each person who signed will get a copy.  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Can I brand the Bonus Agreement to my company?  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                Yes. On the bonus agreements you can place your own company name, logo, address etc. In the signed agreements, which are sent out, you will have the branding there. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> I have several employees with similar bonuses. How to solve that?  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                You can store your own default bonus types, e.g. sales, gross margin, client visits, projects. The types can be reused for the individual bonus agreements. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="8"  style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> What languages are supported?  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="8">
                                <Card.Body>
                                English and Swedish are currently supported. Both can be used in the HMI language of the app and for the bonus agreements. If you need another language, please inform us.  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="9" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> What does it cost?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="9">
                                <Card.Body>
                                All new users have a 30 day try out period. After that, if you chose to continue, then you will be invoiced in SEK from the Swedish company Paseedu AB with Swedish VAT. There are various subscriptions that you can chose from depending on the number of users that you have. <br /><br />
                                <a href="/pricing">Subscriptions and Pricing</a> 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="10" style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faAngleDown} style={{color: "#007BFF"}}/> Who is this for?  
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="10">
                                <Card.Body>
                                The typical user is a B2B Manager or a Sales Manager of a team, which regularly needs new bonuses. It can be yearly, quarterly, monthly or something similar. 
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

          </Accordion>
        </Container>
        </div>
        <br />
        
  
        <Container>
        <div className="col text-center"><Button variant="primary  btn-lg" onClick={() => {this.navigateTo()}}>Try for FREE</Button></div>
       </Container>
        
        
        <br /><br />
 


        <Footer/>
     
        </Form>
  </div>
  );
};
};

export default Home;

