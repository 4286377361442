import React from 'react';
import { Layout } from './components/Layout';

export const NoMatch = () => (
        <Layout>
        <div>
          <h1>NoMatch</h1>
         <p> We could not find the page 404</p>
        </div>
        </Layout>
    )