import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import 'reactjs-popup/dist/index.css';
import "bootstrap/dist/css/bootstrap.css";


export function StopSign(props) {

      const token = JSON.parse(localStorage.getItem('currentUser')).token;

      const model = {};
      model.BonusAgreementId = props.bonusAgreementId; 

      const connectionstring = process.env.REACT_APP_apiUrl + '/api/ApproveSend/' + model.BonusAgreementId;

      if (true) {
        trackPromise(
          axios.put(connectionstring, 
            
          {
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${token}`,
            }
          })
            .then(res => {
              window.location.reload();
            }))
            return true;
          } else{
            window.location.reload();
      return false;
    }

}

export default StopSign;