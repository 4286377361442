import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Layout } from './components/Layout';
import i18n from './i18n';
import videoTakeOff from './assets/videotakeoff.mp4';
import posterVideoTakeOff from './assets/posterplane.png';

import { signupService } from './_services/signup.service';
import { authenticationService } from './_services/authentication.service';


// eslint-disable-next-line 
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

class SignUp_SE extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            companyName: '',
            title: '',
            country: '',
            password: '',
            videoTakeOffSrc: ''
        }

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) { 
            this.props.history.push('/homeloggedin');
        }
    }

    componentDidMount() {
        i18n.changeLanguage('se');
        if (process.env.NODE_ENV === 'development') {
            this.setState({
                firstName: 'testuser5',
                lastName: 'managebonus',
                email: 'testuser5@managebonus.com',
                companyName: 'Manage Bonus',
                password: 'KommaIn!1'
            })

        } else {
           
        }
        this.setState({
            videoTakeOffSrc: videoTakeOff
        })
        
    }
    
    render() {
        return (
            <div className="showcase">
                <Layout>
                <video src={this.state.videoTakeOffSrc} poster={posterVideoTakeOff} muted playsInline autoPlay></video>
                <div className="text" style={{color: "white"}}>
                <br/>
                <h2>Testa gratis i 30 dagar</h2>
                Inget kreditkort behövs. 
                <br/><br/>
                <Formik
                    initialValues={{
                        firstname: this.state.firstName,
                        lastname: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone,
                        companyname: this.state.companyName,
                        title: this.state.title,
                        country: this.state.country,
                        password: this.state.password
                    }}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required('Namn saknas'),
                        lastname: Yup.string().required('Efternamn saknas'),
                        email: Yup.string().email().required('E-posten behöver vara korrekt'),
                        companyname: Yup.string().required('Företagsnamn saknas'),
                        password: Yup.string().required('Lösenordet behöver vara minst 6 tecken långt. Det behöver ha minst ett specialtecken, en siffra, samt en stor och en liten bokstav'),
                     })}
                     enableReinitialize
                    onSubmit={({ firstname, lastname, email, phone, companyname, title, country, password }, { setStatus, setSubmitting }) => {
                        setStatus();
                        signupService.signup(firstname, lastname, email, phone, companyname, title, country, password, 'se')
                        .then(
                            user => {

                                const { from } = this.props.location.state || { from: { pathname: "/signupcomplete" } };
                                this.props.history.push(from);
                            },
                            error => {
                                setSubmitting(false);
                                setStatus("Error in account creation");
                                console.log(error);
                            }
                        );
                    }}>
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="firstname">Namn</label>
                                <Field name="firstname" type="text" className={'form-control' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstname" component="div" className="invalid-feedback"   style={{color: "white"}}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">Efternamn</label>
                                <Field name="lastname" type="text" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastname" component="div" className="invalid-feedback"   style={{color: "white"}}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">E-post</label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback"   style={{color: "white"}}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyname">Företagsnamn</label>
                                <Field name="companyname" type="text" className={'form-control' + (errors.companyname && touched.companyname ? ' is-invalid' : '')} />
                                <ErrorMessage name="companyname" component="div" className="invalid-feedback"   style={{color: "white"}}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Lösenord</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback"   style={{color: "white"}}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Skicka</button>
                                {isSubmitting &&
                                    <img alt="submitting" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                            {status &&
                                <div className={'alert alert-danger'}>{status}</div>
                            }
                        </Form>
                    )}
                </Formik>
                </div></Layout>
            </div>
        )
    }
}

export { SignUp_SE }; 