import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { authenticationService } from '../_services/authentication.service';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import englishflag from '../assets/englishflag.jpg';
import swedishflag from '../assets/swedishflag.jpg';
import bonuslogo from '../assets/bonuslogo.png';


const Styles = styled.div`
  .navbar {
    background-color: #222;
  }

  a, .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;

    &:hover {
      color: white;
    }
  }
`;

class NavigationBar extends Component {    
  constructor(props) {
  super(props);

  this.state = {
      currentUser: null,
      isAdmin: false, 
      redirect: false, 
      tryforfree: "Try for FREE",
      login: "Log in", 
      pricing: "Pricing",
      href_tryforfree: "/signup",
      href_login: "/login",
      href_pricing: "/pricing",
      selectedflag: englishflag, 
      showmenu: true,
      refreshdummy: null, 
      counter: 0
  };
}


componentDidMount() {
  authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x,
      isAdmin: x && x.role === "Admin",
      redirect: false
  }));

  if ((window.location.pathname === '/SE') ||(window.location.pathname === '/signup_se') || (window.location.pathname === '/login_se')  || (window.location.pathname === '/pricing_se')  ) {       
    this.setState({      
        tryforfree: "Testa GRATIS",
        login: "Logga in",
        pricing: "Priser",
        href_tryforfree: "/signup_se",
        href_login: "/login_se",
        href_pricing: "/pricing_se",
        selectedflag: swedishflag
       });
  };

  
  if ((window.location.pathname === '/issigned') || (window.location.pathname.includes('fromemail'))) 
  {       
    this.setState({  showmenu: false });
  } else {
    this.setState({  showmenu: true  });
  };

  if ((window.location.pathname === '/settings')) {
    var counternew; 
    counternew = parseInt(this.state.counter) + 1;
    this.setState({  counter: counternew  });
    console.log("Counter: " + counternew);
  }

}


logout() {
  authenticationService.logout();
  this.setState({ redirect: true })
}

render () {
   if ( this.state.redirect ) {
    this.setState({ redirect: false })
    return <Redirect push to="/" />;
   }



return (
  <Styles>
    
    <Navbar expand="lg">
        {!this.state.currentUser && <Navbar.Brand href="/"><img className="pull-left" style={{height: "52px", padding: "0px 0px 0px 0px", position: 'absolute', top: 0, left: 20 }} src={bonuslogo} alt="Language" /></Navbar.Brand>}
        {this.state.currentUser && <Navbar.Brand  href="/homeloggedin"><img className="pull-left" style={{height: "52px", padding: "0px 0px 0px 0px", position: 'absolute', top: 0, left: 20}} src={bonuslogo} alt="Language" /></Navbar.Brand>}
        {!this.state.showmenu && <div style={{height: "40px"}}> </div>}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark" />
        <Navbar.Collapse id="basic-navbar-nav">
          {this.state.showmenu && <Nav className="ml-auto">
            {this.state.currentUser && <Nav.Item><Nav.Link href="/editonebonus/new"><Trans i18nKey="menu.bonus-new"/></Nav.Link></Nav.Item>}
            {this.state.currentUser && <Nav.Item><Nav.Link href="/bonusagreements"><Trans i18nKey="menu.bonus-list"/></Nav.Link></Nav.Item>}
            {this.state.isAdmin && <Nav.Item><Nav.Link href="/adminhome">Admin</Nav.Link></Nav.Item>}
            {this.state.currentUser && <Nav.Item><Nav.Link href="/settings"><Trans i18nKey="menu.settings"/></Nav.Link></Nav.Item>}
            {this.state.currentUser && <Nav.Item><Nav.Link href="/help"><Trans i18nKey="menu.help"/></Nav.Link></Nav.Item>}
            {this.state.currentUser && <Nav.Item><Nav.Link onClick={this.logout.bind(this)} className="nav-item nav-link"><Trans i18nKey="navigation.logout"/></Nav.Link></Nav.Item>}
            {!this.state.currentUser && <Nav.Item><Nav.Link href={this.state.href_tryforfree}>{this.state.tryforfree}</Nav.Link></Nav.Item>}
            {!this.state.currentUser && <Nav.Item><Nav.Link href={this.state.href_pricing}>{this.state.pricing}</Nav.Link></Nav.Item>}
            {!this.state.currentUser && <Nav.Item><Nav.Link href={this.state.href_login}>{this.state.login}</Nav.Link></Nav.Item>}
            {!this.state.currentUser && <NavDropdown title={<img className="thumbnail-language pull-left" src={this.state.selectedflag} alt="Language" />} id="language-nav-dropdown">
                <NavDropdown.Item href=".." color="black"><div className="pull-left"><img className="thumbnail-language pull-left" src={englishflag} alt="Language" /></div></NavDropdown.Item>
                <NavDropdown.Item href="/SE"><div className="pull-left"><img className="thumbnail-language pull-left" src={swedishflag} alt="Language" /></div></NavDropdown.Item>
                </NavDropdown>
            }
           </Nav>} 
        </Navbar.Collapse>
      </Navbar>
  </Styles >
)}
}

export default NavigationBar
