/*import { authenticationService } from '../_services/authentication.service';*/

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                /*authenticationService.logout();*/
                /* console.log('401,403 error') */
                /*window.location.reload(true);*/
            }
            /* console.log('Issue with fetch handle response in authentication or registration') */
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
            
        }
        return data;
    });
}