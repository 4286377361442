import React, { Component } from "react";
import { Layout } from './components/Layout'


class IsSigned extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: 'Congratulations', 
      hassigned: 'You have signed the Bonus Agreement',
      mayclose: 'You may close this page'
    }
  }

  componentDidMount() {
  if (this.props.match.params.language === 'se') {
    this.setState({ 
      header: 'Grattis', 
      hassigned: 'Du har undertecknat bonusavtalet',
      mayclose: 'Du kan stänga den här sidan'
    })
    console.log(this.props.match.params.language);

  }};
  render () {

  return (
  <div>
    <Layout>
        <div>
        <br />
         <h1>{this.state.header}</h1> <br />
         {this.state.hassigned}.<br /><br />
         <i>{this.state.mayclose}</i>
        </div>
    </Layout>
  </div>
  )
}};

export default IsSigned;


