import React from 'react';
import axios from 'axios';
import { Layout } from './components/Layout';
import BonusTableSteps from './components/bonustablesteps';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';

class ViewSignedResultsAgreement extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    email: "",
    emailCode: "", 
    bonusAgreementId: "",
    name: "",
    onTargetReward: null,
    resultAmount: null,
    period: "",
    employeeUserName: "",
    managerUserName: "",
    employeeFullName: "",
    managerFullName: "",
    phase: "",
    kpi: undefined,
    approval: undefined,
    isLoading: true,
    errors: null
  }
}


  // Now we're going to make a request for data using axios
   getBonusAgreement() {
    const connectionstringemail = process.env.REACT_APP_apiUrl + '/api/email/' + this.props.match.params.EmailCode + '/' + this.props.match.params.Email;
    console.log(connectionstringemail);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstringemail)
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          email: this.state.email,
          emailCode: this.state.emailCode, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          phase: response.data.phase,
          currency: response.data.currency,
          language: response.data.language,
          translation: response.data.translationStringList,
          kpi: response.data.kpi,
          approval: response.data.approval,
          isLoading: false
        });
        console.log(response.data);
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBonusAgreement();
  };

  // Putting that data to use
  render() {

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="ViewSignedResultsAgreement"><LoadingSpinner/></div>;
    }


    return (
      <React.Fragment>
        <Layout>

        <br />
        <h2>{this.state.translation.string042}</h2>
        <br />
        <LoadingSpinner/>
        {this.state.translation.string018} {this.state.employeeFullName}<br />
        {this.state.translation.string022}: <b>{this.state.resultAmount} {this.state.currency}</b><br />
        {this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}

          <br />   <br />           


        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        {kpi.description} <br />
                        {this.state.translation.string003} {kpi.percentageOfTotalReward}% <br />
                        {this.state.translation.string001} {kpi.onTargetReward} {this.state.currency} <br />
                        {this.state.translation.string019} {kpi.result} {kpi.unit} {this.state.translation.string020} <b>{kpi.resultAmount} {this.state.currency} </b> <br /><br />
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} resultAmount={kpi.resultAmount} language={this.state.language}></BonusTableSteps>
                         <br />
                        </div>
                        
                        )
                    })}

        <br />

        <h2>{this.state.translation.string026}</h2>
        <h4>{this.state.translation.string027}</h4><br />

        {this.state.approval.map((approval,j) => {
                      return (
                        <div key={j} >
                        <h5>{approval.fullName}</h5>
                        {approval.approvalStatus === 'Signed' 
                            ? <div>
                                {approval.phase === 'Signed' ? this.state.translation.string016 : this.state.translation.string026}
                                <br /><br />
                                <Table >
                                <tr>
                                    <td>{this.state.translation.string030}: {approval.email}</td>
                                    <td>{this.state.translation.string031}: {approval.dateAnswered}</td>
                                    <td>{this.state.translation.string032}: {approval.timeAnswered} </td>
                                    <td>{this.state.translation.string037}: {approval.ipAddress}</td>
                                </tr>
                                <tr>
                                    <td>{this.state.translation.string033}: {approval.city}</td>
                                    <td>{this.state.translation.string034}: {approval.country}</td>      
                                    <td>{this.state.translation.string035}: {approval.longitude}</td>
                                    <td>{this.state.translation.string036}: {approval.latitude}</td>                            
                                </tr>
                                </Table>
                                </div>
                            : <div>
                                'Sent'
                                </div>}
                        <br /><br />
                        </div>
                        )
                    })
                    }
       

        <br /><br />
        {/*
      Email code: {this.props.match.params.EmailCode} <br />
      Email: {this.props.match.params.Email}
        */}
        
       </Layout>
      </React.Fragment>
    );
  }
}

export default ViewSignedResultsAgreement;