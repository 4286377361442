import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

/*  Google Tag Manager */ 
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-NP79X2H'
}
 
TagManager.initialize(tagManagerArgs)
/*  Google Tag Manager */ 


ReactDOM.render(
  <App />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
