import React, { Component } from "react";
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button, Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Redirect } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";



class ChangeLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      image: null,
      displaychangetext: true,
      displaydeletetext: false,
      token: JSON.parse(localStorage.getItem('currentUser')).token, 
      userId: JSON.parse(localStorage.getItem('currentUser')).id
    };

    this.onImageChange = this.onImageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getImage();
    this.setState({
        isLoading: false
      });

  };

  getImage()
  {

    /* I need to get company id*/ 
    var connectionstring = process.env.REACT_APP_apiUrl + '/api/image/' + this.state.userId;

    axios
      .get(
        connectionstring,
      { responseType: 'arraybuffer', 
      headers: {
        'Authorization': `Bearer ${this.state.token}`
      }}, 
    )
      .then(response => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        this.setState({ image: "data:;base64," + base64, displaychangetext: false });
      })
      .catch(error => {
        /*console.log(error);*/
        this.setState({ image: null})
      });
  }

  displaychange = () => 
  {
    this.setState({ 
      displaychangetext: true 
       });
  }

  deleteLogo = () => 
  {
    var connectionstringdelete = process.env.REACT_APP_apiUrl + '/api/image/' + this.state.userId;
    console.log(connectionstringdelete);

    trackPromise(  
    axios.delete(connectionstringdelete, 
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          this.setState({ 
            displaychangetext: true, 
            image: null 
             });
        })
    )




  }


  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img)
      });

    const data = new FormData();
    /*data.append('image', {
      fileName: img.fileName,
      uri: img.uri,
      name: img.name,
      type: img.type
  });*/

 /* data.append('files', event.target.files[0], event.target.files[0].name); */
 data.append('UserId', this.state.userId);
 data.append('CompanyId', "1");
 data.append('File', event.target.files[0], 'mylogo2.jpg');
 
 console.log(data);


    var connectionstring = process.env.REACT_APP_apiUrl + '/api/image';
    console.log(connectionstring);

    axios.post(connectionstring, data
        ,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`, 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          this.setState({ displaychangetext: false });
          console.log("The image changed");
        })
        .catch(error => console.log(error));
  }};

  handleSubmit = event => {
    event.preventDefault();
    const redirectpage = '/HomeLoggedIn/'
    this.setState({
      redirect: redirectpage
    });
  };


  render() {

     
  if (this.state.redirect) {
    return <Redirect to={this.state.redirect} />
  }


  const { isLoading } = this.state;

  if (isLoading) {
    return <div className="Settings"><LoadingSpinner/></div>;
  }

    return (
      <Layout>
          <br /> <br />
      <div>
        <div>
        <h1><Trans i18nKey="image.logo"/></h1>
        {this.state.image &&
        <img src={this.state.image} alt="logoimage"/>
        }<br /><br />
        {this.state.image &&
              <Button variant="danger" className="mr-1" onClick={this.deleteLogo}><Trans i18nKey="image.delete"/></Button> 
          }
          {this.state.displaychangetext ?
            <div>
              <div>
              <br />
              <input type="file" name="myImage" onChange={this.onImageChange} />
            </div>
            <br />
            <Trans i18nKey="image.size-info"/> <br />
            <Trans i18nKey="image.type-info"/><br />
          </div>
          : <Button variant="secondary" onClick={this.displaychange}><Trans i18nKey="image.edit"/></Button>
          }
          </div>


      </div>
      <Form  onSubmit={this.handleSubmit}>
      <Table borderless>
      <tbody>
      <tr>
      <td></td>
      <td><Button variant="primary" type="submit" className="float-right"><Trans i18nKey="navigation.continue"/></Button></td>
      </tr>
      </tbody>
      </Table>
      </Form>
      </Layout>
    );
  }
}

export default ChangeLogo;