import React, { Component } from "react";
import './App.css';
import LoadingSpinner from "./components/LoadingSpinner";
import videoIntro from './assets/videohelpintro.mp4';
import imageVideoIntro from './assets/imagevideohelpintro.png';
import i18n from './i18n';
import Footer from './Footer.js';


class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, 
      videoIntroSrc: ''
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    i18n.changeLanguage('en');
    this.setState({
      videoIntroSrc: videoIntro
  })
  };


 


  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
        <div style={{display: "flex"}}>
        <video id='videoIntro' src={this.state.videoIntroSrc}  poster={imageVideoIntro} controls playsInline muted  autoPlay loop  style={{width: '100%', top: '-2px', overflow: 'hidden'}}></video>
        </div>
        <Footer/>

    </React.Fragment>
  );
};
};

export default Intro;