import React from 'react';
import axios from 'axios';
import { Form, Button, Modal, Row, Col} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./LoadingSpinner";


class Reset extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    language: "en",
    email: "",
    errorEmail: "",
    postStatus: "",
    show: false, 
    isLoading: false, 
    done: false, 
    doneMessage: "", 
    i18_button: "Forgot Password",
    i18_header: "Reset Password",
    i18_close: "Close",
    i18_reset: "Reset",
    i18_success: "The password is reset. Please check your email and login with the new credentials.",
    i18_error: "The password could not be reset. If the error is persistent, please contact support.",
    i18_email_placeholder: "Email",
    i18_email_incorrect: "The email is incorrect"
  }
}


  // Let's our app know we're ready to render the data
  componentDidMount() {
    if (this.props.language === 'se'){
      this.setState({
        i18_button: "Glömt lösenord",
        i18_header: "Återställa lösenord",
        i18_close: "Stäng",
        i18_reset: "Återställ",
        i18_success: "Lösenordet är nollställt och skickat till din e-post. Vänligen logga in med nya lösenordet.",
        i18_error: "Lösenordet kunde inte bli nollställt. Kontakta support om det inte fungerar igen.", 
        i18_email_placeholder: "E-post",
        i18_email_incorrect: "E-posten är inte korrekt"
    
      });
    }
  };
 

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value 
    });
  }

  validateEmail(email) {
    // eslint-disable-next-line
    var emailregex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailregex.test(email);
  }

  handleClose = () => {
    this.setState(state => {
      return {
        show: false
     };
  })}

  handleShow = () => {
    this.setState(state => {
      return {
        show: true
     };
  })}


  postReset = event => {
    event.preventDefault();
    var formisvalid = true;

    if (!this.validateEmail(this.state.email)) 
    {
      this.setState(state => { return { errorEmail: this.state.i18_email_incorrect }});
      formisvalid = false;
    } else {
        this.setState(state => { return { errorEmail: '' }});
    }

    if (formisvalid) {
        const model = {};
        model.UserName = this.state.email;
        model.Password = "new-password"; 

        const UserRegisterBasicUser = model;
        const connectionstring = process.env.REACT_APP_apiUrl + '/identity/Users/resetpassword';

        console.log(model);

        trackPromise(
        axios.post(connectionstring, UserRegisterBasicUser
        ,
        {
        headers: {
            'Content-Type': 'application/json' 
        }
        })
        .then(res => {
            console.log("success");
            this.setState({ 
              done: true, 
              doneMessage: this.state.i18_success 
             });
            /*this.props.history.push('/bonusagreements');*/
        }))
        .catch(
          error => {
            this.setState({ 
              done: true, 
              doneMessage: this.state.i18_error 
             });
            console.log(error);

        }
        );
     }
};



  // Putting that data to use
  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }

    return (
        <div>
          <Button variant="secondary" className="float-left mr-1" onClick={this.handleShow}>{this.state.i18_button}</Button>
          <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.i18_header}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {!this.state.done && <Form>
                <Form.Group ><Row><Col><Form.Control type="text" name="name" placeholder={this.state.i18_email_placeholder} className="mr10" value={this.state.email} onChange={e => this.handleEmailChange(e)}></Form.Control></Col></Row></Form.Group>
                <Form.Group ><Row><Col>{this.state.errorEmail !== "" && <div className="validationerror"> {this.state.errorEmail}</div> }</Col><Col></Col></Row></Form.Group>
                </Form>}
                <LoadingSpinner/>
                {this.state.done && this.state.doneMessage}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>{this.state.i18_close}</Button>
                {!this.state.done && <Button variant="primary" onClick={this.postReset} className="float-left">{this.state.i18_reset}</Button>}
              </Modal.Footer>
            </Modal>
        </div>




    );
  }
}

export default Reset;