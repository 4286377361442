import React, { Component } from "react";
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import 'reactjs-popup/dist/index.css';
import { Form, Button, Modal, Row, Col} from 'react-bootstrap';
import { Trans } from 'react-i18next';
import i18n from '../i18n';
import "bootstrap/dist/css/bootstrap.css";
import { Redirect } from "react-router-dom";



class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage.getItem('currentUser')).token, 
      userId: JSON.parse(localStorage.getItem('currentUser')).id, 
      show: false, 
      shareName: "", 
      shareEmail: "", 
      bonusAgreementId: 1
    };

  }

  validateName(name) {
    /*this.reseterrors();*/
      var formisvalid = true;

      if (name === "") {
        formisvalid = false;
      }
    return formisvalid;
  }

  validateEmail(email) {
    // eslint-disable-next-line
    var emailregex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailregex.test(email);
  }

  handleShareChange = (e) => {
    const {name, value} = e.target;

    var shareName = this.state.shareName;
    var shareEmail = this.state.shareEmail;
    var errorShareName = this.state.errorShareName;
    var errorShareEmail = this.state.errorShareEmail;

    if (name === "shareName") {
      shareName = value;
      errorShareName = "";
    }

    if (name === "shareEmail") {
      shareEmail = value;
      errorShareEmail = "";
    }

    this.setState(state => {
      return {
        shareName: shareName,
        shareEmail: shareEmail,
        errorShareName: errorShareName,
        errorShareEmail: errorShareEmail

     }});
    
    }

  handleClose = () => {
    this.setState(state => {
      return {
        show: false
     };
  })}

  handleShow = () => {
    this.setState(state => {
      return {
        show: true
     };
  })}

  postShare = event => {
    event.preventDefault();

    var validforsend = true;
    if (!this.validateEmail(this.state.shareEmail)) { validforsend = false; this.setState(state => { return {  errorShareEmail: <Trans i18nKey="error-share.email-incorrect"/> }}); }
    if (!this.validateName(this.state.shareName)) {validforsend = false; this.setState(state => { return {  errorShareName: <Trans i18nKey="error-share.name-incorrect"/> }});}


    const model = {};

    if (validforsend) {
      model.ShareEmail = this.state.shareEmail; 
      model.ShareName = this.state.shareName; 
      model.BonusAgreementId = this.props.bonusAgreementId;

      const connectionstring = process.env.REACT_APP_apiUrl + '/api/Share';
      console.log(connectionstring);
      console.log(model);

      trackPromise(
      axios.post(connectionstring, model
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          this.setState({
            redirect: "/BonusAgreements"
          });
        }))
      } else { /* Not valid for send */}
  };


  render() {

    if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }

    return (
        <div>
          <Button variant="secondary" className="float-right mr-1" onClick={this.handleShow}><Trans i18nKey="share.share"/></Button>
          <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title><Trans i18nKey="share.title"/></Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p><Trans i18nKey="share.who-to-share-with"/></p>
              
                <Form.Group ><Row><Col><Form.Control type="text" name="shareName" placeholder={i18n.t('share.name')} className="mr10" value={this.state.shareName} onChange={e => this.handleShareChange(e)}></Form.Control></Col></Row></Form.Group>
         
                 <Form.Group ><Row><Col><Form.Control type="text" name="shareEmail" placeholder={i18n.t('share.email')} className="mr10" value={this.state.shareEmail} onChange={e => this.handleShareChange(e)}></Form.Control></Col></Row></Form.Group>
                {this.state.errorShareName !== "" ? <Form.Group ><Row><Col><div className="validationerror"> {this.state.errorShareName}</div></Col></Row></Form.Group> : ""} 
                 {this.state.errorShareEmail !== "" ? <Form.Group ><Row><Col><div className="validationerror"> {this.state.errorShareEmail}</div></Col></Row></Form.Group> : "" } 
    

              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}><Trans i18nKey="navigation.close"/></Button>
                <Button variant="primary" onClick={this.postShare} className="float-right"><Trans i18nKey="navigation.share"/> </Button>
              </Modal.Footer>
            </Modal>
        </div>
    );
  }
}

export default Share;