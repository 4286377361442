import React from 'react';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { authenticationService } from './_services/authentication.service';
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

class AdminBonusAgreements extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    bonusagreements: [],
    isLoading: true,
    errors: null, 
    role: JSON.parse(localStorage.getItem('currentUser')).role,
    token: JSON.parse(localStorage.getItem('currentUser')).token, 
    redirect: false
  };
  // Now we're going to make a request for data using axios
  getBonusAgreements() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpi';
    const connectionstring = connectionstring1.concat("" , connectionstring2);

    axios
      // This is where the data is hosted
      .get(connectionstring,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          bonusagreements: response.data,
          isLoading: false
        });
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    if (this.state.role !== "Admin") { 
      authenticationService.logout();
      this.setState({redirect: true })
      
    } else {
    this.getBonusAgreements();
  }
  }
  // Putting that data to use
  render() {
    const { isLoading, bonusagreements } = this.state;

    if (this.state.redirect) {
      return <Redirect to={"/"} />
    }

    return (
      <React.Fragment>
        <Layout>
        <br /> 
        <h2>Bonus Agreements Admin</h2>
        <br />
        <div  key={uuidv4()}>
          {!isLoading ? (
            bonusagreements.map(bonusagreement => {
              return (
                <div className="card" key={uuidv4()}>
                  <div className="card-body">
                    <h4 className="card-title">{bonusagreement.employeeFullName} - {bonusagreement.name}</h4> 
                    <h6 className="card-subtitle mb-2 text-muted">{bonusagreement.phase}</h6>
                    Bonus agreement Id: {bonusagreement.bonusAgreementId}. <br />
                    Length: {bonusagreement.kpi.length}

                    {/*
                    {(bonusagreement.kpi.kpiId !== null) && <div> This is not null
                      </div>}
                    
                    {bonusagreement.kpi.kpiId && bonusagreement.kpi.map((kpi,i) => {
                      return (
                        <div>
                        <p class="card-text">{i+1}. {kpi.name} - {kpi.description}</p>
                        </div>
                        )
                    })}
                  */}
                 
                    
                    
                    <Container>
                        <Row>     
                      <Col><Link to={`/listonebonus/${bonusagreement.bonusAgreementId}`}>View</Link></Col>
                      <Col><Link to={`admindeletebonusagreement/${bonusagreement.bonusAgreementId}`}>Delete</Link></Col>
                      {/*<Col><input type="button" value="Delete" onClick={this.DeleteAgreement} /></Col>*/}
                      </Row>
                      </Container>
                    <br />
                  </div>  
                </div>
              )
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default AdminBonusAgreements;