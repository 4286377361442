import React, { Component } from "react";
import './App.css';
import LoadingSpinner from "./components/LoadingSpinner";
import videoPartner from './assets/videopartner.mp4';
import posterPartner from './assets/posterpartner.png';
import i18n from './i18n';
import Footer from './Footer.js';


class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false, 
      videoPartnerSrc: ''
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    i18n.changeLanguage('en');
    this.setState({
      videoPartnerSrc: videoPartner
  })
  };


 


  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
      <div>
      <div style={{display: "flex"}}>
        <video id='videoIntro' src={this.state.videoPartnerSrc}  poster={posterPartner} controls playsInline muted  autoPlay loop style={{width: '100%', top: '-2px', overflow: 'hidden'}}></video>
        </div>
        <Footer/>
        </div>

    </React.Fragment>
  );
};
};

export default Partner;