import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import { useTranslation, Trans } from 'react-i18next';

import { Layout } from './components/Layout'

export function HomeLoggedIn () {
  const { i18n } = useTranslation();

  useEffect(() => {
    /*  window.localStorage.i18nextLng */ 
    if (JSON.parse(localStorage.getItem('currentUser')).language === 'se') {
      i18n.changeLanguage('se');
    }

    if (JSON.parse(localStorage.getItem('currentUser')).language === 'en') {
      i18n.changeLanguage('en');
    }


  }, [i18n]);


  return (
  <div>
    <Layout>
        <div>
        <br/>
        <h1><Trans i18nKey="home.header"/></h1> 
        <Trans i18nKey="home.subheader"/> <br /><br />
        <CardDeck>
        <Card style={{ width: '18rem' }} bg='light'>
          <Card.Body>
            <Card.Title><Trans i18nKey="home.new-bonus-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="home.new-bonus-text"/>
            </Card.Text>  
            <Link to="/editonebonus/new"><Button variant="primary"><Trans i18nKey="home.new-bonus-button"/></Button></Link>
          </Card.Body>
        </Card>
       </CardDeck>
        <br />
        <CardDeck>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title><Trans i18nKey="home.view-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="home.view-text"/>
            </Card.Text>
            <Link to="/bonusagreements"><Button variant="secondary"><Trans i18nKey="home.view-button"/></Button></Link>
          </Card.Body>
        </Card>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title><Trans i18nKey="home.settings-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="home.settings-text"/>
            </Card.Text>
            <Link to="/settings"><Button variant="secondary"><Trans i18nKey="home.settings-button"/></Button></Link>
          </Card.Body>
         </Card>
        </CardDeck>
        {/* Site: {process.env.REACT_APP_apiUrl} */}
        </div>
    </Layout>
  </div>
  )
}

