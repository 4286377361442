import React, { Component } from "react";
import './App.css';
import { Layout } from './components/Layout';
import { Button } from 'react-bootstrap';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { authenticationService } from './_services/authentication.service';
import { Redirect } from 'react-router-dom';


class OrderConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      userName: JSON.parse(localStorage.getItem('currentUser')).userName
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {

  };

  logout() {
    authenticationService.logout();
    this.setState({ redirect: true })
  }


  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }

    if ( this.state.redirect ) {
        this.setState({ redirect: false })
        return <Redirect push to="/" />;
       }


  return (
    <React.Fragment>
    <Layout>
        <br /><br />
        <h1><Trans i18nKey="subscription.confirmation-header"/></h1>
        <Trans i18nKey="subscription.confirmation-order-recieved"/> <br /><br />
        <Trans i18nKey="subscription.confirmation-logout-login"/> <br /><br />
      <LoadingSpinner/>
      <Table borderless>
        <tr>
            <td><span style={{'color': '#007bff', 'text-decoration': 'none', 'background-color': 'transparent', 'cursor':'pointer'}} onClick={() => {this.logout()}}><Button variant="primary" className="mr-1" ><Trans i18nKey="subscription.confirmation-continue"/></Button></span></td>
       </tr>
      </Table>
      <br /><br />


    </Layout>
    </React.Fragment>
  );
};
};

export default OrderConfirmation;