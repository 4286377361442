import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import BonusTableSteps from './components/bonustablesteps';
import { Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class ListOneBonus extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    token: JSON.parse(localStorage.getItem('currentUser')).token,
    bonusAgreementId: "",
    name: "",
    onTargetReward: null,
    resultAmount: null,
    period: "",
    employeeUserName: "",
    managerUserName: "",
    employeeFullName: "",
    managerFullName: "",
    phase: "",
    kpi: [],
    data_bonuslevels: [],
    isLoading: true,
    errors: null
  }
}
  // Now we're going to make a request for data using axios
  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpiapproval/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);
    
    trackPromise(
    axios
      .get(connectionstring,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
      .then(response => {
        this.setState({
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          currency: response.data.currency,
          phase: response.data.phase,
          language: response.data.language,
          kpi: response.data.kpi,
          approval: response.data.approval,
          translation: response.data.translationStringList, 
          data_bonuslevels: [],
          isLoading: false
        });
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));

  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getBonusAgreement();
  };
 


  // Putting that data to use
  render() {
        
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="ViewSignedResultsAgreement"><LoadingSpinner/></div>;
    }

    return (
      <React.Fragment>
        <Layout>
        <br />
        <h2>{this.state.translation.string000}</h2>
        <br /> 
        
        <h4 className="card-title">{this.state.name} - {this.state.employeeFullName}</h4> 
        <h6 className="card-subtitle mb-2 text-muted">{this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}</h6>
         <br />          

        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        <p className="card-text">{!kpi.description ? "" : kpi.description + ". "} {this.state.translation.string003} {kpi.percentageOfTotalReward}%. {this.state.translation.string044}:  {kpi.goal}. {this.state.translation.string001}: {kpi.onTargetReward} {this.state.currency} </p>
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} onTargetReward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency} kpistep={kpi.kpiStep} language={this.state.language}></BonusTableSteps>
                        <br />
                        </div>
                        
                        )
                    })}
        {this.state.phase === "Draft" 
        ? <div><i>{this.state.translation.string010}</i></div>
        : <div>

        {((this.state.phase === "Signed") || (this.state.phase === "Set Results"))        
        ? <div>
            <h2>{this.state.translation.string016}</h2><br />
            <h4>{this.state.translation.string027}</h4><br />
          </div>
        : <div>
            <h2>{this.state.translation.string043}</h2>
          </div>   
        }   <br />


        {this.state.approval.map((approval,j) => {
                        return (
                          <div key={j} >
                          {approval.approvalStatus === 'Signed' 
                              ? <div>
                                  <h5>{approval.fullName}</h5>
                                  <Trans i18nKey="approval.orginal-bonus-signed"/>
                                  <br /><br />
                                  <Table >
                                  <tbody>
                                  <tr>
                                      <td><Trans i18nKey="approval.email"/>: {approval.email}</td>
                                      <td><Trans i18nKey="approval.date"/>: {approval.dateAnswered}</td>
                                      <td><Trans i18nKey="approval.time"/>: {approval.timeAnswered} </td>
                                      <td><Trans i18nKey="approval.ipaddress"/>: {approval.ipAddress}</td>
                                  </tr>
                                  <tr>
                                      <td><Trans i18nKey="approval.city"/>: {approval.city}</td>
                                      <td><Trans i18nKey="approval.country"/>: {approval.country}</td>      
                                      <td><Trans i18nKey="approval.longitude"/>: {approval.longitude}</td>
                                      <td><Trans i18nKey="approval.latitude"/>: {approval.latitude}</td>                            
                                  </tr>
                                  </tbody>
                                  </Table>
                                  </div>
                              : <div>
                                {approval.phase === "Set Results"
                                  ? <div></div>
                                  : <div>
                                    <h5>{approval.fullName}</h5>
                                     {approval.dateSent ? " " + this.state.translation.string039 + " " + approval.dateSent + "." : this.state.translation.string040 + "."}
                                     <br /><br /></div>
                                }
                                </div>}
                          </div>
                          )
                      })
                      }
        </div>}

        <br />    
        <Link className="float-left" to={`/bonusagreements`}><Button variant="secondary"><Trans i18nKey="navigation.return"/></Button></Link><br /><br />
       </Layout>
      </React.Fragment>
    );
  }
}

export default ListOneBonus;