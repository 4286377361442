import React from "react";
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import BonusTableSteps from './components/bonustablesteps';
/*import './App.css';*/

class SignResultsFromEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      displayAgreement: "false",  
      bonusAgreementId: "",
      employeeEmail: "", 
      employeeFullName: "", 
      phase: "", 
      dateSent: "", 
      dateAnswered: "", 
      timeAnswered: "", 
      approvalStatus: "", 
      approvalLevel: "", 
      approvalComment: "", 
      email: "", 
      fullName: "", 
      ipAddress: "", 
      city: "", 
      country: "", 
      longitude: "", 
      latitude: "", 
      name: "", 
      onTargetReward: "", 
      resultAmount: "", 
      period: "",  
      employeeUserName: "", 
      managerUserName: "", 
      kpi: undefined
    };
  }
 
  getIPInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
          bonusAgreementId: this.state.bonusAgreementId,
          employeeEmail: this.state.employeeEmail, 
          employeeFullName: this.state.employeeFullName, 
          phase: this.state.phase, 
          dateSent: this.state.dateSent, 
          dateAnswered: this.state.dateAnswered, 
          timeAnswered: this.state.timeAnswered, 
          approvalStatus: this.state.approvalStatus, 
          approvalLevel: this.state.approvalLevel, 
          approvalComment: this.state.approvalComment, 
          email: this.state.email, 
          fullName: this.state.fullName, 
          ipAddress: data.ip, 
          city: data.city, 
          country: data.country_name, 
          longitude: String(data.longitude), 
          latitude: String(data.latitude), 
          name: this.state.name,
          onTargetReward: this.state.onTargetReward,
          resultAmount: this.state.resultAmount,
          period: this.state.period,
          employeeUserName: this.state.employeeUserName,
          managerUserName: this.state.managerUserName,
          kpi: this.state.kpi,
        });
        

    }).catch((error) => {

    });
};

  getBonusAgreement() {
    const connectionstringemail = process.env.REACT_APP_apiUrl + '/api/signfromemail/' + this.props.match.params.EmailCode + '/' + this.props.match.params.Email;


    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstringemail
      ,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          employeeEmail: response.data.employeeUserName, 
          employeeFullName: response.data.employeeFullName, 
          phase: response.data.phase, 
          dateSent: "", 
          dateAnswered: "", 
          timeAnswered: "", 
          approvalStatus: "", 
          approvalLevel: 0, 
          approvalComment: "", 
          email: this.props.match.params.Email, 
          fullName: response.data.approval[0].FullName, 
          ipAddress: "", 
          city: "", 
          country: "", 
          longitude: "", 
          latitude: "", 
          currency: response.data.currency,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          language: response.data.language,
          translation: response.data.translationStringList,
          kpi: response.data.kpi,
          isLoading: false
        });
        this.getIPInfo();
        console.log(response.data);
        if ((response.data.phase === "Signed") || (response.data.phase === "Set Results")  || (response.data.phase === "Sign Results"))
        { this.setState({ displayAgreement: "true" }); console.log("We should display results")}
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, displayAgreement: "false", isLoading: false })));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getBonusAgreement();
  };


  handleChange = (e) => {
    const {name, value} = e.target;

    var New_bonusAgreementId = this.state.bonusAgreementId; 
    var New_employeeEmail = this.state.employeeEmail; 
    var New_employeeFullName = this.state.employeeFullName; 
    var New_phase = this.state.phase; 
    var New_dateSent = this.state.dateSent; 
    var New_dateAnswered = this.state.dateAnswered; 
    var New_timeAnswered = this.state.timeAnswered; 
    var New_approvalStatus = this.state.approvalStatus; 
    var New_approvalLevel = this.state.approvalLevel; 
    var New_approvalComment = this.state.approvalComment; 
    var New_email = this.state.email;
    var New_fullName = this.state.fullName; 
    var New_ipAddress = this.state.ipAddress; 
    var New_city = this.state.city; 
    var New_country = this.state.country; 
    var New_longitude = this.state.longitude; 
    var New_latitude = this.state.latitude; 
    var New_name = this.state.name; 
    var New_onTargetReward = this.state.onTargetReward;
    var New_resultAmount = this.state.resultAmount; 
    var New_period = this.state.period;  
    var New_employeeUserName = this.state.employeeUserName; 
    var New_managerUserName = this.state.managerUserName; 
    if (name === "bonusAgreementId") { New_bonusAgreementId = value;}
    if (name === "name") { New_name = value;}
    if (name === "onTargetReward") { New_onTargetReward = value;}
    if (name === "resultAmount") { New_resultAmount = value;}
    if (name === "period") { New_period = value;}
    if (name === "employeeUserName") { New_employeeUserName = value;}
    if (name === "managerUserName") { New_managerUserName = value;}
    if (name === "phase") { New_phase = value;}


    this.setState({
      bonusAgreementId: New_bonusAgreementId, 
      employeeEmail : New_employeeEmail, 
      employeeFullName : New_employeeFullName, 
      phase : New_phase,
      dateSent : New_dateSent, 
      dateAnswered : New_dateAnswered, 
      timeAnswered : New_timeAnswered, 
      approvalStatus : New_approvalStatus, 
      approvalLevel : New_approvalLevel, 
      approvalComment : New_approvalComment, 
      email : New_email,
      fullName : New_fullName, 
      ipAddress : New_ipAddress, 
      city : New_city, 
      country : New_country, 
      longitude : New_longitude, 
      latitude : New_latitude, 
      name: New_name, 
      onTargetReward: New_onTargetReward, 
      resultAmount: New_resultAmount, 
      period: New_period,  
      employeeUserName: New_employeeUserName, 
      managerUserName: New_managerUserName, 
      kpi: this.state.kpi,
    
    });


  }
 
  handleSubmit = event => {
    event.preventDefault();
    this.setState({  isLoading: true })

    var today = new Date();

    const model = {};
    model.bonusAgreementId = this.state.bonusAgreementId; 
    model.employeeEmail = this.state.employeeEmail; 
    model.employeeFullName = this.state.employeeFullName; 
    model.phase = this.state.phase;
    model.dateSent = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(); 
    model.dateAnswered = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    model.timeAnswered = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(); 
    model.approvalStatus = this.state.approvalStatus; 
    model.approvalLevel = this.state.approvalLevel; 
    model.approvalComment = this.state.approvalComment; 
    model.email = this.props.match.params.Email;
    model.fullName = this.state.fullName; 
    model.ipAddress = this.state.ipAddress; 
    model.city = this.state.city;
    model.country = this.state.country; 
    model.longitude = this.state.longitude; 
    model.latitude = this.state.latitude; 
    model.emailCode = this.props.match.params.EmailCode;

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/SignFromEmail';
    const connectionstring = connectionstring1.concat("" , connectionstring2);


    trackPromise(
    axios.post(connectionstring, BonusAgreement
      ,
    {
      headers: {
        'Content-Type': 'application/json', 
      }
    })
      .then(res => {
        this.props.history.push('/issigned/' + this.state.language);
      }))
  };

  render () {

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="SignResultsFromEmail"><LoadingSpinner/></div>;
    }

  return (
    <React.Fragment>
    <Layout>
    {(this.state.displayAgreement === "true") ? 
    ( <div>
      <br />
      <br />
        <h2>{this.state.translation.string041}</h2>
        <i>{this.state.translation.string014}</i>
        <br /><br />
        <LoadingSpinner/>
        <h4 className="card-title">{this.state.name}</h4>
        {this.state.translation.string018} {this.state.employeeFullName}<br />
        {this.state.translation.string019}: <b>{this.state.resultAmount} {this.state.currency}</b><br />
        {this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}

          <br />   <br />       

        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        {kpi.description} <br />
                        {this.state.translation.string003} {kpi.percentageOfTotalReward}% <br />
                        {this.state.translation.string009} {kpi.onTargetReward} {this.state.currency} <br />
                        {this.state.translation.string019} {kpi.result} {kpi.unit} {this.state.translation.string020} <b>{kpi.resultAmount} {this.state.currency} </b> <br /><br />
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} resultAmount={kpi.resultAmount} language={this.state.language}></BonusTableSteps>
                        <br />
                       </div>
                        
                        )
                    })}
      <Form onSubmit={this.handleSubmit}>
      <Button variant="primary" type="submit" className="float-right">{this.state.translation.string015}</Button>
      </Form>
      <br /><br />
      
      <br />


        <br /><br />
        </div> ) : (
        <div> <br /><br /> 
        <h2>{this.state.translation.string056}</h2> 
        <i>{this.state.translation.string057}</i>
        </div>
        )}
    </Layout>
    </React.Fragment>
  );
};
};

export default SignResultsFromEmail;