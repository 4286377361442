import React, {useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { trackPromise } from 'react-promise-tracker';
import { VisibilityOutlined, DeleteOutlined, EditOutlined, FormatListBulletedOutlined } from '@mui/icons-material';
import { ContentCopyOutlined, ScreenShareOutlined, CloseOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StopSign from './StopSign';
import 'typeface-roboto';
import i18n from './i18n';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function BonusAgreements() {
  const [ bonusagreements, setBonusagreements] = useState([]);
  const [ hereLocaleText, setHereLocaleText ] = useState([]);
  const [ downloaded, setDownloaded ] = useState("no");
  let history = useHistory();


   
  const columns = [
    {field: 'name', headerClassName: 'mymuiheader', headerFontWeight: 'bold', width: 250, headerName: i18n.t("bonus.name")},
    {field: 'employeeFullName', headerClassName: 'mymuiheader', width: 180, headerName: i18n.t("bonus.employee-name")},
    {field: 'period', headerClassName: 'mymuiheader', headerName: i18n.t("bonus.period")},
    {field: 'phase', headerClassName: 'mymuiheader', headerName: i18n.t("bonus.phase"), width: 220,
      renderCell: (cellValues) => {
        return(
        <div>
          {i18n.t("phase." + cellValues.row.phase)}
        </div>)
      }
    },
    {
      field: "View",
      headerName: i18n.t("navigation.view"),
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            color="secondary"
            onClick={(event) => {
              handleView(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <VisibilityOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    }, 
    {
      field: "Edit",
      headerName: i18n.t("navigation.edit"),
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Set Results" || cellValues.row.phase === "Signed" ) &&
          <Button
            variant="text"
            onClick={(event) => {
              handleEdit(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <FormatListBulletedOutlined sx={{ color: "#007bff" }} />
          </Button>}
          </div>
        );
      }
    }, 
    {
      field: "Sign",
      headerName: i18n.t("approval.sign"),
      headerClassName: 'mymuiheader', 
      width: 120,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Set Results") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleSign(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <EditOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    },
    {
      field: "Stop",
      headerName: i18n.t("approval.stop-sign"),
      headerClassName: 'mymuiheader', 
      width: 120,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Sign"  || cellValues.row.phase === "Sign Results") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleStopSign (event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <CloseOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    },  
    {
      field: "Share",
      headerName: i18n.t("share.share"),
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            onClick={(event) => {
              handleShare(event, cellValues);
            }}
          >
            <ScreenShareOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    },
    {
      field: "Copy",
      headerName: i18n.t("bonus.copy"),
      headerClassName: 'mymuiheader', 
      width: 100,
      renderCell: (cellValues) => {
        return (<div>
          <Button
            variant="text"
            onClick={(event) => {
              handleCopy(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <ContentCopyOutlined sx={{ color: "#007bff" }}/>
          </Button>
          </div>
        );
      }
    },
    {
      field: "Delete",
      headerName: i18n.t("bonus.delete"),
      headerClassName: 'mymuiheader', 
      width: 80,
      renderCell: (cellValues) => {
        return (<div>
          {(cellValues.row.phase === "Draft"  || cellValues.row.phase === "Sign") &&
          <Button
            variant="text"
            onClick={(event) => {
              handleDelete(event, cellValues);
            }}
          >
            {/*<div><FontAwesomeIcon icon={faTrashAlt} /></div>*/}
            <DeleteOutlined sx={{ color: "#007bff" }}/>
          </Button>}
          </div>
        );
      }
    }


  ]
  
  function handleView(e, cellValues) {
    console.log("We should view a page");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results" || cellValues.row.phase === "Sign Results" || cellValues.row.phase === "Signed Results")
    { 
      history.push("/listonebonuswithresult/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft" || cellValues.row.phase === "Sign" || cellValues.row.phase === "Signed")
  {
    history.push("/listonebonus/" + cellValues.id)
  }
  }

  function handleEdit(e, cellValues) {
    console.log("We should edit a page");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results" || cellValues.row.phase === "Signed" )
    { 
      history.push("/setresultonebonus/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft")
  {
    history.push("/editonebonus/" + cellValues.id)
  }
  }

  function handleSign(e, cellValues) {
    console.log("We should sign");
    console.log(cellValues);
    
    if (cellValues.row.phase === "Set Results")
    { 
      history.push("/signresults/" + cellValues.id); 
    }

  if (cellValues.row.phase === "Draft")
  {
    history.push("/sign/" + cellValues.id)
  }
  }

  function handleStopSign (e, cellValues) {
    StopSign({bonusAgreementId: cellValues.id});
  } 

  function handleShare(e, cellValues) {
    console.log("We should share");
    console.log(cellValues);

    history.push("/sharebonusagreement/" + cellValues.id); 

  }

  function handleCopy (e, cellValues) {

    const model = {};
    model.bonusAgreementId = cellValues.id;
    model.userId = JSON.parse(localStorage.getItem('currentUser')).id;

    const BonusAgreementCopy = model;

    var connectionstring = process.env.REACT_APP_apiUrl + '/api/bonuskpiapproval/copybonusagreement';
    let token = JSON.parse(localStorage.getItem('currentUser')).token;

    trackPromise(  
    axios.post(connectionstring, BonusAgreementCopy,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(res => {
          /*this.props.history.push('/bonusagreements');*/
          window.location.reload(false);
        })
    ).catch(error => console.log(error));

} 

function handleDelete (e, cellValues) {
  console.log("We should sign");
  console.log(cellValues);
  
  if (cellValues.row.phase === "Draft" || cellValues.row.phase === "Sign")
  { 
    history.push("/deletebonusagreement/" + cellValues.id); 
  }

}


  useEffect(() => {

      let token = JSON.parse(localStorage.getItem('currentUser')).token;
      let managerUserName = JSON.parse(localStorage.getItem('currentUser')).userName;
      let language = JSON.parse(localStorage.getItem('currentUser')).language;
      let connectionstring = process.env.REACT_APP_apiUrl + "/api/bonusagreements/" + token + "/" + managerUserName;
      console.log(connectionstring);
  
      trackPromise(
      axios
        .get(connectionstring,
          {
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${token}`,
            }
          })
        .then(response => {
          setBonusagreements(response.data);
          setDownloaded("yes");
          console.log(response.data);
       
        })
        // If we catch any errors connecting, let's update accordingly
        .catch(error => console.log(error)));
 
        if (JSON.parse(localStorage.getItem('currentUser')).language === 'se') {
          i18n.changeLanguage('se');
        }
    
        if (JSON.parse(localStorage.getItem('currentUser')).language === 'en') {
          i18n.changeLanguage('en');
        }

 
        if (language === "se") {
          setHereLocaleText( 
            {
              noRowsLabel: 'Inga rader.',
              noResultsOverlayLabel: 'Inga resultat hittade.',
              errorOverlayDefaultLabel: 'Ett fel har uppstått.',
            
              // Density selector toolbar button text
              toolbarDensity: 'Storlek',
              toolbarDensityLabel: 'Storlek',
              toolbarDensityCompact: 'Kompakt',
              toolbarDensityStandard: 'Normal',
              toolbarDensityComfortable: 'Stor',
            
              // Columns selector toolbar button text
              toolbarColumns: 'Kolumner',
              toolbarColumnsLabel: 'Välj kolumner',
            
              // Filters toolbar button text
              toolbarFilters: 'Filter',
              toolbarFiltersLabel: 'Visa filter',
              toolbarFiltersTooltipHide: 'Dölj filter',
              toolbarFiltersTooltipShow: 'Visa filter',
              toolbarFiltersTooltipActive: (count) =>
                count > 1 ? `${count} aktiva filter` : `${count} aktivt filter`,
            
              // Export selector toolbar button text
              toolbarExport: 'Exportera',
              toolbarExportLabel: 'Exportera',
              toolbarExportCSV: 'Exportera till CSV',
              toolbarExportPrint: 'Skriv ut',
            
              // Columns panel text
              columnsPanelTextFieldLabel: 'Sök i kolumn',
              columnsPanelTextFieldPlaceholder: 'Kolumntitel',
              columnsPanelDragIconLabel: 'Flytta kolumn',
              columnsPanelShowAllButton: 'Visa alla',
              columnsPanelHideAllButton: 'Dölj alla',
            
              // Filter panel text
              filterPanelAddFilter: 'Lägg till filter',
              filterPanelDeleteIconLabel: 'Ta bort',
              filterPanelOperators: 'Operator',
              filterPanelOperatorAnd: 'och',
              filterPanelOperatorOr: 'eller',
              filterPanelColumns: 'Kolumn',
              filterPanelInputLabel: 'Värde',
              filterPanelInputPlaceholder: 'Filtervärde',
            
              // Filter operators text
              filterOperatorContains: 'innehåller',
              filterOperatorEquals: 'lika med',
              filterOperatorStartsWith: 'börjar med',
              filterOperatorEndsWith: 'slutar med',
              filterOperatorIs: 'är',
              filterOperatorNot: 'är inte',
              filterOperatorAfter: 'är mer än',
              filterOperatorOnOrAfter: 'är lika med eller mer än',
              filterOperatorBefore: 'är mindre än',
              filterOperatorOnOrBefore: 'är lika med eller mindre än',
              filterOperatorIsEmpty: 'är tom',
              filterOperatorIsNotEmpty: 'är inte tom',
            
              // Filter values text
              filterValueAny: 'antingen',
              filterValueTrue: 'Ja',
              filterValueFalse: 'Nej',
            
              // Column menu text
              columnMenuLabel: 'Meny',
              columnMenuShowColumns: 'Visa kolumner',
              columnMenuFilter: 'Filtrera',
              columnMenuHideColumn: 'Dölj',
              columnMenuUnsort: 'Annulera sortering',
              columnMenuSortAsc: 'Sortera ökande',
              columnMenuSortDesc: 'Sortera minskande',
            
              // Column header text
              columnHeaderFiltersTooltipActive: (count) =>
                count > 1 ? `${count} activa filter` : `${count} aktivt filter`,
              columnHeaderFiltersLabel: 'Visa filter',
              columnHeaderSortIconLabel: 'Sorteren',
            
              // Rows selected footer text
              footerRowSelected: (count) =>
                count > 1
                  ? `${count.toLocaleString()} rader valda`
                  : `${count.toLocaleString()} rad vald`,
            
              // Total rows footer text
              footerTotalRows: 'Totalt:',
            
              // Total visible rows footer text
              footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} av ${totalCount.toLocaleString()}`,
            
              // Checkbox selection text
              checkboxSelectionHeaderName: 'Checkbox urval',
            
              // Boolean cell text
              booleanCellTrueLabel: 'Ja',
              booleanCellFalseLabel: 'Nej',
            
              // Actions cell more text
              actionsCellMore: 'mer',
            
              // Column pinning text
              pinToLeft: 'Sätta fast till vänster',
              pinToRight: 'Sätta fast till höger',
              unpin: 'Släppa loss',
            
              // Tree Data
              treeDataGroupingHeaderName: 'Gruppera',
              treeDataExpand: 'Expandera',
              treeDataCollapse: 'Dra samman',
            
              // Grouping columns
              groupingColumnHeaderName: 'Gruppera',
              groupColumn: name => `Gruppera på ${name}`,
              unGroupColumn: name => `Sluta gruppera på ${name}`,
          
          
            
          }
           );
          console.log("Lanugage should be changed to Swedish");
         } else {
          setHereLocaleText( 
            {
              noRowsLabel: 'No rows.',
              noResultsOverlayLabel: 'No results found.',
              errorOverlayDefaultLabel: 'An error has occured.',
            
              // Density selector toolbar button text
              toolbarDensity: 'Size',
              toolbarDensityLabel: 'Size',
              toolbarDensityCompact: 'Compact',
              toolbarDensityStandard: 'Normal',
              toolbarDensityComfortable: 'Large',
            
              // Columns selector toolbar button text
              toolbarColumns: 'Columns',
              toolbarColumnsLabel: 'Select columns',
            
              // Filters toolbar button text
              toolbarFilters: 'Filter',
              toolbarFiltersLabel: 'Show filter',
              toolbarFiltersTooltipHide: 'Hide filter',
              toolbarFiltersTooltipShow: 'Show filter',
              toolbarFiltersTooltipActive: (count) =>
                count > 1 ? `${count} aktive filters` : `${count} aktive filter`,
            
              // Export selector toolbar button text
              toolbarExport: 'Export',
              toolbarExportLabel: 'Export',
              toolbarExportCSV: 'Exportera to CSV',
              toolbarExportPrint: 'Print',
            
              // Columns panel text
              columnsPanelTextFieldLabel: 'Seek in column',
              columnsPanelTextFieldPlaceholder: 'Column title',
              columnsPanelDragIconLabel: 'Move column',
              columnsPanelShowAllButton: 'Show all',
              columnsPanelHideAllButton: 'Hide all',
            
              // Filter panel text
              filterPanelAddFilter: 'Add filter',
              filterPanelDeleteIconLabel: 'Remove',
              filterPanelOperators: 'Operator',
              filterPanelOperatorAnd: 'and',
              filterPanelOperatorOr: 'or',
              filterPanelColumns: 'Column',
              filterPanelInputLabel: 'Value',
              filterPanelInputPlaceholder: 'Filter value',
            
              // Filter operators text
              filterOperatorContains: 'contains',
              filterOperatorEquals: 'equal to',
              filterOperatorStartsWith: 'starts with',
              filterOperatorEndsWith: 'ends with',
              filterOperatorIs: 'is',
              filterOperatorNot: 'is not',
              filterOperatorAfter: 'is more than',
              filterOperatorOnOrAfter: 'is equal to or more than',
              filterOperatorBefore: 'is less than',
              filterOperatorOnOrBefore: 'is equal to or less than',
              filterOperatorIsEmpty: 'is empty',
              filterOperatorIsNotEmpty: 'is not empty',
            
              // Filter values text
              filterValueAny: 'either',
              filterValueTrue: 'Yes',
              filterValueFalse: 'No',
            
              // Column menu text
              columnMenuLabel: 'Menu',
              columnMenuShowColumns: 'Show columns',
              columnMenuFilter: 'Filter',
              columnMenuHideColumn: 'Hide',
              columnMenuUnsort: 'Annul sorting',
              columnMenuSortAsc: 'Sort ascending',
              columnMenuSortDesc: 'Sort descending',
            
              // Column header text
              columnHeaderFiltersTooltipActive: (count) =>
                count > 1 ? `${count} active filters` : `${count} aktive filter`,
              columnHeaderFiltersLabel: 'Show filter',
              columnHeaderSortIconLabel: 'Sort',
            
              // Rows selected footer text
              footerRowSelected: (count) =>
                count > 1
                  ? `${count.toLocaleString()} row selected`
                  : `${count.toLocaleString()} row selected`,
            
              // Total rows footer text
              footerTotalRows: 'Total:',
            
              // Total visible rows footer text
              footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} av ${totalCount.toLocaleString()}`,
            
              // Checkbox selection text
              checkboxSelectionHeaderName: 'Checkbox selection',
            
              // Boolean cell text
              booleanCellTrueLabel: 'Yes',
              booleanCellFalseLabel: 'No',
            
              // Actions cell more text
              actionsCellMore: 'more',
            
              // Column pinning text
              pinToLeft: 'Pin left',
              pinToRight: 'Pin right',
              unpin: 'Unpin',
            
              // Tree Data
              treeDataGroupingHeaderName: 'Group',
              treeDataExpand: 'Expand',
              treeDataCollapse: 'Pull together',
            
              // Grouping columns
              groupingColumnHeaderName: 'Group',
              groupColumn: name => `Group on ${name}`,
              unGroupColumn: name => `Ungroup on ${name}`,
          
          
            
         }
           ); 
          
           console.log("We keep English language");
         }



  }, []);

  return (<div>
    {bonusagreements.length === 0 ? 
    <div>
      
      {downloaded === "no" ?
        <div style={{height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Box >
          <CircularProgress /> 
          </Box>
        </div>
        : 
        <div style={{height: "150px", width: "100vw", paddingTop: "25px", marginTop: "25px", display: "flex", justifyContent: "center"}}>
            <CardDeck>
              <Card style={{ width: '18rem', justifyContent: "center" }} bg='light'>
                <Card.Body>
                  <Card.Title><Trans i18nKey="home.new-bonus-header"/></Card.Title>
                  <Card.Text>
                  <Trans i18nKey="home.new-bonus-text"/>
                  </Card.Text>  
                  <Link to="/editonebonus/new"><Button variant="primary"><Trans i18nKey="home.new-bonus-button"/></Button></Link>
                </Card.Body>
              </Card>
            </CardDeck>



        </div>
      }
      
    </div> 
    :
    <div>
      <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            localeText={ hereLocaleText }
            rows={bonusagreements}
            columns={columns}
            getRowId={(row) => row.bonusAgreementId}
            autoPageSize
            rowsPerPageOptions={[5,15,30,50,100]}
            pagination
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
    </div> 
    }
    

    </div>
  );
}