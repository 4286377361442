import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Redirect } from "react-router-dom";
import { authenticationService } from './_services/authentication.service';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Trans } from 'react-i18next';

class AdminDeleteUser extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        users: null,
        role: JSON.parse(localStorage.getItem('currentUser')).role, 
        token: JSON.parse(localStorage.getItem('currentUser')).token,
        id: JSON.parse(localStorage.getItem('currentUser')).id,
        redirect: false, 
        userName: null, 
        deletionmessage: ""
      }
    }

    componentDidMount() {
      if (this.state.role !== "Admin") { 
        authenticationService.logout();
        this.setState({redirect: true })
        
      };
    }


  handleChange = (e) => {
    const {name, value} = e.target;

    var New_userName = this.state.firstName; 

    if (name === "userName") { New_userName = value;}
  

    this.setState({
      userName: New_userName 
    });

  }
  
  handleSubmit = event => {
    event.preventDefault();
    this.postDeleteUser();

  };

  postDeleteUser(){ 

    var connectionstring = process.env.REACT_APP_apiUrl + '/identity/Users';

    trackPromise(
    axios.delete(connectionstring, 
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }, 
        data: {UserEmail: this.state.userName, AdminId: this.state.id}}
      )
        .then(res => {
          this.setState({
            deletionmessage: "The user " + this.state.userName + " was deleted", 
            userName: ""
          });
          }))
  };



  render () {

    if (this.state.redirect) {
      return <Redirect to={'/'} />
    }

  return (
  <div>
    <Layout>
      <LoadingSpinner/>
        <div>
        <br/>
        <h1>Admin Delete User</h1> 

        <Form onSubmit={this.handleSubmit}>
            Enter email (i.e. username) to delete <br /><br />
            <Form.Group ><Row><Col><Form.Label>Email</Form.Label></Col><Col><Form.Control type="value" name="userName" placeholder="Email" className="mr10" value={this.state.userName} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
            <Button variant="primary" type="submit" className="float-right">Submit</Button>
        </Form>
        {this.state.deletionmessage}
        <br /><br />
        <Link className="float-left" to={`/adminhome`}><Button  variant="secondary"><Trans i18nKey="navigation.return"/></Button></Link>

        </div>
    </Layout>
  </div>
  )

}
}

export default AdminDeleteUser;



