import React from 'react';
import axios from 'axios';
import { Layout } from './components/Layout';
import BonusTableSteps from './components/bonustablesteps';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";

class ViewSignedAgreement extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    isLoading: true,
    email: "",
    emailCode: "", 
    bonusAgreementId: "",
    name: "",
    onTargetReward: null,
    resultAmount: null,
    period: "",
    employeeUserName: "",
    managerUserName: "",
    employeeFullName: "",
    managerFullName: "",
    phase: "",
    kpi: [],
    data_bonuslevels: [],
    errors: null
  }
}


  // Now we're going to make a request for data using axios
  getBonusAgreement() {
    const connectionstringemail = process.env.REACT_APP_apiUrl + '/api/email/' + this.props.match.params.EmailCode + '/' + this.props.match.params.Email;

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstringemail)
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          email: this.state.email,
          emailCode: this.state.emailCode, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          currency: response.data.currency,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          language: response.data.language,
          phase: response.data.phase,
          kpi: response.data.kpi,
          translation: response.data.translationStringList,
          data_bonuslevels: [],
          isLoading: false
        });
        this.builddatatable();
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(state => {
        return {
          email: this.props.match.params.Email,
          emailCode: this.props.match.params.EmailCode, 
          bonusAgreementId: this.state.bonusAgreementId,
          name: this.state.name,
          onTargetReward: this.state.onTargetReward,
          resultAmount: this.state.resultAmount,
          period: this.state.period,
          employeeUserName: this.state.employeeUserName,
          managerUserName: this.state.managerUserName,
          employeeFullName: this.state.employeeFullName,
          managerFullName: this.state.managerFullName,
          phase: this.state.phase,
          kpi: this.state.kpi,
          data_bonuslevels: this.state.data_bonuslevels,
          isLoading: this.state.isLoading,
          errors: this.state.errors
        }
      });
      /*We should get the bonus agreementid */
    this.getBonusAgreement();
  };
 
  builddatatable(){
    var tableswithdata = {};
    tableswithdata = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      newkpi = [];
      for (var k=0; k<5; k++) {
        var newbonuslevel = {};
        newbonuslevel.tb_percentage = j*10;
        newbonuslevel.tb_level = parseInt(j)*100+parseInt(k);
        newbonuslevel.tb_levelaxis = "k SEK";
        newbonuslevel.tb_payout = j*k*100;
        newbonuslevel.tb_payoutaxis = "SEK";
        newkpi.push(newbonuslevel);
      }
      tableswithdata.push(newkpi);
    }


    this.setState(state => {
      return {
        email: this.state.email,
        emailCode: this.state.emailCode, 
        bonusAgreementId: this.state.bonusAgreementId,
        name: this.state.name,
        onTargetReward: this.state.onTargetReward,
        resultAmount: this.state.resultAmount,
        period: this.state.period,
        employeeUserName: this.state.employeeUserName,
        managerUserName: this.state.managerUserName,
        employeeFullName: this.state.employeeFullName,
        managerFullName: this.state.managerFullName,
        phase: this.state.phase,
        kpi: this.state.kpi,
        data_bonuslevels: tableswithdata,
      }
    })
    this.forceUpdate();
  };



  // Putting that data to use
  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }

    return (
      <React.Fragment>
        <Layout>

        <br />
        <h2>{this.state.translation.string000}</h2>
        <i>{this.state.translation.string016}</i>
        <br /><br />
        <LoadingSpinner/>
        <h4 class="card-title">{this.state.name} - {this.state.employeeFullName}</h4> 
        <h6 class="card-subtitle mb-2 text-muted">{this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}</h6>
       
         <br />          

        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        <p class="card-text">{kpi.description}. {this.state.translation.string003}: {kpi.percentageOfTotalReward}% </p>
                        
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} language={this.state.language}></BonusTableSteps>
                        <br />
                        </div>
                        
                        )
                    })}
        <br />

        <br /><br />
        {/*
      Email code: {this.props.match.params.EmailCode} <br />
      Email: {this.props.match.params.Email}
        */}
        
       </Layout>
      </React.Fragment>
    );
  }
}

export default ViewSignedAgreement;