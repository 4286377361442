import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Redirect } from "react-router-dom";
import { Trans } from 'react-i18next';
import i18n from './i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faEdit  } from '@fortawesome/free-solid-svg-icons';
import HelpVideo from './components/HelpVideo';

const renderTooltipOnTargetReward = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.on-target-reward"/>
  </Tooltip>
);

const renderTooltipCurrency = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.currency"/>
  </Tooltip>
);


const renderTooltipApprovalOrder = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.approval-order"/>
  </Tooltip>
);

const renderTooltipAddApprover = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.add-approver"/>
  </Tooltip>
);

const renderTooltipAddKPI = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.add-kpi"/>
  </Tooltip>
);

const renderTooltipEditKPIStep = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.edit-kpisteps"/>
  </Tooltip>
);

const renderTooltipKPIType = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.overwrite-kpi-by-default"/>
  </Tooltip>
);

const renderTooltipBonusWhen = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.bonus-when"/>
  </Tooltip>
);

const renderTooltipUnit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.unit"/>
  </Tooltip>
);


const renderTooltipPercentageOfTotalReward = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <Trans i18nKey="tooltip.percentage-of-total-reward"/>
  </Tooltip>
);


class EditOneBonus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true, 
      redirect: null,
      redirecttype: 'save',
      redirectkpiindex: '0',
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      formIsvalid: true,
      new: true,
      finishedloading: false,
      languageoption1: 'English',
      languageoption2: 'Swedish',
      language: 'en',
      languagetext: 'English',
      bonusAgreementId: "", 
      name: "", 
      onTargetReward: 0, 
      resultAmount: 0, 
      period: "",  
      employeeUserName: "", 
      managerUserName: JSON.parse(localStorage.getItem('currentUser')).userName,
      employeeFullName: "", 
      managerFullName: JSON.parse(localStorage.getItem('currentUser')).fullName, 
      employeeApprovalLevel: 1, 
      managerApprovalLevel: 1,
      phase: "Draft",
      currency: "",
      errorName: "",
      errorOnTargetReward: "",
      errorResultAmount: "",
      errorPeriod: "",
      errorEmployeeUserName: "",
      errorEmployeeFullName: "",
      errorEmployeeApprovalLevel: "",
      errorManagerApprovalLevel: "",
      errorPhase: "",
      errorCurrency: "",
      approval: [],
      kpi: [
        { 
          kpiId: 0,
          bonusAgreementId: "",
          name: "",
          description: "", 
          type: "", 
          goal: 0, 
          result: 0,  
          resultPercentage: 0, 
          percentageOfTotalReward:0, 
          onTargetReward: 0,
          resultAmount: 0,
          unit: "",
          errorName: "",
          errorDescription: "",
          errorType: "",
          errorGoal: "",
          errorResult: "",
          errorResultPercentage: "",
          errorPercentageOfTotalReward: "",
          errorTargetReward: "",
          errorResultAmount: "", 
          errorUnit: ""
        }          
      ],
    };
  }
 
setTranslations() {
    
}



 makeNewBonusAgreement(){
   /* Everything is already set. We don't need to do anything. Here we only seed data for test purposes */

   this.setState({
    token: this.state.token,
    new: true,
    finishedloading: true, 
    bonusAgreementId: '',
    name: "FY2044",
    onTargetReward: 2000,
    resultAmount: 0,
    period: 'Year 2044',
    employeeUserName: 'workie@workie.com',
    managerUserName: this.state.managerUserName,
    employeeFullName: 'Workie work',
    managerFullName: this.state.managerFullName,
    employeeApprovalLevel: this.state.employeeApprovalLevel,
    managerApprovalLevel: this.state.managerApprovalLevel,
    phase: this.state.phase,
    currency: 'SEK',
    usedFor: "User",
    approval: this.state.approval,
    kpi: [
      { 
        name: "Sales",
        description: "Sales in Greece", 
        type: "Sales", 
        goal: 4000, 
        result: 0,  
        resultPercentage: 0, 
        percentageOfTotalReward:100, 
        onTargetReward: 210,
        resultAmount: 0,
        unit: 'SEK', 
        usedFor: 'User',
        direction: 'Up',
        managerUserName: this.state.managerUserName,
        sortOrder: 1
      },
    ],
  });
 }

 populateApprovalAndLanguageAfterGet(languagecode) {
  // The Manager and Employee Approvals can not be removed. Therefore, they are not in the approval list
  var newapprovals = [];
  var newapproval = {};
  for (var i=0; i<this.state.approval.length; i++) {
    if (i > 1) {
    newapproval.bonusAgreementId = this.state.bonusAgreementId; 
    newapproval.employeeEmail = this.state.employeeUserName;
    newapproval.employeeFullName = this.state.employeeFullName;
    newapproval.email = this.state.approval[i].email;
    newapproval.fullName = this.state.approval[i].fullName;
    newapproval.approvalLevel = parseInt(this.state.approval[i].approvalLevel);
    newapprovals.push(newapproval);
    }
  }

  var languagetext, languageoption1, languageoption2;

  /* Languagecode is language at bonus agreement */
  /* i18nextLng is logged in user language */

  if (window.localStorage.i18nextLng === "se") {
    languageoption1 = 'Engelska';
    languageoption2 = 'Svenska'; 

    if (languagecode === 'se') { languagetext = "Svenska"; } else {languagetext = "Engelska"}


  } else {  
    languageoption1 = 'English';
    languageoption2 = 'Swedish'; 

    if (languagecode === 'se') { languagetext = "Swedish"; } else {languagetext = "English"}
  };

  this.setState(state => { 
    return {
      approval: newapprovals,
      languagetext: languagetext, 
      languageoption1: languageoption1,
      languageoption2: languageoption2,
      language: languagecode
   }})

   this.populateSelectedKpi();
 };

setNewEmptyBonusAgreement() {


}

getNewBonusAgreement() {

  var connectionstring = process.env.REACT_APP_apiUrl + '/api/bonuskpiapproval/' + this.state.managerUserName + '/dy';


  trackPromise(
  axios
    // This is where the data is hosted
    .get(connectionstring
    ,
    {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
    // Once we get a response and store data, let's change the loading state
    .then(response => {
      if (process.env.NODE_ENV === 'development') {
        this.setState({
          new: true,
          finishedloading: true, 
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          employeeApprovalLevel: response.data.employeeApprovalLevel,
          managerApprovalLevel: response.data.managerApprovalLevel,
          phase: response.data.phase,
          currency: response.data.currency,
          usedFor: response.data.usedFor,
          language: response.data.language,
          companyId: response.data.companyId,
          translationStringList: response.data.translationStringList,
          kpi: response.data.kpi,
          kpiType: response.data.kpiType,
          isLoading: false
        })

      } else {
        this.setState({
          finishedloading: true, 
          bonusAgreementId: response.data.bonusAgreementId,
          name: '',
          onTargetReward: 0,
          resultAmount: 0,
          period: '',
          employeeUserName: '',
          managerUserName: response.data.managerUserName,
          employeeFullName: '',
          managerFullName: response.data.managerFullName,
          employeeApprovalLevel: 1,
          managerApprovalLevel: 1,
          phase: response.data.phase,
          currency: '',
          usedFor: response.data.usedFor,
          language: response.data.language,
          companyId: response.data.companyId,
          translationStringList: response.data.translationStringList,
          kpi: response.data.kpi,
          kpiType: response.data.kpiType,
          isLoading: false
         })};
     this.populateApprovalAndLanguageAfterGet(response.data.language);
    })
    .catch(error => this.setState({ error })));
};

  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpiapproval/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          new: false,
          finishedloading: true, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          employeeApprovalLevel: response.data.employeeApprovalLevel,
          managerApprovalLevel: response.data.managerApprovalLevel,
          phase: response.data.phase,
          currency: response.data.currency,
          usedFor: response.data.usedFor,
          language: response.data.language,
          companyId: response.data.companyId,
          translationStringList: response.data.translationStringList,
          approval: response.data.approval,
          kpi: response.data.kpi,
          kpiType: response.data.kpiType,
          isLoading: false
        });
        this.populateApprovalAndLanguageAfterGet(response.data.language);

      })
      // If we catch any errors connecting, let's update accordingly
      .catch(
        error => this.setState({ error, isLoading: false }))
       );
  };
  

  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    
    /* if no subscription - just redirect */
    if (JSON.parse(localStorage.getItem('currentUser')).validSubscription !== "yes") {
        this.setState(state => {
          return {
          redirect: '/order'
        };
        });
      } else {

        if (JSON.parse(localStorage.getItem('currentUser')).language === 'se') {
          i18n.changeLanguage('se');
        }
    
        if (JSON.parse(localStorage.getItem('currentUser')).language === 'en') {
          i18n.changeLanguage('en');
        }

        this.setTranslations();

        var bonusid = this.props.match.params.bonusAgreementId;
        if (bonusid === 'new') { 
          /* this.makeNewBonusAgreement();*/
          this.getNewBonusAgreement();
        } else {
            this.getBonusAgreement();
        }
    }
  };

  populateSelectedKpi()
  {
    const list = [...this.state.kpi];

   var i ;
   for(i=0; i < list.length; i++){
     if (list[i].direction === "Up") { 
         list[i].directionvalue = i18n.t('kpi.result-more-than-target');
       }
     if (list[i].direction === "Down") { 
       list[i].directionvalue = i18n.t('kpi.result-less-than-target');
     }

     this.setState({kpi: list})
   }
  }

  setlanguagechangetexts() {
    var englishtext = 'English';
    var swedishtext = 'Swedish'

    if (window.localStorage.i18nextLng === 'se') {
      englishtext = 'Engelska';
      swedishtext = 'Svenska'
    }
    
    this.setState({      
      englishtext: englishtext,
      swedishtext: swedishtext
    });
  }

  handleApprovalChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.state.approval];

    if (name === "email") {
      list[index][name]=value;
      list[index]["errorEmail"]="";
    }

    if (name === "fullName") {
      list[index][name]=value;
      list[index]["errorFullName"]="";
    }

    if (name === 'approvalLevel') { list[index][name]=this.correctapprovallevel(value) }
    else { list[index][name]=value; } 

    if (name === "approvalLevel") {
      list[index]["errorApprovalLevel"]="";
    }

    this.setState({      
      approval: list
    });

  }


  handleKpiChangefromFunction = (kpiindex, sendname, sendvalue) => {
    const list = [...this.state.kpi];
    list[kpiindex][sendname]=sendvalue;
  }


 handleKpiChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.state.kpi];

    if (name === "name") {
      list[index][name]=value;
      list[index]["errorName"]="";
    }

    if (name === "description") {
      list[index][name]=value;
      list[index]["errorDescription"]="";
    }

    if (name === "direction") {
      list[index]["directionvalue"]=value;
      if (value === i18n.t('kpi.result-more-than-target')) { 
        list[index]["direction"]="Up";
      }
      if (value === i18n.t('kpi.result-less-than-target')) { 
        list[index]["direction"]="Down";
      }
      list[index]["errorType"]="";
    }

    if (name === "type") {
      list[index][name]=value;
      const typelist = [...this.state.kpiType];
      var j;
      for (j = 0; j < typelist.length; j++) {
        if (typelist[j].typeName === value)
        {
          list[index]["direction"]=typelist[j].typeDirection;
          if (typelist[j].typeDirection === "Up") { 
            list[index]["directionvalue"]=i18n.t('kpi.result-more-than-target');
          }
          if (typelist[j].typeDirection === "Down") { 
            list[index]["directionvalue"]=i18n.t('kpi.result-less-than-target');
          }

          list[index]["name"] = typelist[j].typeName;
          list[index]["description"] = typelist[j].typeDescription;
          list[index]["unit"] = typelist[j].typeUnit;
          list[index]["goal"] = typelist[j].typeGoal;


        }
        /* Reset errors */
        list[index]["errorName"]="";
        list[index]["errorDescription"]="";
        list[index]["errorGoal"]="";
      }

      list[index]["errorType"]="";
    }

    if (name === "goal") { 
        if (this.validateNumber(value)) {
          list[index][name]=value;
          list[index]["errorGoal"]="";
        } else {
          list[index]["errorGoal"]=i18n.t('error-kpi.goal-not-a-number');
        }
     }

    if (name === "percentageOfTotalReward") {
      if (this.validateNumber(value)) {
        list[index][name]=value;
        list[index]["errorPercentageOfTotalReward"]="";
      } else {
        list[index]["errorPercentageOfTotalReward"]=i18n.t('error-kpi.percentage-of-total-should-be-number');
      }


      
    }

    if (name === "targetReward") {
      list[index][name]=value;
      list[index]["errorTargetReward"]="";
    }

    if (name === "resultAmount") {
      list[index][name]=value;
      list[index]["errorResultAmount"]="";
    }

    if (name === "unit") {
      list[index][name]=value;
      list[index]["errorUnit"]="";
    }

    this.setState({      
      kpi: list
    });

  }

  correctapprovallevel(newvalue) {
    var returnnumber;
    var numberofapprovers;
    numberofapprovers = this.state.approval.length + 2;
    if (this.validateNumber(newvalue)) {
      returnnumber = newvalue;
      if (parseInt(newvalue) < 1) { returnnumber = 1 };
      if (parseInt(newvalue) > numberofapprovers)  { 
        returnnumber = 1 
         };
    } else {
      /* the value is not a number. Return 1 */ 
      returnnumber = 1;
    }
    return returnnumber;
  }


  handleLanguageChange = (e) => {
    // eslint-disable-next-line
      const {name, value} = e.target;

      var language;


      if ((value === 'English') || (value === 'Engelska')) { 
        language = 'en';
      }
      if ((value === 'Swedish') || (value === 'Svenska')) { 
        language = 'se'; 
      }

      this.setState(state => {
        return {
          languagetext: value,
          language: language
       };
     });


  }




  handleBonusChange = (e) => {
    const {name, value} = e.target;

    var New_bonusAgreementId = this.state.bonusAgreementId; 
    var New_name = this.state.name; 
    var New_onTargetReward;
    if (isNaN(parseFloat(this.state.onTargetReward))) {
      New_onTargetReward = "";
    } else { New_onTargetReward = parseFloat(this.state.onTargetReward); }
    var New_resultAmount = parseFloat(this.state.resultAmount); 
    var New_period = this.state.period;  
    var New_employeeUserName = this.state.employeeUserName; 
    var New_managerUserName = this.state.managerUserName; 
    var New_employeeFullName = this.state.employeeFullName; 
    var New_managerFullName = this.state.managerFullName; 
    var New_employeeApprovalLevel = this.state.employeeApprovalLevel; 
    var New_managerApprovalLevel = this.state.managerApprovalLevel; 
    var New_phase = this.state.phase;
    var New_currency = this.state.currency;

    if (name === "bonusAgreementId") { New_bonusAgreementId = value; }
    if (name === "name") { New_name = value; this.setState(state => { return { errorName: "" }})}
    if (name === "onTargetReward") { 
      if (this.validateNumber(value)) {
        New_onTargetReward = value;
        this.setState(state => { return { errorOnTargetReward: "" }})
      } else {
        this.setState(state => { return { errorOnTargetReward: i18n.t('error-bonus.on-target-reward-should-be-number') }})
      }
    }
    if (name === "resultAmount") { New_resultAmount = value; this.setState(state => { return { errorResultAmount: "" }})}
    if (name === "period") { New_period = value; this.setState(state => { return { errorPeriod: "" }})}
    if (name === "employeeUserName") { New_employeeUserName = value; this.setState(state => { return { errorEmployeeUserName: "" }})}
    if (name === "managerUserName") { New_managerUserName = value; this.setState(state => { return { errorManagerUserName: "" }})}
    if (name === "employeeFullName") { New_employeeFullName = value; this.setState(state => { return { errorEmployeeFullName: "" }})}
    if (name === "managerFullName") { New_managerFullName = value; this.setState(state => { return { errorManagerFullName: "" }})}
    if (name === "employeeApprovalLevel") {  New_employeeApprovalLevel = this.correctapprovallevel(value); this.setState(state => { return { errorEmployeeApprovalLevel: "" }})
    }
    if (name === "managerApprovalLevel") { New_managerApprovalLevel = this.correctapprovallevel(value); this.setState(state => { return { errorManagerApprovalLevel: "" }})}
    if (name === "phase") { New_phase = value; this.setState(state => { return { errorPhase: "" }})}
    if (name === "currency") { New_currency = value; this.setState(state => { return { errorCurrency: "" }})}

    this.setState({
      bonusAgreementId: New_bonusAgreementId, 
      name: New_name, 
      onTargetReward: New_onTargetReward, 
      resultAmount: New_resultAmount, 
      period: New_period,  
      employeeUserName: New_employeeUserName, 
      managerUserName: New_managerUserName, 
      employeeFullName: New_employeeFullName, 
      managerFullName: New_managerFullName, 
      employeeApprovalLevel: New_employeeApprovalLevel, 
      managerApprovalLevel: New_managerApprovalLevel,
      phase: New_phase,
      currency: New_currency,
    });

  }

  handleAddApproval = () => {
      const list = [...this.state.approval];

      const newapproval = {};
      newapproval.email = "";
      newapproval.fullName = "";
      newapproval.approvalLevel = 1;
      list.push(newapproval);

      this.setState(state => {
        return {
          token: this.state.token, 
          new: this.state.new,
          finishedloading: true,
          bonusAgreementId: this.state.bonusAgreementId, 
          name: this.state.name, 
          onTargetReward: this.state.onTargetReward, 
          resultAmount: this.state.resultAmount, 
          period: this.state.period,  
          employeeUserName: this.state.employeeUserName, 
          managerUserName: this.state.managerUserName, 
          employeeFullName: this.state.employeeFullName, 
          managerFullName: this.state.managerFullName, 
          employeeApprovalLevel: this.state.employeeApprovalLevel, 
          managerApprovalLevel: this.state.managerApprovalLevel,
          phase: this.state.phase,
          approval: list,
          kpi: this.state.kpi,
       };
     });

  }

  handleRemoveApproval = index => {
    const list = [...this.state.approval];
    list.splice(index,1);

    this.setState(state => {
       return {
        token: this.state.token, 
        new: this.state.new,
        finishedloading: this.state.finishedloading,
        bonusAgreementId: this.state.bonusAgreementId, 
        name: this.state.name, 
        onTargetReward: this.state.onTargetReward, 
        resultAmount: this.state.resultAmount, 
        period: this.state.period,  
        employeeUserName: this.state.employeeUserName, 
        managerUserName: this.state.managerUserName, 
        employeeFullName: this.state.employeeFullName, 
        managerFullName: this.state.managerFullName, 
        employeeApprovalLevel: this.correctapprovallevel(this.state.employeeApprovalLevel), 
        managerApprovalLevel: this.correctapprovallevel(this.state.managerApprovalLevel),
        phase: this.state.phase,
        approval: list,
        kpi: this.state.kpi,
      };
    });

  }
  

 handleAddInput = () => {
      const list = [...this.state.kpi];

      const newkpi = {};
      newkpi.kpiId = 0;
      newkpi.bonusAgreementId = this.state.bonusAgreementId;
      newkpi.name = "";
      newkpi.description = "";
      newkpi.type = "select-type";
      newkpi.goal = 0;
      newkpi.result = 0;
      newkpi.resultPercentage = 0;
      newkpi.percentageOfTotalReward = 0;
      newkpi.onTargetReward = 0;
      newkpi.resultAmount = 0;
      newkpi.unit = "";
      newkpi.usedFor = "User";
      newkpi.direction = "Up";
      newkpi.managerUserName = this.state.managerUserName;
      newkpi.sortOrder = this.state.kpi.length + 1;
      newkpi.lowCutOff = 75;
      newkpi.highCutOff = 150;
      newkpi.numberOfKpiSteps = 10;
      newkpi.scaleUnderGoal = "scale-1-1";
      newkpi.scaleOverGoal = "scale-1-1";

      list.push(newkpi);

      var usedpercentageOfTotalReward = 0;
      for (var j=0; j<list.length; j++) {
        if ((j > (list.length - 1)) || (j === list.length - 1)) {
          list[j]["percentageOfTotalReward"] = 100 - usedpercentageOfTotalReward;
        } else {
          list[j]["percentageOfTotalReward"] = Math.round(100/list.length);
          usedpercentageOfTotalReward = usedpercentageOfTotalReward + list[j]["percentageOfTotalReward"];
        }
      }

      this.setState(state => {
        return {
          kpi: list,
       };
     });
  }

  handleRemoveInput = index => {
    const list = [...this.state.kpi];
    list.splice(index,1);

    var usedpercentageOfTotalReward = 0;
    for (var j=0; j<list.length; j++) {
      if ((j > (list.length - 1)) || (j === list.length - 1)) {
        list[j]["percentageOfTotalReward"] = 100 - usedpercentageOfTotalReward;
      } else {
        list[j]["percentageOfTotalReward"] = Math.round(100/list.length);
        usedpercentageOfTotalReward = usedpercentageOfTotalReward + list[j]["percentageOfTotalReward"];
      }
    }

    this.setState(state => {
       return {
        kpi: list,
      };
    });

  }

  handleEditLevels = index => {

    this.setState(state => {
       return {
        redirecttype: 'editkpistep',
        redirectkpiindex: index,
      };
    });

    this.postbonusagreement();

  }

 
  validateEmail(email) {
    // eslint-disable-next-line
    var emailregex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailregex.test(email);
  }

  validateNumber(numberstring) {
    // eslint-disable-next-line
    var emailregex = /^[0-9]*$/gm;
    return emailregex.test(numberstring);
  } 

  reseterrors() {
    this.setState(state => { return {
      formIsvalid: true,
      errorName: "",
      errorOnTargetReward: "",
      errorResultAmount: "",
      errorPeriod: "",
      errorEmployeeUserName: "",
      errorEmployeeFullName: "",
      errorPhase: "",
      errorCurrency: "",
    }});

  }

  validatebonusagreement() {
   /* this.reseterrors();*/
    var formisvalid = true;
    const approvallist = [...this.state.approval];
    const kpilist = [...this.state.kpi];

    if (this.state.name === "") {
      this.setState(state => { return { errorName: i18n.t('error-bonus.no-bonus-name') }});
      formisvalid = false;
    }

    if (isNaN(this.state.onTargetReward)) {
      this.setState(state => { return { errorOnTargetReward: i18n.t('error-bonus.on-target-reward-not-valid') }});
      formisvalid = false;
    } else {
     /* Target reward is a number */
    }
    
    if ((this.state.onTargetReward === 0) || (this.state.onTargetReward < 0.00001))  {
      this.setState(state => { return { errorOnTargetReward: i18n.t('error-bonus.on-target-reward-should-be-number') }});
      formisvalid = false;

    }

    if (this.state.period === "" ) {
      this.setState(state => { return { errorPeriod: i18n.t('error-bonus.no-period') }});
      formisvalid = false;
    }

    if (this.state.employeeFullName === "") {
      this.setState(state => { return { errorEmployeeFullName: i18n.t('error-bonus.no-employee-name') }});
      formisvalid = false;
    }

    if (this.state.employeeUserName === "" ) 
    {
      this.setState(state => { return { errorEmployeeUserName: i18n.t('error-bonus.no-employee-email') }});
      formisvalid = false;
    } 
    else 
    {
      if (!this.validateEmail(this.state.employeeUserName)) 
      {
        this.setState(state => { return { errorEmployeeUserName: i18n.t('error-bonus.employee-email-incorrect') }});
        formisvalid = false;
      } 
    }

    if (this.state.employeeApprovalLevel === "") {
      this.setState(state => { return { errorEmployeeApprovalLevel: i18n.t('error-bonus.no-employee-approval-order') }});
      formisvalid = false;
    }

    if (this.state.managerApprovalLevel === "") {
      this.setState(state => { return { errorManagerApprovalLevel: i18n.t('error-bonus.no-manager-approval-order') }});
      formisvalid = false;
    }

    if (this.state.phase === "" ) {
      this.setState(state => { return { errorPhase: "You need to set the phase" }});
      formisvalid = false;
    }

    if (this.state.currency === "" ) {
        this.setState(state => { return { errorCurrency: i18n.t('error-bonus.no-currency') }});
        formisvalid = false;
    }

    for (var k=0; k<this.state.approval.length; k++) {

      if  (this.state.approval[k].fullName === "") { 
        approvallist[k].errorFullName = i18n.t('error-approval.no-name');
        formisvalid = false;
      }

      if (!this.validateEmail(this.state.approval[k].email)) 
      {
        approvallist[k].errorEmail = i18n.t('error-approval.no-email');
        formisvalid = false;
      } 

      if (isNaN(this.state.approval[k].approvalLevel)  || this.state.approval[k].approvalLevel === "") {
        approvallist[k].errorApprovalLevel = i18n.t('error-approval.no-approval-order');
        formisvalid = false;
      }
    }

    var kpipercentage = 0.0;
    for (var i=0; i<this.state.kpi.length; i++) {
      kpipercentage = parseFloat(kpipercentage) + parseFloat(this.state.kpi[i].percentageOfTotalReward);


      if  (this.state.kpi[i].name === "") { 
        kpilist[i].errorName = i18n.t('error-kpi.no-name');
        formisvalid = false;
      }


      if  (this.state.kpi[i].goal === 0 || this.state.kpi[i].goal === "") { 
        kpilist[i].errorGoal = i18n.t('error-kpi.no-goal');
        formisvalid = false;
      }

      if  (this.state.kpi[i].unit === "") { 
        kpilist[i].errorUnit = i18n.t('error-kpi.no-unit');
        formisvalid = false;
      }

    }

    if (kpipercentage !== 100 ) {
      formisvalid = false;
      for (i=0; i<this.state.kpi.length; i++) {
        kpilist[i].errorPercentageOfTotalReward = i18n.t('error-kpi.percentage-of-total-reward-wrong');;
      }
    }

    if (formisvalid === true) {
         this.setState(state => { return { formIsvalid: true  }}); 
     } else {
         this.setState(state => { return { formIsvalid: false  }}); 
    }


    this.setState({      
      approval: approvallist,
      kpi: kpilist
    });

    return formisvalid;
  };

  posttracking(){

    var Tracker;
    Tracker.Page = "EditOneBonus";
    Tracker.Action = "Save Bonus Agreement";

    var connectionstringtrack = process.env.REACT_APP_apiUrl + '/api/track'

      axios.post(connectionstringtrack, Tracker
          ,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
          .then(res => {
            /* We can do something */
           })
  }


  postbonusagreement(){ 


    var sendformisvalid = false;
    sendformisvalid = this.validatebonusagreement();

    const model = {};
    if (this.state.new) { model.BonusAgreementId = 0 }
    else {model.BonusAgreementId = this.state.bonusAgreementId; }
    model.Name = this.state.name; 
    model.OnTargetReward = parseFloat(this.state.onTargetReward);
    model.ResultAmount = parseFloat(this.state.resultAmount);
    model.Period = this.state.period;
    model.EmployeeUserName = this.state.employeeUserName;
    model.ManagerUserName = this.state.managerUserName;
    model.EmployeeFullName = this.state.employeeFullName;
    model.ManagerFullName = this.state.managerFullName;
    model.EmployeeApprovalLevel = parseInt(this.state.employeeApprovalLevel); 
    model.ManagerApprovalLevel = parseInt(this.state.managerApprovalLevel);
    model.Phase = "Draft";
    model.Currency = this.state.currency;
    model.UsedFor = this.state.usedFor;
    model.Language = this.state.language;
    model.CompanyId = this.state.companyId;
  


    model.Approval = [];
    var newapproval = {};
    for (var i=0; i<this.state.approval.length; i++) {
      if (this.state.new) { newapproval.BonusAgreementId = 0 }
      else {newapproval.BonusAgreementId = this.state.bonusAgreementId; }
      newapproval.EmployeeEmail = this.state.employeeUserName;
      newapproval.EmployeeFullName = this.state.employeeFullName;
      newapproval.Email = this.state.approval[i].email;
      newapproval.FullName = this.state.approval[i].fullName;
      newapproval.ApprovalLevel = parseInt(this.state.approval[i].approvalLevel);
      model.Approval.push(newapproval);
    }

    model.Kpi = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      if (this.state.new) {  newkpi.KpiId = 0 } else { newkpi.KpiId = this.state.kpi[j].kpiId; }
      newkpi.Name = this.state.kpi[j].name;
      if (this.state.new) { newkpi.BonusAgreementId = 0 } else {newkpi.BonusAgreementId = this.state.bonusAgreementId; }
      newkpi.Description = this.state.kpi[j].description;
      newkpi.Type = this.state.kpi[j].type;
      newkpi.Goal = parseInt(this.state.kpi[j].goal);
      newkpi.Result = parseInt(this.state.kpi[j].result);
      newkpi.ResultPercentage = parseFloat(this.state.kpi[j].resultPercentage);
      newkpi.PercentageOfTotalReward = parseFloat(this.state.kpi[j].percentageOfTotalReward);
      newkpi.OnTargetReward = parseInt(this.state.kpi[j].onTargetReward);
      newkpi.Unit = this.state.kpi[j].unit;
      newkpi.UsedFor = this.state.kpi[j].usedFor;
      newkpi.Direction = this.state.kpi[j].direction;
      newkpi.Currency = this.state.currency;
      newkpi.ManagerUserName = this.state.managerUserName;
      newkpi.SortOrder = parseInt(this.state.kpi[j].sortOrder);
      newkpi.LowCutOff = this.state.kpi[j].lowCutOff;
      newkpi.HighCutOff = this.state.kpi[j].highCutOff;
      newkpi.NumberOfKpiSteps = this.state.kpi[j].numberOfKpiSteps;
      newkpi.ScaleUnderGoal = this.state.kpi[j].scaleUnderGoal;
      newkpi.ScaleOverGoal = this.state.kpi[j].scaleOverGoal;

      model.Kpi.push(newkpi);
    }
    /* Tracker can be added, but slow
    model.Tracker = [];
    var newtracker = {};
    newtracker.UserName = this.state.managerUserName;
    newtracker.Action = "Save Bonus Agremeent - " + this.state.name;
    newtracker.Page = "EditOneBonus";
    newtracker.Site = process.env.REACT_APP_apiUrl;
    model.Tracker.push(newtracker)*/

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var poststring = '/api/bonuskpiapproval';
    const connectionstringpost = connectionstring1.concat("" , poststring);

    console.log(connectionstringpost);
    console.log(BonusAgreement);

   /* sendformisvalid = false; */

    if (sendformisvalid) {

    trackPromise(
    axios.post(connectionstringpost, BonusAgreement
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          /*this.posttracking();*/
          this.setState({
            bonusAgreementId: res.data.bonusAgreementId,
            kpi: res.data.kpi
          });
          if (this.state.redirecttype === 'save') {
            /* this.props.history.push('/BonusAgreements'); */ 
            const redirectpage = '/Sign/' + this.state.bonusAgreementId;
            this.setState({
              redirect: redirectpage
            });
          } else {
              const redirectpage = '/EditKpiTableSteps/' + this.state.kpi[this.state.redirectkpiindex].kpiId + '/EditOneBonus/' + this.state.bonusAgreementId
              this.setState({
                redirect: redirectpage
              });
            }
        }))
        .catch(error => console.log(error));
  }};



  handleSubmit = event => {
    event.preventDefault();
    this.postbonusagreement();

  };


  
  
  render () {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { isLoading } = this.state;

    if (isLoading) {
      return <div><div className="EditOneBonus"><LoadingSpinner/></div>
      </div>;
    }

  return (
    <Layout>
      <LoadingSpinner/>

      

      <br/>
      
     

      <Form onSubmit={this.handleSubmit}>

      <Form.Group ><Row><Col><h3><Trans i18nKey="bonus.edit"/></h3></Col><Col><HelpVideo videoName={'getting-started'} ></HelpVideo></Col></Row></Form.Group>


        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.name"/></Form.Label></Col><Col><Form.Control type="text" name="name" placeholder={i18n.t('bonus.name')} className="mr10" value={this.state.name} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorName}</div></Col></Row></Form.Group> } 
        

        <Form.Group ><Row><Col>
        <OverlayTrigger
            placement="right"
            overlay={renderTooltipOnTargetReward}
          >
        <Form.Label><Trans i18nKey="bonus.on-target-reward"/></Form.Label>
        </OverlayTrigger>
        </Col><Col><Form.Control type="value" name="onTargetReward" placeholder={i18n.t('bonus.on-target-reward-in-set-currency')} className="mr10" value={this.state.onTargetReward} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorOnTargetReward !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorOnTargetReward}</div></Col></Row></Form.Group> } 
        
        <Form.Group ><Row><Col>
        <OverlayTrigger
            placement="right"
            overlay={renderTooltipCurrency}
          >
        <Form.Label><Trans i18nKey="bonus.currency"/></Form.Label>
        </OverlayTrigger>
        </Col><Col><Form.Control type="value" name="currency" placeholder={i18n.t('bonus.currency')} className="mr10" value={this.state.currency} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorCurrency !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorCurrency}</div></Col></Row></Form.Group> } 
        
        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.period"/></Form.Label></Col><Col><Form.Control type="value" name="period" placeholder={i18n.t('bonus.period')} className="mr10" value={this.state.period} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorPeriod !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorPeriod}</div></Col></Row></Form.Group> } 
        
        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.language"/> </Form.Label></Col><Col><Form.Control as="select" type="text" name="type" placeholder="type" className="mr10" value={this.state.languagetext} onChange={e => this.handleLanguageChange(e)}>
                  <option>{this.state.languageoption1}</option>
                  <option>{this.state.languageoption2}</option>
        </Form.Control></Col></Row></Form.Group>

        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.employee-name"/>  </Form.Label></Col><Col><Form.Control type="value" name="employeeFullName" placeholder={i18n.t('bonus.employee-name')} className="mr10" value={this.state.employeeFullName} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorEmployeeFullName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorEmployeeFullName}</div></Col></Row></Form.Group> } 

        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.employee-email"/>  </Form.Label></Col><Col><Form.Control type="value" name="employeeUserName" placeholder={i18n.t('bonus.employee-email')} className="mr10" value={this.state.employeeUserName} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorEmployeeUserName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorEmployeeUserName}</div></Col></Row></Form.Group> } 

        <Form.Group ><Row><Col>
        <OverlayTrigger
            placement="right"
            overlay={renderTooltipApprovalOrder}
          >
        <Form.Label><Trans i18nKey="bonus.employee-approval-order"/>  </Form.Label>
        </OverlayTrigger>

        </Col><Col><Form.Control type="value" name="employeeApprovalLevel" placeholder={i18n.t('bonus.employee-approval-order')} className="mr10" value={this.state.employeeApprovalLevel} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorEmployeeApprovalLevel !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorEmployeeApprovalLevel}</div></Col></Row></Form.Group> } 

        <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.manager-approval-order"/>  </Form.Label></Col><Col><Form.Control type="value" name="managerApprovalLevel" placeholder={i18n.t('bonus.manager-approval-order')} className="mr10" value={this.state.managerApprovalLevel} onChange={e => this.handleBonusChange(e)}></Form.Control></Col></Row></Form.Group>
        {this.state.errorManagerApprovalLevel !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorManagerApprovalLevel}</div></Col></Row></Form.Group> } 


      <br />
      
 
 

      <Trans i18nKey="approval.additional"/>  
      <OverlayTrigger
            placement="top"
            overlay={renderTooltipAddApprover}
          >
        <Button className="mr-1 my-1 float-right"  variant="success" onClick={this.handleAddApproval}><FontAwesomeIcon icon={faPlus} /> <Trans i18nKey="approval.add-approver"/></Button>
      </OverlayTrigger>

      <br /><br />
      {this.state.approval.map((approvalitem,j) => {
          return(
           <div key={j} className="box" style={{ display: this.state.finishedloading ? "block" : "none" }}> 
            
            <Form.Group ><Row>
              <Col><Form.Control type="text" name="fullName" placeholder={i18n.t('approval.name')} className="mr10" value={this.state.approval[j].fullName} onChange={e => this.handleApprovalChange(e,j)}></Form.Control></Col>
              <Col><Form.Control type="text" name="email" placeholder={i18n.t('approval.email')} className="mr10" value={this.state.approval[j].email} onChange={e => this.handleApprovalChange(e,j)}></Form.Control></Col>
              <Col><Form.Control type="text" name="approvalLevel" placeholder={i18n.t('approval.approval-order')} className="mr10" value={this.state.approval[j].approvalLevel} onChange={e => this.handleApprovalChange(e,j)}></Form.Control></Col>
              <Col>{this.state.approval.length !== 0 && 
               <Button className="mr-1 my-1 float-right"  variant="danger" onClick={() => this.handleRemoveApproval(j)}><FontAwesomeIcon icon={faMinus} /> </Button>

              }
              </Col>

             </Row></Form.Group>
             <Form.Group ><Row>
             <Col>{this.state.approval[j].errorFullName !== "" && <div className="validationerror"> {this.state.approval[j].errorFullName}</div>} </Col>
             <Col>{this.state.approval[j].errorEmail !== "" && <div className="validationerror"> {this.state.approval[j].errorEmail}</div> } </Col>
             <Col>{this.state.approval[j].errorApprovalLevel !== "" && <div className="validationerror"> {this.state.approval[j].errorApprovalLevel}</div> }</Col>
             <Col></Col>
             </Row></Form.Group>
             </div>
            )})}


        <br /><br />
          {this.state.kpi.map((item,i) => {
          return(    
            <div key={i} className="box"> 
            <h5><Trans i18nKey="kpi.kpi"/> {i+1}</h5>  
            {/* { this.state.new === true && */}
              <Form.Group >
                <Row><Col>
                <OverlayTrigger
                   placement="right"
                    overlay={renderTooltipKPIType}
                   >
                <Form.Label style={{color: "grey"}}><Trans i18nKey="kpi.overwrite-kpi-by-default"/></Form.Label>
                </OverlayTrigger>
                </Col>
                <Col>
                <Form.Control as="select" type="text" name="type" placeholder="type" className="mr10"  style={{color: "grey"}} value={this.state.kpi[i].type} onChange={e => this.handleKpiChange(e,i)}>
                  {this.state.kpi[i].type === "select-type" && <option style={{color: "grey"}}>{i18n.t('kpi.select-type')}</option>}
                  {this.state.kpiType.map((kpitypeitem, m) => {
                    return (
                      <option style={{color: "grey"}} key={m}>{this.state.kpiType[m].typeName}</option>
                    )
                  })}
                 </Form.Control>
                 </Col>
                 </Row>
                 </Form.Group>
              {/*} } */}
             
               <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.name"/> </Form.Label></Col><Col><Form.Control type="text" name="name" placeholder={i18n.t('kpi.name')} className="mr10" value={this.state.kpi[i].name} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorName}</div></Col></Row></Form.Group> } 

              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.description"/></Form.Label></Col><Col><Form.Control type="text" name="description" placeholder={i18n.t('kpi.description')} className="mr10" value={this.state.kpi[i].description} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorDescription !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorDescription}</div></Col></Row></Form.Group> } 

               <Form.Group ><Row><Col>
                <OverlayTrigger
                    placement="right"
                      overlay={renderTooltipBonusWhen}
                    >
                  <Form.Label><Trans i18nKey="kpi.bonus-when"/> </Form.Label>
                </OverlayTrigger>
               
               </Col><Col><Form.Control as="select" type="text" name="direction" placeholder="direction" className="mr10" value={this.state.kpi[i].directionvalue} onChange={e => this.handleKpiChange(e,i)}>
                    <option>{i18n.t('kpi.result-more-than-target')}</option>
                    <option>{i18n.t('kpi.result-less-than-target')}</option>
               </Form.Control></Col></Row></Form.Group>
          
           

              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.goal"/></Form.Label></Col><Col><Form.Control type="text" name="goal" placeholder={i18n.t('kpi.goal')} className="mr10" value={this.state.kpi[i].goal} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorGoal !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorGoal}</div></Col></Row></Form.Group> } 
 
              <Form.Group ><Row><Col>
                <OverlayTrigger
                      placement="right"
                        overlay={renderTooltipUnit}
                      >
                  <Form.Label><Trans i18nKey="kpi.unit"/> </Form.Label>
                </OverlayTrigger>
              </Col><Col><Form.Control type="text" name="unit" placeholder={i18n.t('kpi.unit')} className="mr10" value={this.state.kpi[i].unit} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorUnit !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorUnit}</div></Col></Row></Form.Group> } 

              <Form.Group ><Row><Col>
                <OverlayTrigger
                        placement="right"
                          overlay={renderTooltipPercentageOfTotalReward}
                        >
                <Form.Label><Trans i18nKey="kpi.percentage-of-total-reward"/> </Form.Label>
                </OverlayTrigger>
              </Col><Col><Form.Control type="text" name="percentageOfTotalReward" placeholder={i18n.t('kpi.percentage-of-total-reward')} className="mr10" value={this.state.kpi[i].percentageOfTotalReward} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorPercentageOfTotalReward !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorPercentageOfTotalReward}</div></Col></Row></Form.Group> } 


              {this.state.kpi.length - 1 === i &&
                <OverlayTrigger placement="top" overlay={renderTooltipAddKPI}>
                  <Button className="mr-1 my-1 float-right"  variant="success" onClick={this.handleAddInput}><FontAwesomeIcon icon={faPlus} /> <Trans i18nKey="kpi.add-kpi"/></Button>
                </OverlayTrigger>
              }

               <OverlayTrigger placement="top" overlay={renderTooltipEditKPIStep}>
                <Button className="mr-1 my-1 float-right"  variant="success" onClick={this.handleEditLevels.bind(this, i)}><FontAwesomeIcon icon={faEdit} /> <Trans i18nKey="kpi-step.edit-levels"/></Button>
              </OverlayTrigger>

              {this.state.kpi.length !== 1 && 
                <Button className="mr-1 my-1 float-right"  variant="danger" onClick={() => this.handleRemoveInput(i)}><FontAwesomeIcon icon={faMinus} /></Button>
              }


              <br />
              <LoadingSpinner/>
            </div> 
          )
        }
      
      )}
      <br />
      <LoadingSpinner/>
      <Button variant="primary" type="submit" className="float-right"><Trans i18nKey="navigation.save"/></Button>
      </Form>
      <br/>
      <br/>
      
    </Layout>
  );
};
};

export default EditOneBonus;