import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Trans } from 'react-i18next';
import i18n from './i18n';

class SetResultOneBonus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      isLoading: true,
      finishedloading: false,
      bonusAgreementId: "", 
      name: "", 
      onTargetReward: "", 
      resultAmount: "", 
      period: "",  
      employeeUserName: "", 
      managerUserName: JSON.parse(localStorage.getItem('currentUser')).userName,
      employeeFullName: "", 
      managerFullName: JSON.parse(localStorage.getItem('currentUser')).fullName, 
      employeeApprovalLevel: 1, 
      managerApprovalLevel: 2,
      phase: "Result",
      approval: [],
      kpi: [
        { 
          kpiId: "",
          bonusAgreementId: "",
          name: "",
          description: "", 
          type: "", 
          goal: "", 
          result: "",  
          resultPercentage: "", 
          percentageOfTotalReward:"", 
          onTargetReward: "",
          resultAmount: "",
        },
      ],
    };
  }
 
  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpiapproval/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          token: this.state.token,
          new: false,
          finishedloading: true, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: this.state.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: this.state.managerFullName,
          employeeApprovalLevel: this.state.employeeApprovalLevel,
          managerApprovalLevel: this.state.managerApprovalLevel,
          phase: this.state.phase,
          approval: response.data.approval,
          kpi: response.data.kpi,
          isLoading: false
        });
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBonusAgreement();
  };

  validateNumber(numberstring) {
    // eslint-disable-next-line
    var emailregex = /^[0-9]*$/gm;
    return emailregex.test(numberstring);
  } 

 handleKpiChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.state.kpi];
    /*list[index]["errorResult"]="";
    list[index][name]=value;*/

    if (name === "result") { 
      if (this.validateNumber(value)) {
        list[index][name]=value;
        list[index]["errorResult"]="";
      } else {
        list[index]["errorResult"]=i18n.t('error-kpi.goal-not-a-number');
      }
   }


    this.setState({      
      kpi: list
    });

  }

  validatebonusagreement() {
    /*this.reseterrors();*/
      var formisvalid = true;
      const kpilist = [...this.state.kpi];
  
      for (var i=0; i<this.state.kpi.length; i++) {

        if  ( isNaN(this.state.kpi[i].result)) { 
          kpilist[i].errorResult = "The result needs to be a number";
          formisvalid = false;
        }
       }  
      if (formisvalid === true) {
           this.setState(state => { return { formIsvalid: true  }}); 
       } else {
           this.setState(state => { return { formIsvalid: false  }}); 
      }
  
 
      this.setState({      
        kpi: kpilist
      });
  
      return formisvalid;
    };

 
  handleSubmit = event => {
    event.preventDefault();

    var sendformisvalid = false;
    sendformisvalid = this.validatebonusagreement();

    /*sendformisvalid = false;*/
    
  if (sendformisvalid) {
    const model = {};
    if (this.state.new) { model.BonusAgreementId = 0 }
    else {model.BonusAgreementId = this.state.bonusAgreementId; }
    model.Name = this.state.name; 
    model.OnTargetReward = parseFloat(this.state.onTargetReward);
    model.ResultAmount = parseFloat(this.state.resultAmount);
    model.Period = this.state.period;
    model.EmployeeUserName = this.state.employeeUserName;
    model.ManagerUserName = this.state.managerUserName;
    model.EmployeeFullName = this.state.employeeFullName;
    model.ManagerFullName = this.state.managerFullName;
    model.EmployeeApprovalLevel = parseInt(this.state.employeeApprovalLevel); 
    model.ManagerApprovalLevel = parseInt(this.state.managerApprovalLevel);
    model.Phase = "Set Results";

    model.Approval = [];
    var newapproval = {};
    for (var i=0; i<this.state.approval.length; i++) {
      if (this.state.new) { newapproval.BonusAgreementId = 0 }
      else {newapproval.BonusAgreementId = this.state.bonusAgreementId; }
      newapproval.EmployeeEmail = this.state.employeeUserName;
      newapproval.EmployeeFullName = this.state.employeeFullName;
      newapproval.Email = this.state.approval[i].email;
      newapproval.FullName = this.state.approval[i].fullName;
      newapproval.ApprovalLevel = parseInt(this.state.approval[i].approvalLevel);
      model.Approval.push(newapproval);
    }

    model.Kpi = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      newkpi.KpiId = this.state.kpi[j].kpiId;
      newkpi.Name = this.state.kpi[j].name;
      newkpi.BonusAgreementId = this.state.bonusAgreementId;
      newkpi.Description = this.state.kpi[j].description;
      newkpi.Type = this.state.kpi[j].type;
      newkpi.Goal = parseInt(this.state.kpi[j].goal);
      newkpi.Result = parseInt(this.state.kpi[j].result);
      newkpi.ResultPercentage = parseFloat(this.state.kpi[j].resultPercentage);
      newkpi.PercentageOfTotalReward = parseFloat(this.state.kpi[j].percentageOfTotalReward);
      newkpi.OnTargetReward = parseFloat(this.state.kpi[j].percentageOfTotalReward) * parseFloat(this.state.onTargetReward);

      model.Kpi.push(newkpi);
    }

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var poststring = '/api/result';
    const connectionstringpost = connectionstring1.concat("" , poststring);

    trackPromise(
    axios.post(connectionstringpost, BonusAgreement
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          const redirectpage = '/SignResults/' + this.state.bonusAgreementId;
          this.setState({
            redirect: redirectpage
          });
          /*this.props.history.push('/BonusAgreements');*/
        }))
  }};
  
  render () {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="SetResultOneBonus"><LoadingSpinner/></div>;
    }

  return (
    <Layout>
      <br />
      <h3><Trans i18nKey="bonus.set-results"/></h3><br/>
      <Form onSubmit={this.handleSubmit}>
      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.header"/> </Form.Label></Col><Col><Form.Label>{this.state.name} </Form.Label></Col></Row></Form.Group>
      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.period"/> </Form.Label></Col><Col><Form.Label>{this.state.period} </Form.Label></Col></Row></Form.Group>
      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="bonus.employee-name"/>  </Form.Label></Col><Col><Form.Label>{this.state.employeeFullName}  </Form.Label></Col></Row></Form.Group>
 
      <br />
      
      <Form.Group ><Row>
        <Col><b><Trans i18nKey="kpi.kpi"/></b></Col>
        <Col><b><Trans i18nKey="kpi.description"/></b></Col>
        <Col><b><Trans i18nKey="kpi.goal"/></b></Col>
        <Col><b><Trans i18nKey="kpi.unit"/></b></Col>
        <Col><b><Trans i18nKey="kpi.result"/></b></Col>
        </Row></Form.Group>

          {this.state.kpi.map((item,i) => {
          return(    
            <div key={i} className="box"> 
            <Form.Group ><Row>
            <Col><Form.Label>{this.state.kpi[i].name} </Form.Label></Col>
            <Col><Form.Label>{this.state.kpi[i].description} </Form.Label></Col>
            <Col><Form.Label>{this.state.kpi[i].goal} </Form.Label></Col>
            <Col><Form.Label>{this.state.kpi[i].unit} </Form.Label></Col>
            <Col><Form.Control type="text" name="result" placeholder="Result" className="mr10" value={this.state.kpi[i].result} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col>
            </Row>
            </Form.Group>
            {this.state.kpi[i].errorResult !== "" && <Form.Group ><Row><Col></Col><Col></Col><Col></Col><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorResult}</div></Col></Row></Form.Group> } 
            </div> 
          )
        }
      
      )}
      <br />
      <LoadingSpinner/>
      <Table borderless>
      <tbody>
      <tr>
      <td><Link className="float-left" to={`/bonusagreements`}><Button variant="secondary"><Trans i18nKey="navigation.return"/></Button></Link></td>
      <td><Button variant="primary" type="submit" className="float-right"><Trans i18nKey="navigation.save"/></Button></td>
      </tr>
      </tbody>
      </Table>
      
      </Form>
      <br/><br/>
    </Layout>
  );
};
};

export default SetResultOneBonus;