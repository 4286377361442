import React, { Component } from "react";
import { Layout } from './components/Layout';
import 'reactjs-popup/dist/index.css';
import { Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import videoHelpIntro from './assets/videohelpintro.mp4';
import imageVideoHelpIntro from './assets/imagevideohelpintro.png';
import { Redirect } from "react-router-dom";
import { Trans } from 'react-i18next';
import i18n from './i18n';


class SignUpComplete extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isShow: false,
      redirect: null,
      videoSource: ''
    };
  }
 

  componentDidMount() {
    console.log(JSON.parse(localStorage.getItem('currentUser')).language);
    if (JSON.parse(localStorage.getItem('currentUser')).language === 'se') {
      i18n.changeLanguage('se');
    }

    if (JSON.parse(localStorage.getItem('currentUser')).language === 'en') {
      i18n.changeLanguage('en');
    }

    this.setState({
      videoSource: videoHelpIntro
  })
}

handleContinue = () => {
  this.setState({
    redirect: '/editonebonus/new'
  });

}


 
  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Layout>
        <br /><br />
        <h1><Trans i18nKey="sign-up-complete.header"/>!</h1>
      <div>
      <Trans i18nKey="sign-up-complete.intro"/>:
        <video ref="vidRef" src={this.state.videoSource} poster={imageVideoHelpIntro} playsInline muted  autoPlay loop controls style={{width: '100%'}}></video>
        
        <Trans i18nKey="sign-up-complete.entro"/><br />
        <Button variant="primary" className="float-right mr-1 btn-lg" onClick={this.handleContinue}><Trans i18nKey="navigation.continue"/></Button>

         </div>
    </Layout>
    );
  }
}



export default SignUpComplete;

