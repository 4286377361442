import React, { Component } from "react";
import Container from 'react-bootstrap/Container';

class Footer extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        
      };

   }

  render () {
  return (
  <div>
       
       <div style={{backgroundColor: "#222222"}} className="p-3 text-light">
            
        <Container>
        <br />
        <div className="row">
            <div className="col-lg-6 col-md-6 text-left">
                
                <h1>Kontakt</h1>
                <h3>Manage Bonus</h3>
                +46 730 43 57 16 <br />
                contact@managebonus.com<br />
                <br />
                <div style={{"fontSize": "10px"}}>
                Paseedu AB - 556951-8391 -        
                Malvastigen 7D, 192 54 Sollentuna, Sweden<br />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 text-left">
            <br /><br /><br />
            <a className="text-light" href="/partner">Partner</a>   <br /> 
            <a className="text-light" href="/privacy">Privacy policy</a> <br />
            <a className="text-light" href="/intro">Intro</a>
                
                </div>
        </div>
         </Container>
      </div>

  </div>
  );
};
};

export default Footer;

