import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Button} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';


class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      userName: JSON.parse(localStorage.getItem('currentUser')).userName
    };
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {

  };


 
  orderUser(){
    this.orderSubscription("User");
  }

  orderTeam(){
    this.orderSubscription("Team");
  }

  orderCompany(){
    this.orderSubscription("Company");
  }

  orderEnterprice(){
    this.orderSubscription("Enterprise");
  }


  orderSubscription(subscriptionName) {

    const model = {};
    model.SubscriptionName = subscriptionName; 
    model.UserName = this.state.userName; 

    const Order = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/Subscription';
    const connectionstring = connectionstring1.concat("" , connectionstring2);

    trackPromise(
    axios.post(connectionstring, Order
      ,
    {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then(res => {
        this.props.history.push('/orderconfirmation');
      }))
  };

  render () {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="Sign"><LoadingSpinner/></div>;
    }


  return (
    <React.Fragment>
    <Layout>
      <br /><br />
       <div>
        <br/>
        <h1><Trans i18nKey="subscription.header"/></h1> 
        <Trans i18nKey="subscription.line-1"/> <br /><br />
        <Trans i18nKey="subscription.line-2"/> <br /><br />
        <CardDeck>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title><Trans i18nKey="subscription.box-1-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="subscription.box-1-for-who"/> <br />
            <Trans i18nKey="subscription.box-1-price"/> <br />
            <Trans i18nKey="subscription.box-1-invoice-period"/>
            </Card.Text>
            <span style={{'color': '#007bff', 'text-decoration': 'none', 'background-color': 'transparent', 'cursor':'pointer'}} onClick={() => {this.orderUser()}}><Button variant="primary" className="mr-1" ><Trans i18nKey="subscription.order"/></Button></span>
          </Card.Body>
        </Card>
        <Card style={{ width: '18rem' }}>
          <Card.Body>
          <Card.Title><Trans i18nKey="subscription.box-2-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="subscription.box-2-for-who"/> <br />
            <Trans i18nKey="subscription.box-2-price"/> <br />
            <Trans i18nKey="subscription.box-2-invoice-period"/>
            </Card.Text>
            <span style={{'color': '#007bff', 'text-decoration': 'none', 'background-color': 'transparent', 'cursor':'pointer'}} onClick={() => {this.orderTeam()}}><Button variant="primary" className="mr-1" ><Trans i18nKey="subscription.order"/></Button></span>
          </Card.Body>
         </Card>
         <Card style={{ width: '18rem' }}>
          <Card.Body>
          <Card.Title><Trans i18nKey="subscription.box-3-header"/></Card.Title>
            <Card.Text>
            <Trans i18nKey="subscription.box-3-for-who"/> <br />
            <Trans i18nKey="subscription.box-3-price"/> <br />
            <Trans i18nKey="subscription.box-3-invoice-period"/>
            </Card.Text>
            <span style={{'color': '#007bff', 'text-decoration': 'none', 'background-color': 'transparent', 'cursor':'pointer'}} onClick={() => {this.orderCompany()}}><Button variant="primary" className="mr-1" ><Trans i18nKey="subscription.order"/></Button></span>
          </Card.Body>
         </Card>
        
        </CardDeck>
        {/* Site: {process.env.REACT_APP_apiUrl} */}
        </div>

               
      <LoadingSpinner/>
      <Table borderless>
        <tr>
           <td><Link className="float-left" to={`/bonusagreements/`}><Button  variant="secondary"><Trans i18nKey="navigation.return"/></Button></Link></td>
       </tr>
      </Table>
      <br /><br />


    </Layout>
    </React.Fragment>
  );
};
};

export default Order;