import React from 'react';
import axios from 'axios';
import { Form, Button, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { Layout } from './components/Layout';
import { authenticationService } from './_services/authentication.service';
import { Redirect } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";


class AdminReset extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    language: "en",
    role: JSON.parse(localStorage.getItem('currentUser')).role,
    token: JSON.parse(localStorage.getItem('currentUser')).token, 
    redirect: false, 
    email: "",
    password: "",
    errorEmail: "",
    errorPassword: "",
    postStatus: "",
    show: false, 
    isLoading: false, 
    done: false, 
    doneMessage: "", 
    i18_button: "Forgot Password",
    i18_header: "Reset Password",
    i18_close: "Close",
    i18_reset: "Reset",
    i18_success: "The password is reset. ",
    i18_error: "The password could not be reset.",
    i18_email_placeholder: "Email",
    i18_email_incorrect: "The email is incorrect"
  }
}

  componentDidMount() {
    if (this.state.role !== "Admin") { 
      authenticationService.logout();
      this.setState({redirect: true })
      
    };
  }
 

  handleChange = (e) => {
    const {name, value} = e.target;

    var New_email = this.state.email; 
    var New_password = this.state.password; 

    if (name === "email") { New_email = value; }
    if (name === "password") { New_password = value;  }

    this.setState({
      email: New_email, 
      password: New_password
    });

  }

  validateEmail(email) {
    // eslint-disable-next-line
    var emailregex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailregex.test(email);
  }

  postReset = event => {
    event.preventDefault();
    var formisvalid = true;

    if (!this.validateEmail(this.state.email)) 
    {
      this.setState(state => { return { errorEmail: this.state.i18_email_incorrect }});
      formisvalid = false;
    } else {
        this.setState(state => { return { errorEmail: '' }});
    }

    if (formisvalid) {
        const model = {};
        model.UserName = this.state.email;
        model.Password = this.state.password; 

        const UserRegisterBasicUser = model;
        const connectionstring = process.env.REACT_APP_apiUrl + '/identity/Users/adminresetpassword';

        trackPromise(
        axios.post(connectionstring, UserRegisterBasicUser
        ,
        {
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`, 
        }
        })
        .then(res => {
            this.setState({ 
              done: true, 
              doneMessage: this.state.i18_success 
             });
            /*this.props.history.push('/bonusagreements');*/
        }))
        .catch(
          error => {
            this.setState({ 
              done: true, 
              doneMessage: this.state.i18_error 
             });

        }
        );
     }
};



  // Putting that data to use
  render() {

    if (this.state.redirect) {
        return <Redirect to={'/'} />
      }

    return (
        <div>
            <Layout>
            <LoadingSpinner/>
            <br />
            <h2>Reset Password for user</h2><br />
            <Form>
            <Form.Group ><Row><Col><Form.Control type="text" name="email" placeholder='User Email' className="mr10" value={this.state.email} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
            <Form.Group ><Row><Col><Form.Control type="text" name="password" placeholder='New Password' className="mr10" value={this.state.password} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
            <Form.Group ><Row><Col>{this.state.errorEmail !== "" && <div className="validationerror"> {this.state.errorEmail}</div> }</Col><Col></Col></Row></Form.Group>
            </Form>

            {this.state.done && this.state.doneMessage}

            <Link className="float-left mr-1" to={`/adminhome`}><Button  variant="secondary">Return</Button></Link>
            <Button variant="primary" onClick={this.postReset} className="float-left mr-1">{this.state.i18_reset}</Button><br /><br />
            <i>The user password must be of 6 characters, have a special character, a number, one lowercase character and one uppcase character. </i><br />
            <br /><br />


            </Layout>



        </div>




    );
  }
}

export default AdminReset;