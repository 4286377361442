import React, { Component } from "react";
import './App.css';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import { Redirect } from "react-router-dom";
import { Trans } from 'react-i18next';
import i18n from './i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faEdit  } from '@fortawesome/free-solid-svg-icons';



class EditKpiTables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true, 
      redirect: null,
      redirecttype: 'save',
      redirectkpiindex: '0',
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      formIsvalid: true,
      new: true,
      finishedloading: false,
      bonusAgreementId: "", 
      name: "", 
      onTargetReward: 0, 
      resultAmount: 0, 
      period: "",  
      employeeUserName: "", 
      managerUserName: JSON.parse(localStorage.getItem('currentUser')).userName,
      employeeFullName: "", 
      managerFullName: JSON.parse(localStorage.getItem('currentUser')).fullName, 
      employeeApprovalLevel: 1, 
      managerApprovalLevel: 2,
      phase: "Draft",
      currency: "",
      errorName: "",
      errorOnTargetReward: "",
      errorResultAmount: "",
      errorPeriod: "",
      errorEmployeeUserName: "",
      errorEmployeeFullName: "",
      errorEmployeeApprovalLevel: "",
      errorManagerApprovalLevel: "",
      errorPhase: "",
      errorCurrency: "",
      errorForm: "",
      approval: [],
      kpi: [
        { 
          kpiId: "",
          bonusAgreementId: "",
          name: "",
          description: "", 
          type: "", 
          goal: 0, 
          result: 0,  
          resultPercentage: 0, 
          percentageOfTotalReward:0, 
          onTargetReward: 0,
          resultAmount: 0,
          unit: "",
          directionvalue: i18n.t('kpi.result-more-than-target'),
          direction: "Up",
          managerUserName: JSON.parse(localStorage.getItem('currentUser')).userName,
          sortOrder: 1,
          currency: "",
          lowCutOff: 70,
          highCutOff: 140,
          scaleUnderGoal: "scale-2-1",
          scaleOverGoal: "scale-1-2",
          errorName: "",
          errorDescription: "",
          errorType: "",
          errorGoal: "",
          errorResult: "",
          errorResultPercentage: "",
          errorPercentageOfTotalReward: "",
          errorTargetReward: "",
          errorResultAmount: "", 
          errorUnit: ""
        }          
      ],
    };

  }
 

  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/defaultbonusagreement/';
    var connectionstring3 = this.state.managerUserName;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          new: false,
          finishedloading: true, 
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          phase: response.data.phase,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          employeeApprovalLevel: response.data.employeeApprovalLevel,
          managerApprovalLevel: response.data.managerApprovalLevel,
          currency: response.data.currency,
          usedFor: response.data.usedFor, 
          kpi: response.data.kpi,
          isLoading: false
        });
        this.populateSelectedKpi();
        
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };
  

  // Let's our app know we're ready to render the data
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBonusAgreement();
   };

   populateSelectedKpi()
   {
    const list = [...this.state.kpi];

    var i ;
    for(i=0; i < list.length; i++){
      if (list[i].direction === "Up") { 
          list[i].directionvalue = i18n.t('kpi.result-more-than-target');
        }
      if (list[i].direction === "Down") { 
        list[i].directionvalue = i18n.t('kpi.result-less-than-target');
      }

      this.setState({kpi: list})
    }
  

   }

 handleKpiChange = (e, index) => {
    const {name, value} = e.target;
    const list = [...this.state.kpi];

    if (name === "name") {
      list[index][name]=value;
      list[index]["errorName"]="";
    }

    if (name === "description") {
      list[index][name]=value;
      list[index]["errorDescription"]="";
    }

    if (name === "type") {
      list[index][name]=value;
      list[index]["errorType"]="";
    }

    if (name === "direction") {
      list[index]["directionvalue"]=value;
      list[index]["errorType"]="";
    }

    if (name === "direction") {
      list[index]["directionvalue"]=value;
      if (value === i18n.t('kpi.result-more-than-target')) { 
        list[index]["direction"]="Up";
      }
      if (value === i18n.t('kpi.result-less-than-target')) { 
        list[index]["direction"]="Down";
      }
      list[index]["errorType"]="";
    }

    if (name === "goal") {
      list[index][name]=value;
      /*if ( value === 0 || isNaN(value)) { list[index][name] = 0} else list[index][name]=parseFloat(value);*/
      list[index]["errorGoal"]="";
    }

    if (name === "unit") {
      list[index][name]=value;
      list[index]["errorUnit"]="";
    }

    if (name === "goal") {
      list[index][name]=value;
      list[index]["errorGoal"]="";
    }

    this.setState({      
      kpi: list,
      errorForm: ""
    });

  }

 handleAddInput = () => {
      const list = [...this.state.kpi];

      const newkpi = {};
      newkpi.kpiId = -1;
      newkpi.bonusAgreementId = this.state.bonusAgreementId;
      newkpi.name = "";
      newkpi.description = "";
      newkpi.type = "";
      newkpi.goal = 0;
      newkpi.result = 0;
      newkpi.resultPercentage = 0;
      newkpi.percentageOfTotalReward = 0;
      newkpi.onTargetReward = 0;
      newkpi.resultAmount = 0;
      newkpi.unit = "";
      newkpi.usedFor = "UserDefault";
      newkpi.direction = "Up";
      newkpi.managerUserName = this.state.managerUserName;
      newkpi.sortOrder = list.length;
      newkpi.currency = this.state.currency;
      newkpi.lowCutOff = 70;
      newkpi.highCutOff = 140;
      newkpi.numberOfKpiSteps = 10;
      newkpi.scaleUnderGoal = "scale-2-1";
      newkpi.scaleOverGoal = "scale-1-2";
      newkpi.directionvalue = i18n.t('kpi.result-more-than-target');
      newkpi.errorName = "";
      newkpi.errorDescription = "";
      newkpi.errorType = "";
      newkpi.errorGoal = "";
      newkpi.errorResult = "";
      newkpi.errorResultPercentage = "";
      newkpi.errorPercentageOfTotalReward = "";
      newkpi.errorTargetReward = "";
      newkpi.errorResultAmount = ""; 
      newkpi.errorUnit = "";

      list.push(newkpi);

      this.setState(state => {
        return {
          kpi: list,
       };
     });
  }

  handleRemoveInput = index => {
    const list = [...this.state.kpi];
    list.splice(index,1);

    this.setState(state => {
       return {
        kpi: list,
      };
    });
  }
 
  handleEditLevels = index => {

    this.setState(state => {
       return {
        redirecttype: 'editsteps',
        redirectkpiindex: index,
      };
    });

    this.postbonusagreement();

  }

  validatebonusagreement() {
    var formisvalid = true;
    const approvallist = [...this.state.approval];
    const kpilist = [...this.state.kpi];

    for (var i=0; i<this.state.kpi.length; i++) {
      if  (this.state.kpi[i].name === "") { 
        kpilist[i].errorName = i18n.t('error-kpi.no-name');
        formisvalid = false;
      }

      if  (this.state.kpi[i].goal === 0 || this.state.kpi[i].goal === "") { 
        kpilist[i].errorGoal = i18n.t('error-kpi.no-goal');
        formisvalid = false;
      }

      if (isNaN(this.state.kpi[i].goal)) {
        kpilist[i].errorGoal = i18n.t('error-kpi.goal-not-a-number'); 
        formisvalid = false;
      }

      if  (this.state.kpi[i].unit === "") { 
        kpilist[i].errorUnit = i18n.t('error-kpi.no-unit');
        formisvalid = false;
      }

    }

    if (formisvalid === true) {
         this.setState(state => { return { formIsvalid: true, errorForm: ""  }});
     } else {
         this.setState(state => { return { formIsvalid: false, errorForm: "Error in form"  }}); 
    }

    this.setState({      
      approval: approvallist,
      kpi: kpilist,
    });

    return formisvalid;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.postbonusagreement();
  }




  postbonusagreement() {

    var sendformisvalid = false;
    sendformisvalid = this.validatebonusagreement();

    /*sendformisvalid = true;*/
    
  if (sendformisvalid) {
    const model = {};
    model.BonusAgreementId = this.state.bonusAgreementId;
    model.Name = this.state.name; 
    model.OnTargetReward = parseFloat(this.state.onTargetReward);
    model.ResultAmount = parseFloat(this.state.resultAmount);
    model.Period = this.state.period;
    model.EmployeeUserName = this.state.employeeUserName;
    model.ManagerUserName = this.state.managerUserName;
    model.Phase = "Draft";
    model.EmployeeFullName = this.state.employeeFullName;
    model.ManagerFullName = this.state.managerFullName;
    model.EmployeeApprovalLevel = parseInt(this.state.employeeApprovalLevel); 
    model.ManagerApprovalLevel = parseInt(this.state.managerApprovalLevel);
    model.Currency = this.state.currency;
    model.UsedFor = this.state.usedFor;

    model.Kpi = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      newkpi.KpiId = this.state.kpi[j].kpiId;
      newkpi.BonusAgreementId = this.state.kpi[j].bonusAgreementId;
      newkpi.Name = this.state.kpi[j].name;
      newkpi.Description = this.state.kpi[j].description;
      newkpi.Type = this.state.kpi[j].name;
      newkpi.Goal = parseInt(this.state.kpi[j].goal);
      newkpi.Result = parseInt(this.state.kpi[j].result);
      newkpi.ResultPercentage = parseFloat(this.state.kpi[j].resultPercentage);
      newkpi.PercentageOfTotalReward = parseFloat(this.state.kpi[j].percentageOfTotalReward);
      newkpi.OnTargetReward = parseInt(this.state.kpi[j].onTargetReward);
      newkpi.ResultAmount = parseInt(this.state.kpi[j].resultAmount);
      newkpi.Unit = this.state.kpi[j].unit;
      newkpi.UsedFor = this.state.kpi[j].usedFor;
      newkpi.Direction = this.state.kpi[j].direction;
      newkpi.ManagerUserName = this.state.managerUserName;
      newkpi.SortOrder = this.state.kpi[j].sortOrder;
      newkpi.Currency = this.state.currency;
      newkpi.LowCutOff = this.state.kpi[j].lowCutOff;
      newkpi.HighCutOff = this.state.kpi[j].highCutOff;
      newkpi.NumberOfKpiSteps = this.state.kpi[j].numberOfKpiSteps;
      newkpi.ScaleUnderGoal = this.state.kpi[j].scaleUnderGoal;
      newkpi.ScaleOverGoal = this.state.kpi[j].scaleOverGoal;

      model.Kpi.push(newkpi);
    }

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var poststring = '/api/defaultbonusagreement';
    const connectionstringpost = connectionstring1.concat("" , poststring);

     if (true) {
    trackPromise(
    axios.put(connectionstringpost, BonusAgreement
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {
          this.setState({
            bonusAgreementId: res.data.bonusAgreementId,
            kpi: res.data.kpi, 
            isLoading: false
          });

          if (this.state.redirecttype === 'save') {

            this.props.history.push('/BonusAgreements');
          } else {
              const redirectpage = '/EditKpiTableSteps/' + this.state.kpi[this.state.redirectkpiindex].kpiId + '/EditKpiTables/' + this.state.bonusAgreementId;
              this.setState({
                redirect: redirectpage
              });
            }
        }))
      }
  }};
  
  
  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="EditKpiTables"><LoadingSpinner/></div>;
    }

  return (
    <Layout>
      <LoadingSpinner/>
      <br />
      <h3><Trans i18nKey="kpi-tables.edit-default-kpi-tables"/></h3><br/>
      <Form onSubmit={this.handleSubmit}>
      <br />
      
          {this.state.kpi.map((item,i) => {
          return(    
            <div key={i} className="box"> 
            <h5><Trans i18nKey="kpi-tables.standard-kpi-type"/> {i+1}</h5>  
              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.name"/> </Form.Label></Col><Col><Form.Control type="text" name="name" placeholder={i18n.t('kpi.name')} className="mr10" value={this.state.kpi[i].name} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorName}</div></Col></Row></Form.Group> } 

              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.description"/> </Form.Label></Col><Col><Form.Control type="text" name="description" placeholder={i18n.t('kpi.description')} className="mr10" value={this.state.kpi[i].description} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorDescription !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorDescription}</div></Col></Row></Form.Group> } 

              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.goal"/> </Form.Label></Col><Col><Form.Control type="text" name="goal" placeholder={i18n.t('kpi.goal')} className="mr10" value={this.state.kpi[i].goal} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorGoal !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorGoal}</div></Col></Row></Form.Group> } 


              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.unit"/> </Form.Label></Col><Col><Form.Control type="text" name="unit" placeholder={i18n.t('kpi.unit')} className="mr10" value={this.state.kpi[i].unit} onChange={e => this.handleKpiChange(e,i)}></Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorUnit !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorUnit}</div></Col></Row></Form.Group> } 

              <Form.Group ><Row><Col><Form.Label><Trans i18nKey="kpi.bonus-when"/> </Form.Label></Col><Col><Form.Control as="select" type="text" name="direction" placeholder="direction" className="mr10" value={this.state.kpi[i].directionvalue} onChange={e => this.handleKpiChange(e,i)}>
                  <option>{i18n.t('kpi.result-more-than-target')}</option>
                  <option>{i18n.t('kpi.result-less-than-target')}</option>
                </Form.Control></Col></Row></Form.Group>
              {this.state.kpi[i].errorType !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.kpi[i].errorType}</div></Col></Row></Form.Group> } 
 

              {this.state.kpi.length - 1 === i &&
               <Button variant="success" className="mr10 float-right" onClick={this.handleAddInput}><FontAwesomeIcon icon={faPlus} /></Button>
               }

              <LoadingSpinner/>
              <Button variant="success" className="mr10 float-right" onClick={this.handleEditLevels.bind(this, i)}><FontAwesomeIcon icon={faEdit} /> <Trans i18nKey="kpi-step.edit-levels"/></Button>
 

              {this.state.kpi.length !== 1 && 
                <Button variant="danger" className="mr10 float-right" onClick={() => this.handleRemoveInput(i)}><FontAwesomeIcon icon={faMinus} /></Button>
              }
 

              <br />
              
            </div> 
          )
        }
      
      )}

      <LoadingSpinner/>
      {this.state.errorForm !== "" && <div><br /><br /><Form.Group ><Row><Col></Col><Col><div className="validationerror"> <Trans i18nKey="error.form-error"/></div></Col></Row></Form.Group></div> } 
      <br />
      <Button variant="primary" type="submit" className="float-right"><Trans i18nKey="navigation.save"/></Button>
      </Form>
      <br/><br/>
    </Layout>
  );
};
};

export default EditKpiTables;