import React, { Component } from "react";
import { Layout } from './components/Layout';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import i18n from './i18n';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new: true,
      isLoading: true,
      redirecttype: 'save',
      token: JSON.parse(localStorage.getItem('currentUser')).token,
      userId: JSON.parse(localStorage.getItem('currentUser')).id,
      firstName: "", 
      lastName: "",
      title: "",
      company: {
        name: "",
        street: "",
        houseNumber: "",
        postCode: "",
        city: "",
        country: "",
        companyCode: "",
        vatCode: "",
        webPage: "",
        email: "", 
        phone: "", 

    }
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    /* We need to do this to activate the top menu again*/
    this.getUserAndCompanyData();

  };


  getUserAndCompanyData() {
    var connectionstring = process.env.REACT_APP_apiUrl + '/identity/Users/getusercompanyedit/' + this.state.userId;

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
      ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          new: false,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          title: response.data.title,
          language: window.localStorage.i18nextLng,
          companyId: response.data.companyId,
          company: response.data.company,
          isLoading: false
        });
        
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
  };


  handleChange = (e) => {
    const {name, value} = e.target;

    var New_firstName = this.state.firstName; 
    var New_lastName = this.state.lastName; 
    var New_title = this.state.title;
    var New_name= this.state.company.name;
    var New_street = this.state.company.street;
    var New_houseNumber = this.state.company.houseNumber;
    var New_postCode = this.state.company.postCode;
    var New_city = this.state.company.city; 
    var New_country = this.state.company.country;
    var New_companyCode = this.state.company.companyCode;
    var New_vatCode = this.state.company.vatCode;
    var New_webPage = this.state.company.webPage;
    var New_email = this.state.company.email;
    var New_phone = this.state.company.phone;


    var New_errorFirstName = this.state.errorFirstName;
    var New_errorLastName = this.state.errorLastName;
    var New_errorTitle = this.state.errorTitle;
    var New_errorName = this.state.errorName;

    if (name === "firstName") { New_firstName = value; New_errorFirstName = ""}
    if (name === "lastName") { New_lastName = value; New_errorLastName = "" }
    if (name === "title") { New_title = value; New_errorTitle = "" }
    if (name === "name") { New_name = value; New_errorName = "" }
    if (name === "street") { New_street = value;}
    if (name === "houseNumber") { New_houseNumber = value;}
    if (name === "postCode") { New_postCode = value;}
    if (name === "city") { New_city = value;}
    if (name === "country") { New_country = value;}
    if (name === "companyCode") { New_companyCode = value;}
    if (name === "vatCode") { New_vatCode = value;}
    if (name === "webPage") { New_webPage = value;}
    if (name === "email") { New_email = value;}
    if (name === "phone") { New_phone = value;}

    this.setState({
      firstName: New_firstName, 
      lastName: New_lastName,
      title: New_title,
      errorFirstName: New_errorFirstName,
      errorLastName: New_errorLastName,
      errorTitle: New_errorTitle,
      errorName: New_errorName,
      company: {
        name: New_name,
        street: New_street,
        houseNumber: New_houseNumber,
        postCode: New_postCode,
        city: New_city,
        country: New_country,
        companyCode: New_companyCode,
        vatCode: New_vatCode,
        webPage: New_webPage,
        email: New_email, 
        phone: New_phone
    }
    });

  }

  validatebonusagreement() {
      var formisvalid = true;
  
      if (!this.state.firstName) {
        this.setState(state => { return { errorFirstName: i18n.t('error-settings.no-first-name') }});
        formisvalid = false;
      }

      if (!this.state.lastName) {
        this.setState(state => { return { errorLastName: i18n.t('error-settings.no-last-name') }});
        formisvalid = false;
      }

      if (!this.state.title) {
        this.setState(state => { return { errorTitle: i18n.t('error-settings.no-title') }});
        formisvalid = false;
      }

      if (!this.state.company.name) {
        this.setState(state => { return { errorName: i18n.t('error-company.no-company-name') }});
        formisvalid = false;
      }

      if (formisvalid === true) {
           this.setState(state => { return { formIsvalid: true  }}); 
       } else {
           this.setState(state => { return { formIsvalid: false  }}); 
      }
  
      return formisvalid;
    };

    handleKPITables = index => {

      this.setState(state => {
         return {
          redirecttype: 'editkpitables',
        };
      });
  
       this.postUserAndCompanyData();
  
    }



  handleSubmit = event => {
    event.preventDefault();
    this.postUserAndCompanyData();

  };

  postUserAndCompanyData(){ 


    var sendformisvalid = false;
    sendformisvalid = this.validatebonusagreement();

    const model = {};
    model.UserId = this.state.userId;
    model.FirstName = this.state.firstName;
    model.LastName = this.state.lastName; 
    model.Title = this.state.title;
    model.Language = window.localStorage.i18nextLng;

    const Company = {}
    
    Company.Name = this.state.company.name;
    Company.Street = this.state.company.street;
    Company.HouseNumber = this.state.company.houseNumber;
    Company.PostCode = this.state.company.postCode;
    Company.City = this.state.company.city;
    Company.Country = this.state.company.country;
    Company.CompanyCode = this.state.company.companyCode;
    Company.VATCode = this.state.company.vatCode;
    Company.WebPage = this.state.company.webPage;
    Company.Email = this.state.company.email;
    Company.Logo = this.state.company.logo;
    Company.Phone = this.state.company.phone;
    model.Company = Company;

    const UserCompanyEdit = model;

    var connectionstring = process.env.REACT_APP_apiUrl + '/identity/Users/changeuserandcompanysettings';
   
    /*sendformisvalid = false; */

    if (sendformisvalid) {

    trackPromise(
    axios.post(connectionstring, UserCompanyEdit
        ,
      {
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then(res => {

          /*this.posttracking();*/
          this.setState({
            bonusAgreementId: res.data.bonusAgreementId,
            kpi: res.data.kpi
          });
          if (this.state.redirecttype === 'editkpitables') {
            this.setState({ redirect: '/editkpitables' })
          } else {
              this.setState({ redirect: '/ChangeLogo'  })};
        }))
  }};

render() {
 
  if (this.state.redirect) {
    return <Redirect to={this.state.redirect} />
  }


  const { isLoading } = this.state;

  if (isLoading) {
    return <div className="Settings"><LoadingSpinner/></div>;
  }

  return (
    <React.Fragment>
    <Layout>
    <Form onSubmit={this.handleSubmit}>
        <div>
          <br /><br />
          <div className="Settings">
          <h2><Trans i18nKey="settings.header"/></h2>

          
        </div>
    </div>

          
      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="settings.first-name"/></Form.Label></Col><Col><Form.Control type="value" name="firstName" placeholder={i18n.t('settings.first-name')} className="mr10" value={this.state.firstName} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorFirstName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorFirstName}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="settings.last-name"/>  </Form.Label></Col><Col><Form.Control type="value" name="lastName" placeholder={i18n.t('settings.last-name')} className="mr10" value={this.state.lastName} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorLastName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorLastName}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="settings.title"/>  </Form.Label></Col><Col><Form.Control type="value" name="title" placeholder={i18n.t('settings.title')} className="mr10" value={this.state.title} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorTitle !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorTitle}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col></Col><Col><Link to={`/changelanguage`}><Button><Trans i18nKey="menu.change-language"/></Button></Link></Col></Row></Form.Group>
      <br /><br />

      <h3><Trans i18nKey="company.company"/> </h3>

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.name"/>  </Form.Label></Col><Col><Form.Control type="value" name="name" placeholder={i18n.t('company.name')} className="mr10" value={this.state.company.name || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorName !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorName}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.street"/> </Form.Label></Col><Col><Form.Control type="value" name="street" placeholder={i18n.t('company.street')} className="mr10" value={this.state.company.street || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorStreet !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorStreet}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.house-number"/>  </Form.Label></Col><Col><Form.Control type="value" name="houseNumber" placeholder={i18n.t('company.house-number')} className="mr10" value={this.state.company.houseNumber || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorHouseNumber !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorHouseNumber}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.post-code"/>  </Form.Label></Col><Col><Form.Control type="value" name="postCode" placeholder={i18n.t('company.post-code')} className="mr10" value={this.state.company.postCode || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorPostCode !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorPostCode}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.city"/>  </Form.Label></Col><Col><Form.Control type="value" name="city" placeholder={i18n.t('company.city')} className="mr10" value={this.state.company.city || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorCity !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorCity}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.country"/>  </Form.Label></Col><Col><Form.Control type="value" name="country" placeholder={i18n.t('company.country')} className="mr10" value={this.state.company.country  || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorCountry !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorCountry}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.company-code"/>  </Form.Label></Col><Col><Form.Control type="value" name="companyCode" placeholder={i18n.t('company.company-code')} className="mr10" value={this.state.company.companyCode || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorCompanyCode !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorCompanyCode}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.vat-code"/>  </Form.Label></Col><Col><Form.Control type="value" name="vatCode" placeholder={i18n.t('company.vat-code')} className="mr10" value={this.state.company.vatCode || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorVATCode !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorVATCode}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.web-page"/>  </Form.Label></Col><Col><Form.Control type="value" name="webPage" placeholder={i18n.t('company.web-page')} className="mr10" value={this.state.company.webPage || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorWebPage !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorWebPage}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.email"/>  </Form.Label></Col><Col><Form.Control type="value" name="email" placeholder={i18n.t('company.email')} className="mr10" value={this.state.company.email || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorEmail !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorEmail}</div></Col></Row></Form.Group> } 

      <Form.Group ><Row><Col><Form.Label><Trans i18nKey="company.phone"/> </Form.Label></Col><Col><Form.Control type="value" name="phone" placeholder={i18n.t('company.phone')} className="mr10" value={this.state.company.phone || ""} onChange={e => this.handleChange(e)}></Form.Control></Col></Row></Form.Group>
      {this.state.errorPhone !== "" && <Form.Group ><Row><Col></Col><Col><div className="validationerror"> {this.state.errorPhone}</div></Col></Row></Form.Group> } 


      <h3><Trans i18nKey="kpi-tables.default-kpi-tables"/> </h3>

      <Form.Group ><Row><Col></Col><Col><input type="button" variant="primary" value={i18n.t('kpi-tables.edit-default-kpi-tables')} className="float-left btn-primary btn" onClick={this.handleKPITables.bind(this)}/></Col></Row></Form.Group>
      <br />

      <Table borderless>
      <tbody>  
      <tr>
      <td></td>
      <td><Button variant="primary" type="submit" className="float-right"><Trans i18nKey="navigation.save"/></Button></td>
      </tr>
      </tbody>
      </Table>
      </Form>
    </Layout>
    </React.Fragment>
  );
}}

export default Settings;