import React from 'react';
import axios from 'axios';
import { Layout } from './components/Layout';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import LoadingSpinner from "./components/LoadingSpinner";
import BonusTableSteps from './components/bonustablesteps';
import { Table } from 'react-bootstrap';
import Share from './components/Share';

class SignResults extends React.Component {
  constructor(props) {
    super(props);
  // State will apply to the posts object which is set to loading by default
  this.state = {
    token: JSON.parse(localStorage.getItem('currentUser')).token,
    bonusAgreementId: "",
    name: "",
    onTargetReward: null,
    resultAmount: null,
    period: "",
    employeeUserName: "",
    managerUserName: "",
    employeeFullName: "",
    managerFullName: "",
    managerApprovalLevel: "",
    phase: "",
    kpi: [],
    data_bonuslevels: [],
    isLoading: true,
    errors: null
  }
}

getIPInfo = () => {
  axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      this.setState({
        token: this.state.token, 
        bonusAgreementId: this.state.bonusAgreementId,
        employeeEmail: this.state.employeeEmail, 
        employeeFullName: this.state.employeeFullName, 
        phase: this.state.phase, 
        dateSent: this.state.dateSent, 
        dateAnswered: this.state.dateAnswered, 
        timeAnswered: this.state.timeAnswered, 
        approvalStatus: this.state.approvalStatus, 
        approvalLevel: this.state.approvalLevel, 
        approvalComment: this.state.approvalComment, 
        email: this.state.email, 
        fullName: this.state.fullName, 
        ipAddress: data.ip, 
        city: data.city, 
        country: data.country_name, 
        longitude: String(data.longitude), 
        latitude: String(data.latitude), 
        name: this.state.name,
        onTargetReward: this.state.onTargetReward,
        resultAmount: this.state.resultAmount,
        period: this.state.period,
        employeeUserName: this.state.employeeUserName,
        managerUserName: this.state.managerUserName,
        kpi: this.state.kpi,
        isLoading: this.state.isLoading
      });
      
  }).catch((error) => {
      /* error */
  });
};


  // Now we're going to make a request for data using axios
  getBonusAgreement() {
    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/bonuskpi/';
    var connectionstring3 = this.props.match.params.bonusAgreementId;
    var connectionstring12 = connectionstring1.concat("" , connectionstring2);
    var connectionstring = connectionstring12.concat("" , connectionstring3);
    
    console.log(connectionstring);

    trackPromise(
    axios
      // This is where the data is hosted
      .get(connectionstring
        ,
        {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          bonusagreement: response.data,
          bonusAgreementId: response.data.bonusAgreementId,
          name: response.data.name,
          onTargetReward: response.data.onTargetReward,
          resultAmount: response.data.resultAmount,
          period: response.data.period,
          employeeUserName: response.data.employeeUserName,
          managerUserName: response.data.managerUserName,
          employeeFullName: response.data.employeeFullName,
          managerFullName: response.data.managerFullName,
          managerApprovalLevel: response.data.managerApprovalLevel,
          email: response.data.managerUserName,
          phase: response.data.phase,
          currency: response.data.currency,
          language: response.data.language,
          translation: response.data.translationStringList,
          kpi: response.data.kpi,
          data_bonuslevels: [],
          isLoading: false
        });
        this.builddatatable();
        this.getIPInfo();
        console.log(response.data);
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false })));
    
  };

  // Let's our app know we're ready to render the data
  componentDidMount() {
    if (JSON.parse(localStorage.getItem('currentUser')).validSubscription !== "yes") {
      this.props.history.push('/order');
      this.setState(state => {
        return {
        redirect: '/order'
      };
      });
    } else {
      this.getBonusAgreement();
    }

  };
 
  builddatatable(){
    var tableswithdata = {};
    tableswithdata = [];
    for (var j=0; j<this.state.kpi.length; j++) {
      var newkpi = {};
      newkpi = [];
      for (var k=0; k<5; k++) {
        var newbonuslevel = {};
        newbonuslevel.tb_percentage = j*10;
        newbonuslevel.tb_level = parseInt(j)*100+parseInt(k);
        newbonuslevel.tb_levelaxis = this.state.kpi[j].unit;
        newbonuslevel.tb_payout = j*k*100;
        newbonuslevel.tb_payoutaxis = this.state.currency;
        newkpi.push(newbonuslevel);
      }
      tableswithdata.push(newkpi);
    }

    this.setState(state => {
      return {
        bonusAgreementId: this.state.bonusAgreementId,
        name: this.state.name,
        onTargetReward: this.state.onTargetReward,
        resultAmount: this.state.resultAmount,
        period: this.state.period,
        employeeUserName: this.state.employeeUserName,
        managerUserName: this.state.managerUserName,
        employeeFullName: this.state.employeeFullName,
        managerFullName: this.state.managerFullName,
        phase: this.state.phase,
        kpi: this.state.kpi,
        data_bonuslevels: tableswithdata,
      }
    })
    this.forceUpdate();
  };

  handleSubmit = event => {
    event.preventDefault();

    var today = new Date();

    const model = {};
    model.bonusAgreementId = this.state.bonusAgreementId; 
    model.employeeEmail = this.state.employeeEmail; 
    model.employeeFullName = this.state.employeeFullName; 
    model.phase = "Start Sign Results";
    model.dateSent = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(); 
    model.dateAnswered = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    model.timeAnswered = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(); 
    model.approvalStatus = this.state.approvalStatus; 
    model.approvalLevel = this.state.managerApprovalLevel; 
    model.approvalComment = this.state.approvalComment; 
    model.email = this.state.email;
    model.fullName = this.state.fullName; 
    model.ipAddress = this.state.ipAddress; 
    model.city = this.state.city;
    model.country = this.state.country; 
    model.longitude = this.state.longitude; 
    model.latitude = this.state.latitude; 

    const BonusAgreement = model;

    var connectionstring1 = process.env.REACT_APP_apiUrl ;
    var connectionstring2 = '/api/ApproveSend';
    const connectionstring = connectionstring1.concat("" , connectionstring2);

    trackPromise(
    axios.post(connectionstring, BonusAgreement
      ,
    {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then(res => {
        this.props.history.push('/bonusagreements');
      }))
  };



  // Putting that data to use
  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div className="SignResults"><LoadingSpinner/></div>;
    }

    return (
      <React.Fragment>
        <Layout>
        <br />
        <h2>{this.state.translation.string017}</h2>
        <i> {this.state.translation.string014}</i>
        <br /><br />
        <h4 className="card-title">{this.state.name} - {this.state.employeeFullName}</h4> 
        {this.state.translation.string018} {this.state.employeeFullName}<br />
        {this.state.translation.string022}: <b>{this.state.resultAmount} {this.state.currency}</b><br />
        {this.state.translation.string001}: {this.state.onTargetReward} {this.state.currency}

          <br />   <br />       
       

        {this.state.kpi.map((kpi,i) => {
                      return (
                        <div key={i} >
                        <h5>{this.state.translation.string002} {i+1} - {kpi.name}</h5>
                        {kpi.description} <br />
                        {this.state.translation.string003} {kpi.percentageOfTotalReward}% <br />
                        {this.state.translation.string001} {kpi.onTargetReward} {this.state.currency} <br />
                        {this.state.translation.string019} {kpi.result} {kpi.unit} {this.state.translation.string020} <b>{kpi.resultAmount} {this.state.currency} </b> <br /><br />
                        <BonusTableSteps goal={kpi.goal} unit={kpi.unit} ontargetreward={this.state.onTargetReward * kpi.percentageOfTotalReward}  currency={this.state.currency}  kpistep={kpi.kpiStep} resultAmount={kpi.resultAmount} language={this.state.language}></BonusTableSteps>
                         <br />
                        </div>
                        
                        )
                    })}
        <br />
        <LoadingSpinner/>
        <Form onSubmit={this.handleSubmit}>
        <Table borderless>
         <tbody>
          <tr>
           <td><Link className="float-left" to={`/SetResultOneBonus/` + this.state.bonusAgreementId}><Button variant="secondary">Return</Button></Link></td>
           <td><Button variant="primary" type="submit" className="float-right">{this.state.managerApprovalLevel === 1 ? this.state.translation.string023 : this.state.translation.string021}</Button>
           <Share bonusAgreementId={this.state.bonusAgreementId}></Share></td>
          </tr>
         </tbody>
        </Table>

       </Form>
       <br /><br />
       </Layout>
      </React.Fragment>
    );
  }
}

export default SignResults;